import {
    GET_CATEGORY_START,
    GET_CATEGORY_SUCCESS,
    GET_CATEGORY_FAIL,
} from '../constants/ActionCategoryTypes';

const initialState = {
    loading: false,
    data: null,
    error: false,
};



const Categories = (state = initialState, action) => {
    switch (action.type) {
        case GET_CATEGORY_START:
            return { ...state, loading: true };
        case GET_CATEGORY_FAIL:
            return { ...state, loading: false, error: true };
        case GET_CATEGORY_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
};

export default Categories