import {
    GET_STORES_DISTANCE_START,
	GET_STORES_DISTANCE_SUCCESS,
	GET_STORES_DISTANCE_FAIL
} from '../constants/ActionCategoryTypes';

const initialState = {
    loading: false,
    data: null,
    error: false,
};

const Locations = (state = initialState, action) => {
    switch (action.type) {
        case GET_STORES_DISTANCE_START:
            return { ...state, loading: true };
        case GET_STORES_DISTANCE_FAIL:
            return { ...state, loading: false, error: true };
        case GET_STORES_DISTANCE_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
};



export default Locations