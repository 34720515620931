import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { MunchBakeryApiUrl } from '../../global';
import CryptoJS from "crypto-js";
import CircularProgress from '@mui/material/CircularProgress';



export default function ThankyouPage() {
    const store = useSelector((state) => state);
    const flavorText = store.Lang.data?.flavor ? store.Lang.data.flavor : '';
    const thankyou_page = store.Lang.data?.thank_you ? store.Lang.data.thank_you : '';

    const langId = store.Lang.data?.lang ? store.Lang.data?.lang : "";
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = React.useState(user);
    const custtoken = store?.auth?.user?.cus_to || store1[0]?.cus_to;
    const location = useLocation();
    const navigate = useNavigate();
    const [getPhoneNumber, setPhoneNumber] = React.useState(localStorage.getItem("cusMob") ? JSON.parse(localStorage.getItem("cusMob")) : "");
    const [getCategoryId, setCategoryId] = React.useState(localStorage?.getItem("categoryId") ? JSON.parse(localStorage?.getItem("categoryId"))?.Id : "");

    const [orderDetails, setOrderDetails] = React.useState(location?.state ? location?.state : "");
    const [getOrderStatus, setOrderStatus] = React.useState("");
    const [getTextInfo, setTextInfoState] = React.useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [getTransactionMessage, setTransactionFailedMessage] = useState(false);
    const [getLoader, setLoader] = useState(true);


    useEffect(() => {
        localStorage?.removeItem("inStock");
        localStorage?.removeItem("invoiceId");
    }, [location])



    // window.onbeforeunload = (event) => {
    //     event.preventDefault();
    //     window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
    //     return window.location.replace("/");
    // };


    const setTermCondition = (e) => {
        e.preventDefault();
        window.open('https://api.whatsapp.com/send/?phone=966920015010&text=customcakechat');
    }

    useEffect(() => {
        setLoader(true);
        if (store.Lang.data?.lang) {
            if (orderDetails?.order_id) {
                statusOfOrder();
                localStorage?.setItem("transaction_success", orderDetails?.order_id);
                localStorage?.removeItem("OrderPayload");
                localStorage?.removeItem("invoice_details");
                if(orderDetails?.payment_method && orderDetails?.payment_method == 2) {
                    GetInfoTextMunchCake();
                }
            } else if (orderDetails?.err) {
                localStorage?.setItem("transaction_failed", JSON.stringify(orderDetails));
                setTransactionFailedMessage(true);
                setErrorMessage(orderDetails?.err);
                GetInfoTextMunchCake();
                setLoader(false)
            } else {
                navigate("/", { replace: true })
            }
        }
    }, [orderDetails?.order_id]);



    const statusOfOrder = async () => {

        let mob = ((getPhoneNumber?.phone).trim()).length < 10 ? `0${getPhoneNumber?.phone}` : getPhoneNumber?.phone;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                MobileNumber: mob,
                OrderNumber: orderDetails?.order_id
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetMunchBakeryOrderStatus/`, data, header);

            if (response?.data?.GetMunchBakeryOrderStatusResult) {
                setOrderStatus(response?.data?.GetMunchBakeryOrderStatusResult);
                setLoader(false);
            }
        } catch (error) {
            console.log("err", error);
        }

    }

    const GetInfoTextMunchCake = async () => {

        let language = langId == 'ar' ? "2" : "1";


        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                langId: language
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetInfoTextMunchCake/`, data, header);

            if (response?.data?.GetInfoTextMunchCakeResult?.length > 0) {
                let TextExist = response?.data?.GetInfoTextMunchCakeResult?.filter((v, i) => v?.Name == "paymentLine");
                setTextInfoState(TextExist);
                let TransactionFailedExist = response?.data?.GetInfoTextMunchCakeResult?.filter((v, i) => v?.Name == "FailedPayment");
                setTextInfoState(TransactionFailedExist);
            }
        } catch (error) {
            console.log("err", error);
        }

    }

    const clickHtmlHandler = (e) => {

        const el = e?.target;
        if (el && el?.parentNode?.tagName == "SPAN") {
            if (el?.tagName == "STRONG") {
                langId == 'en' ? window.open("https://www.munchbakery.com/terms-and-conditions-en") : window.open("https://www.munchbakery.com/terms-and-conditions-ar");
            }
        }

    }

    const mouserHoverHandle = (e) => {

        const ele = e?.target;
        if (ele && ele?.parentNode?.tagName == "SPAN") {
            if (ele?.tagName == "STRONG") {
                ele?.classList?.add("dynamic_text");
            }
        } else {
            ele?.classList?.remove("dynamic_text");
        }

    }

    const changeOrderDateFormat = () => {

        let new_date = moment(orderDetails?.getCartDetail?.OrderDate).format('DD MMM YYYY');

        let changeToAR = new Date(new_date);
        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).format(changeToAR);

        return langId == "en" ? new_date : In_arabic;
    }

    const timeOrderHandler = () => {


        let changeToAR = new Date(`${orderDetails?.getCartDetail?.OrderDate} ${orderDetails?.getCartDetail?.OrderTime}`);

        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(changeToAR);

        return langId == "en" ? moment(`${orderDetails?.getCartDetail?.OrderDate} ${orderDetails?.getCartDetail?.OrderTime}`).format("hh:mm A") : In_arabic;
    }

    const detailsHandler = () => {
        if (getCategoryId == 57) {
            return localStorage?.getItem("sliders") ? JSON.parse(localStorage?.getItem("sliders")) : "";
        } else if (getCategoryId == 81 || getCategoryId == 20 || getCategoryId == 21) {
            return localStorage?.getItem("Others") ? JSON.parse(localStorage?.getItem("Others")) : "";
        } else {
            return {}
        }
    }

    const initiateSessionHandler = async () => {
        localStorage.removeItem("transaction_failed");
        localStorage?.setItem("start_session", "true");
        localStorage?.setItem("try-again", "true");
        let Others = await detailsHandler();
        if (Others) {
            let states = {
                others: Others
            }
            if (getCategoryId == 57) {
                navigate("/customizeNext", { state: states, replace: false });
            } else if (getCategoryId == 81 || getCategoryId == 20 || getCategoryId == 21) {
                navigate("/customizeThird", { state: states, replace: false });
            } else {
                alert("Something went wrong!. Please try again");
            }
        }
    }

    const ErrorHandler = () => {
        window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
        return window.location.replace(window.location.origin);
    }

    const componentHandler = () => {
        if (getTransactionMessage) {
            return (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                        <Box component='div' className='banner h1' style={{ textAlign: 'center' }}>
                            <img alt='Transaction Failed' src="images/transaction_failed.svg" />
                            <h1 className=''>{thankyou_page?.transaction_failed}</h1>
                            <p style={{ color: 'red', textAlign: "center", textTransform: "capitalize" }}>{errorMessage}</p>
                        </Box>
                    </Box>
                    <Box component='div' className='sms-desc'>
                        <Typography component='p' variant='p' style={{ fontWeight: "700" }}>
                            {getTextInfo?.length > 0 && <div dangerouslySetInnerHTML={{ __html: getTextInfo[0]?.Description }}></div>}
                        </Typography>
                    </Box>


                    {/* <Box component='div' className='step_button_box' style={{ position: "unset" }}>
                        <Box component='div' className='btn'>
                            <Button className="upload_btn" onClick={() => initiateSessionHandler()} >
                                {thankyou_page?.try_again}
                            </Button>
                        </Box>
                    </Box> */}

                    <Box component='div' className='step_button_box' style={{ position: "unset" }}>
                        <Box component='div' className='btn'>
                            <Button className="upload_btn" onClick={() => ErrorHandler()} >
                            {thankyou_page?.go_back}
                            </Button>
                        </Box>
                    </Box>
                </>
            )
        } else {
            return (
                <>
                    <Box component='div' className='banner'>
                        <img alt='banner' fetchpriority="high" src="images/banner.svg" />
                    </Box>
                    <Box component='div' className='thankyou_text'>
                        <Typography component='h1' variant='h1'>
                            {thankyou_page ? thankyou_page?.description_heading : 'Thank you on'} <br /> {thankyou_page ? thankyou_page?.description_br : "your order"}
                        </Typography>
                    </Box>
                    <Box component='div' className='order_number'>
                        <Typography component='p' variant='p'>
                            <strong>{thankyou_page ? thankyou_page?.orders_number : 'Order number'} </strong>{orderDetails?.order_id ? orderDetails?.order_id : ''}
                        </Typography>
                    </Box>

                    <Box component='div' className='sms-desc'>
                        <Typography component='p' variant='p' >
                            {getTextInfo?.length > 0 && <div onMouseOver={(e) => mouserHoverHandle(e)} onClick={(e) => clickHtmlHandler(e)} dangerouslySetInnerHTML={{ __html: getTextInfo[0]?.Description }}></div>}
                        </Typography>
                    </Box>

                    <Box component="div" className='my_orders'>
                        <Box component='div' className='orders in_process'>
                            <Box component='div' className='headings'>
                                <Typography component='h3' variant='h3'>
                                    {orderDetails?.GetCategoryId == 57 ? orderDetails?.categoryNameHandle : orderDetails?.getProductData?.ProductName}

                                </Typography>
                                <Typography component='h3' variant='h3' className='sar_total'>
                                    {flavorText?.currency} {orderDetails?.ShoppingDetails?.OrderTotal}
                                </Typography>
                            </Box>
                            <Box component='div' className='order_details'>
                                <Box component='div' className='cake_detail'>
                                    {orderDetails?.getFlavourMax && orderDetails?.getFlavourMax[0]?.Name && <Typography component='p' variant='p'>({flavorText?.flavor_name} 1) {orderDetails?.getFlavourMax[0]?.Name}</Typography>}
                                    {orderDetails?.getFlavourMax && orderDetails?.getFlavourMax[1]?.Name && <Typography component='p' variant='p'>({flavorText?.flavor_name} 2) {orderDetails?.getFlavourMax[1]?.Name}</Typography>}

                                </Box>

                                <Box component='div' className='progress_report'>
                                    {/* <Button variant="outlined">{thankyou_page ? thankyou_page?.in_process : 'In process'}</Button> */}
                                    {getOrderStatus && <Button variant="outlined">{getOrderStatus}</Button>}
                                </Box>
                            </Box>
                            <Box component='div' className='date_details'>
                                <Typography component='p' variant='p' className='pickup_details'>{langId == "en" ? 'Pickup @' : 'استلام من'} {orderDetails?.getCartDetail?.val?.branch_name ? orderDetails?.getCartDetail?.val?.branch_name : ""} {langId == "en" ? 'At' : 'في'} {orderDetails?.getCartDetail?.OrderDate ? changeOrderDateFormat() : ""} - {orderDetails?.getCartDetail?.OrderTime ? timeOrderHandler() : ""}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box component='div' className='bottm_buttons'>
                        {/* <Button onClick={() => PageRender("home_page")}>{thankyou_page ? thankyou_page?.home_page_btn : 'Go to home page'}</Button> */}
                        <Button onClick={(e) => setTermCondition(e)}>{thankyou_page ? thankyou_page?.contact_btn : 'Contact us'}</Button>
                    </Box>
                </>
            )
        }
    }


    return (
        <Box component='div' className='page_default thankyou_page'>
            {getLoader ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                    <CircularProgress />
                </Box>
                :
                componentHandler()
            }
        </Box>
    )
}
