import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material'
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from "../../actions/auth";
import CryptoJS from "crypto-js";
import moment from 'moment';
import axios from 'axios';
import { MunchBakeryApiUrl } from '../../global';



export default function Profile() {
    const lang = useSelector((state) => state);
    const langText = lang.Lang.data?.side_bar ? lang.Lang.data.side_bar : '';
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = React.useState(user);
    const custtoken = lang?.auth?.user?.cus_to || store1[0]?.cus_to;
    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const langId = lang.Lang.data?.lang ? lang.Lang.data?.lang : "";
    const profileText = lang.Lang.data?.profile ? lang.Lang.data.profile : '';

    const [getLatestOrder, setLatestOrder] = React.useState("");
    const [getPhoneNumber, setPhoneNumber] = useState(localStorage.getItem("cusMob") ? JSON.parse(localStorage.getItem("cusMob")) : "");
    const [getTrue, setTrue] = React.useState(false);
    const [getLoader, setLoader] = React.useState(true);
    const [htmlDir, setHtmlDir] = useState('');






    useEffect(() => {
        setHtmlDir(document.documentElement.getAttribute('dir'));
    }, []);


    const Logout = () => {
        if (lang.auth.isLoggedIn) {
            logout();
        }
    }

    const getAllOrderList = async () => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.get(`${MunchBakeryApiUrl}/GetCustomerOrderMunchBakery/${custGuid}/`, header);
            if (response?.data?.length > 0) {
                let latestOrder = response?.data[0];
                await getLatestOrderList(latestOrder);
            } else {
                setTrue(true);
            }
        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const getLatestOrderList = async (latestOrder = "") => {
        setLoader(true);
        let languageId = langId == 'ar' ? 0 : 1;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.get(`${MunchBakeryApiUrl}/GetOrderDetailMunchBakery/${custGuid}/${latestOrder?.OrderId}/${languageId}`, header);
            // console.log(`${MunchBakeryApiUrl}/GetOrderDetailMunchBakery/${custGuid}/${latestOrder?.OrderId}/${languageId}`, '--api');
            if (response?.data) {
                setTrue(false);
                setLatestOrder(response?.data);
                setLoader(false);
                // await encryptStringHandle(response?.data);
            }

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }


    const encryptStringHandle = async (list) => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                encryptString: list?.OrderId
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/EncryptString/`, data, header);
            // console.log(`${MunchBakeryApiUrl}/EncryptString/`, '--api');
            if(response?.data?.EncryptStringResult) {

                await GetOrderDetailsForInvoice(response?.data?.EncryptStringResult);

            }

        } catch (error) {
            console.log("err", error);
        }

    }

    const GetOrderDetailsForInvoice = async (encryptData) => {
        let language = langId == 'ar' ? "0" : "1";


        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                langId: language,
                OrderId: encryptData != "" ? encryptData : ""
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetOrderDetailsForInvoice/`, data, header);
            // console.log(`${MunchBakeryApiUrl}/GetOrderDetailsForInvoice/`, '--api');

            if(response?.data?.GetOrderDetailsForInvoiceResult) {
                setTrue(false);
                setLatestOrder(response?.data?.GetOrderDetailsForInvoiceResult);
                setLoader(false);
            } else {
                setTrue(true);
            }


        } catch (error) {
            console.log("err", error);
        }

    }


    useEffect(() => {
        if (lang.auth.isLoggedIn) {
            getAllOrderList();
            // changeOrderDateFormat();
        }
    }, [])

    // const changeOrderDateFormat = () => {
    //     let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
    //     let newOrderDate = moment(order_date).format('DD MMM YYYY');
    //     return newOrderDate;
    // }

    const phoneFormat = () => {
        if (getPhoneNumber?.phone && ((getPhoneNumber?.phone).trim()).length < 10) {
            return `0${getPhoneNumber?.phone}`;
        } else {
            return getPhoneNumber?.phone;
        }

    }


    const changeOrderDateFormat = () => {
        let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
        let new_date = moment(order_date).format('DD MMM YYYY');

        let changeToAR = new Date(new_date);
        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).format(changeToAR);

        return langId == "en" ? new_date : In_arabic;
    }

    const timeOrderHandler = () => {
        let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
        let new_date = moment(order_date).format('DD MMM YYYY');

        let changeToAR = new Date(`${new_date} ${getLatestOrder?.DeliveryTime}`);

        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
               hour: 'numeric',
               minute: 'numeric',
               hour12: true
            }).format(changeToAR);

        return langId == "en" ? moment(`${new_date} ${getLatestOrder?.DeliveryTime}`).format("hh:mm A") : In_arabic;
    }


    return (
        // <React.Fragment>
            <Box component='div' className='page_default profile_page_main'>
                <Card className='profile_card'>
                    <Box component='div' className='profile_img'>
                        <img src='images/profile_dmmy.svg' />
                    </Box>
                    <Box component='div' className='profile_name'>
                    {!getLoader && getLatestOrder ?
                        // <Typography component='h1' variant='h1'>
                        //      {getLatestOrder?.CustomerMobileNumber }
                        // </Typography>
                         <Typography component='h1' variant='h1'>
                         {phoneFormat()}
                    </Typography>
                        :
                        <Typography component='h1' variant='h1' style={{ padding: '0px 124px' }}>
                            <Skeleton animation="wave" className='text-center'/>
                        </Typography>
                      }

                        {/* <Typography component='p' variant='p' className='tag_line'>
                            VIP {profileText?.customer}
                        </Typography> */}
                    </Box>
                    {/* <Box component='div' className='customer_level'>
                        <Box component='span' variant='span' className='level_line'></Box>
                        <Box component='span' variant='span' className='level_num'>{profileText?.level} 10</Box>
                    </Box> */}
                </Card>
                {lang.auth.isLoggedIn ?
                    <>

                        <Box component='div' className='my_orders_one'>
                            <Box component='div' className='fields_order_value' >
                                <Typography component='h6' variant='h6' >
                                    {langText?.my_latest_order}
                                </Typography>
                            </Box>
                        </Box>
                        {getTrue ?
                            <Box component='div' className='my_orders_one'>
                                <Box component='div' className='orders in_process'>
                                    <Box component='div' className='no_order' >
                                        <Typography component='h3' variant='h3'>
                                            {langId == 'en' ? 'No order has been placed yet.' : 'لم يتم وضع أي أمر حتى الآن'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            :
                            !getLoader && getLatestOrder ?
                                <Box component='div' className='my_orders_one'>
                                    <Link to={'/myOrderDetail'} className='text-decoration'>
                                        <Box component='div' className='orders in_process'>
                                            <Box component='div' className='headings'>
                                                <Typography component='h3' variant='h3'>
                                                    {langText?.accept_order}
                                                </Typography>
                                                <Typography component='h3' variant='h3' className='sar_total'>
                                                    {getLatestOrder?.OrderGuid ? `#${getLatestOrder?.OrderGuid}` : ""}
                                                </Typography>
                                            </Box>
                                            <Box component='div' className='order_details'>
                                                <Box component='div' className='cake_detail'>
                                                    <Typography component='p' variant='p'>
                                                        {langId == "en" ? `Pickup @ ${getLatestOrder?.StoreNameEn}` : `استلام من ${getLatestOrder?.StoreNameAr}`}
                                                    </Typography>
                                                    <Typography component='p' variant='p'>
                                                        {langId == "en" ? 'At' : 'في'} {changeOrderDateFormat()} - {timeOrderHandler()}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box component='div' className='order_details'>
                                                <Box component='div' className='cake_detail'>
                                                    <Typography component='p' variant='p'>
                                                        {langText?.questions}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Box>
                                :
                                <Box component='div' className='my_orders_one'>
                                    <Box component='div' className='orders in_process'>
                                        <Box component='div' className='headings'>
                                            <Typography component='h3' variant='h3'>
                                                <Skeleton animation="wave" width={120} />
                                            </Typography>
                                            <Typography component='h3' variant='h3' className='sar_total'>
                                                <Skeleton animation="wave" width={80} />
                                            </Typography>
                                        </Box>
                                        <Box component='div' className='order_details'>
                                            <Box component='div' className='cake_detail'>
                                                <Typography component='p' variant='p'>
                                                    <Skeleton animation="wave" width={90} />
                                                </Typography>
                                                <Typography component='p' variant='p'>
                                                    <Skeleton animation="wave" width={160} />
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box component='div' className='order_details'>
                                            <Box component='div' className='cake_detail'>
                                                <Typography component='p' variant='p'>
                                                    <Skeleton animation="wave" width={200} />
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                        }

                    </>
                    : ""
                }


                <Box component='div' className={htmlDir + ' edit_options'}>

                    {/* <Box component='div' className='fields'>
                        <Typography component='h2' variant='h2'>
                            {profileText?.phone}
                        </Typography>
                        <Button variant="text">

                            <img src='images/left_back_arrow.svg' alt='Left Arrow' />

                        </Button>
                    </Box>
                    <Box component='div' className='fields'>
                        <Typography component='h2' variant='h2'>
                            {profileText?.name}
                        </Typography>
                        <Button variant="text">
                            <img src='images/left_back_arrow.svg' alt='Left Arrow' />
                        </Button>
                    </Box>
                    <Link to='/myOrders' className='text-decoration'>
                        <Box component='div' className='fields'>
                            <Typography component='h2' variant='h2'>
                                {profileText?.my_orders}
                            </Typography>
                            <Button variant="text">

                                <img src='images/left_back_arrow.svg' alt='Left Arrow' />

                            </Button>
                        </Box>
                    </Link> */}



                    {lang.auth.isLoggedIn ?
                        <Box component='div' className='fields' onClick={() => Logout()} style={{ cursor: 'pointer' }}>
                            <Typography component='h2' variant='h2' >
                                {profileText?.logout}
                            </Typography>
                            <Button variant="text">
                                <img src='images/left_back_arrow.svg' alt='Left Arrow' />
                            </Button>
                        </Box>
                        : ""
                    }
                </Box>
            </Box>
        // </React.Fragment>
    )
}
