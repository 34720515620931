import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { Padding } from '@mui/icons-material';
import heic2any from "heic2any";
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { API_URL } from '../../global';
import { Box, Button, Typography, TextareaAutosize, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'




const newArr = [];
export default function Customize() {
    const lang = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const langId = lang?.Lang?.data?.lang ? lang?.Lang?.data?.lang : '';
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';


    const [opened, setOpened] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [getCustomText, setCustomText] = React.useState('');

    const [GetCategoryId, setCategoryId] = useState(localStorage.getItem("categoryId") ? JSON.parse(localStorage.getItem("categoryId"))?.Id : "");
    const [getImage, setImage] = useState(localStorage.getItem("customize") ? localStorage.getItem("customize") : "");
    const [getImagePath, setImagePath] = useState(localStorage.getItem("image_path") ? JSON.parse(localStorage.getItem("image_path")) : "");
    const [productDetail, setProductDetail] = useState(localStorage.getItem("ProductDetails") ? JSON.parse(localStorage.getItem("ProductDetails")) : "");

    const [getRRR, setRRR] = useState(getImagePath.length > 0 ? getImagePath : []);
    const customizeImage = lang.auth?.image ? lang.auth?.image : '';
    const customizeText = lang.Lang.data?.customize ? lang.Lang.data.customize : '';
    const [cakeImage, setCakeImage] = useState(GetCategoryId == 57 ? 'images/cupcake.png' : 'images/cake_img.png')


    // const [uploadedImage, setUploadedImage] = useState([]);

    const [getRemove, setRemove] = useState(false)
    const [TextVal, SetTextVal] = useState("")
    const [getLoad, setLoad] = useState(false)
    const [getPrice, setPrice] = useState(productDetail?.Price ? productDetail?.Price : 0);
    const [getPath, setPath] = useState(productDetail?.picturePath ? productDetail?.picturePath : "");
    const [getProdId, setProdId] = useState(productDetail?.prodId ? productDetail?.prodId : "");

    const [getImg, setImg] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (getImage) {
            let newVal = JSON.parse(getImage);
            SetTextVal(newVal?.text);
            dispatch({ type: "GET_IMAGE", payload: { image: getImagePath, text: newVal?.text } });
        }

    }, [getRRR])


    useEffect(() => {
        let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";
        if (transaction_err?.err && transaction_err?.err == "declined") {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }

        let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";
        if(get_success_state) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }
    }, [])


    const handleImageUpload = (event) => {

        const files = Array.from(event?.target?.files); // Convert FileList to an array

        if ((getRRR?.length + files?.length) > 4) {
            return handleTextButton(1);
        }

        if ((getImagePath?.length + files?.length) > 4) {
            return handleTextButton(1);
        }


        if (files?.length <= 4) {
            files?.forEach(async (file) => {
                if (file?.type == "image/jpeg" || file?.type == "image/jpg" || file?.type == "image/png" || file?.type == "image/heic" || file?.type == "image/heif") {
                    let fileSize = file?.size?.toString();
                    let maxFileSize = fileSize ? parseInt((fileSize / 1024) / 1000) : 0;
                    if (maxFileSize > 15) {
                        return handleTextButton(2);
                    }
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const img = e?.target?.result;
                        // setUploadedImage((prevImages) => [...prevImages, img]);
                        let formdata = {
                            DisplayOrder:0,
                            PictureAlt:"",
                            PictureName:"",
                            PicturePath:img,
                            PictureTitle:""
                        }

                        setRRR((prevImages) => [...prevImages, formdata]);

                    };
                    if (file?.type == "image/heic" || file?.type == "image/heif") {
                        setLoad(true)

                        // Let's turn the HEIC/HEIF image into JPEG so the browser can read it
                        const blob = await heic2any({
                            blob: file,
                            toType: 'image/jpeg',
                            quality: 5.0,
                        });
                        if (blob) {
                            setLoad(false)
                        }
                        reader.readAsDataURL(blob);
                        setImg((prevImages) => [...prevImages, blob]);

                    }
                    if (file?.type == "image/jpeg" || file?.type == "image/jpg" || file?.type == "image/png") {
                        reader.readAsDataURL(file);
                        setImg((prevImages) => [...prevImages, file]);
                    }

                } else {
                    handleTextButton(3);
                }
            });
        } else {
            handleTextButton(1);
        }
    };

    const removeElementAtIndex = (e, key) => {
        // setRemove(true);
        e.preventDefault();
        setRRR(prevState => {
            const newArray = [...prevState];
            if (newArray?.includes(getPath)) {
                setPath("");
            }
            newArray?.splice(key, 1);

            if (newArray?.length == 0) {
                localStorage.removeItem("customize")
            }
            if (getImagePath?.length > 0) {
                localStorage.setItem("image_path", JSON.stringify(newArray))
                setImagePath(newArray)
            }
            return newArray;
        });

        setImg(prevState => {
            const newArray = [...prevState];
            newArray.splice(key, 1);

            if (newArray?.length == 0) {
                localStorage.removeItem("customize")
            }
            return newArray;
        });
    };

    const handleTextChange = (e) => {
        SetTextVal(e?.target?.value)
    }

    const clear = () => {
        localStorage.removeItem('sliders')
        localStorage.removeItem('Others')
        dispatch({ type: "GET_IMAGE", payload: { image: getImagePath, text: TextVal } });
        return localStorage.setItem("customize", JSON.stringify({ text: TextVal }))
    }

    // console.log(getRRR, "getRRR");

    const FileUpload = async () => {
        if (getImg?.length > 0) {

            setLoader(true);

            const form_data = new FormData;

            getImg?.forEach(image => {
                form_data.append("image[]", image);
            });

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }


            try {
                const response = await axios.post(`${API_URL}/image-upload-path`, form_data, config);
                if (response?.data?.success) {
                    let imagePath = response?.data?.data;
                    let newArray = imagePath;
                    // let form_data = [];
                    // form_data?.concat(newArray);

                    setLoader(false);
                    if (getImagePath) {
                        getImagePath?.map((v, i) => newArray?.push(v))
                        // newArray?.push(getImagePath)
                    }
                    // console.log(newArray, "newArray");
                    localStorage.setItem("image_path", JSON.stringify(newArray))
                    clear();
                    let state = { category_id: GetCategoryId }
                    navigate('/customizeNext', { state: state });
                } else {
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.log("err", error);
                return alert("Something went wrong. Please try again")
            }
        } else {
            localStorage.setItem("image_path", JSON.stringify(getRRR))
            clear()
            // navigate('/customizeNext');
            let state = { category_id: GetCategoryId }
            navigate('/customizeNext', { state: state });
        }

    }


    const handleTextButton = (val, message = "") => {
        if (val === 1) {
            setOpened(true);
            return langId == 'en' ? setCustomText('You can only upload maximum of 4 images.') : setCustomText('يمكنك فقط تحميل 4 صور كحد أقصى')
        } else if (val === 2) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Image size cannot be more than 15 MB.') : setCustomText('لا يمكن أن يزيد حجم الصورة عن 15 ميجابايت')
        } else if (val === 3) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Image format not allowed!.') : setCustomText('تنسيق الصورة غير مسموح به!')
        } else if (val === 4) {
            if (GetCategoryId == 57 || GetCategoryId == 20) {
                setOpened(true);
                return langId == 'en' ? setCustomText('Please upload image.') : setCustomText('يرجى تحميل الصورة')
            }
        } else {
            return setOpened(false);
        }
    }


    return (
        <React.Fragment>
            <Box component='div' className='page_default customize_page'>
                <Box component='div' className='custom_cake'>
                    {cakeImage && <img src={cakeImage} />}
                </Box>
                <Typography component='h1' variant='h1'>
                    {GetCategoryId == 57 ? customizeText?.cupcake_heading : customizeText?.heading}
                </Typography>

                <Box component='div' className='upload_img_opt'>
                    <Button component="label" className="upload_btn" style={getRRR?.length > 0 ? { padding: '0px' } : {}}>
                        <div className="uploaded-images-container">
                            {getLoad ?
                                <CircularProgress style={{ width: "30px", height: "30px" }} />
                                :
                                <>
                                    {getRRR?.length > 0 ?
                                        <>
                                            {getRRR?.length <= 4 && getRRR?.map((image, index) => (
                                                <div key={index} className="uploaded-image">
                                                    <div key={index} onClick={(event) => removeElementAtIndex(event, index)} className="close-thik"
                                                        style={{ position: "absolute", marginLeft: '60px', right: '5px' }}></div>
                                                    <img src={image?.PicturePath} alt="Uploaded" className="small-image" />
                                                </div>
                                            ))}
                                            {(getRRR?.length < 4 && !getRemove) &&
                                                (<div className="" style={{ margin: "12px 0px" }}>
                                                    <img src="/images/add_to_photos.svg" alt="icon" />
                                                </div>)
                                            }
                                        </>
                                        :
                                        customizeText?.upload_image
                                    }
                                </>


                            }
                            {getRemove && <input hidden onClick={() => setRemove(false)} />}
                            {(getRRR?.length < 4 && !getRemove) && <input hidden accept="image/*, .heic, .heif" multiple type="file" onChange={handleImageUpload} />}
                        </div>
                    </Button>
                </Box>
                <Box component='div' className='upload_inst'>
                    {/* <Typography component="label" variant='label'>
                        {customizeText?.description}
                    </Typography> */}
                    <TextareaAutosize
                        id="description"
                        // minRows="0"
                        // maxRows="7"
                        onChange={handleTextChange}
                        value={TextVal?.trimStart()}
                        placeholder={customizeText?.description}
                        className="small-description"
                    />
                </Box>
                <Box component='div' className='step_button_box'>
                    <Box component='div' className='text'>
                        <Typography component='h4' variant='h4'>{customizeText?.currency} {GetCategoryId == 81 ? 0 : getPrice}</Typography>
                        <Typography component='p' variant='p'>{customizeText?.vat_included}</Typography>
                    </Box>
                    <Box component='div' className='btn'>
                        {getRRR?.length == 0 && (GetCategoryId == 57 || GetCategoryId == 20)
                            ?
                            <Button className="upload_btn" onClick={() => handleTextButton(4)}>
                                {customizeText?.next_btn}
                            </Button>
                            :
                            <>
                                {!getLoad ?
                                    <>
                                        {!loader ?
                                            <Button className="upload_btn" onClick={() => FileUpload()}>
                                                {customizeText?.next_btn}
                                            </Button>
                                            :
                                            <Button className="upload_btn" disabled>
                                                <CircularProgress style={{ width: "23px", height: "23px", color: "black" }} />
                                            </Button>
                                        }
                                    </>
                                    :
                                    <Button className="upload_btn" disabled>
                                        {customizeText?.next_btn}
                                    </Button>
                                }
                            </>

                        }
                    </Box>
                </Box>
            </Box>
            <Dialog
                open={opened}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={() => setOpened(false)}
                aria-labelledby="responsive-dialog-title"
                aria-describedby="responsive-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title">
                    {getCustomText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="responsive-dialog-description">
                        {/* You will loose your progress, are you sure to move back ? */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => handleButton("0")}>{flavorText?.cancel}</Button> */}
                    <Button onClick={() => setOpened(false)} autoFocus>
                        {flavorText?.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
