import React from 'react';
import Box from '@mui/material/Box';
import LocationTabs from '../../components/LocationTabs';
import { useLocation } from 'react-router-dom';



export default function Location() {
    const location = useLocation();
    const CategoryId = location?.state?.CategoryId,
    apiTrigger = location?.state?.apiTrigger,
    CategoryId2 = location?.state?.CategoryId2,
    token = location?.state?.token,
    custGuid = location?.state?.custGuid,
    custId = location?.state?.custId;
    return (
        <React.Fragment>
            <Box component='div' className='page_default location_picker_page'>
                <LocationTabs CategoryId={CategoryId} CategoryId2={CategoryId2} token={token} customerGuid={custGuid} customerId={custId} apiTrigger={apiTrigger} />
            </Box>
        </React.Fragment>
    )
}
