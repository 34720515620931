import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import VerificationModal from '../../components/VerificationModal'
import { useSelector, useDispatch } from 'react-redux';
import { GetCategory } from '../../actions/category';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";
import { API_URL, MunchBakeryApiUrl } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import { TrackGoogleTagManagerPageView } from '../../utils/google-tag-manager/events.js';
import TagManager from 'react-gtm-module';
import { ExecutePaymentGateway, InitiateSessionGateway } from '../../actions/payment_gateways.js';
import { logout } from "../../actions/auth";



export default function StartNext() {
    const store = useSelector((state) => state);
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = useState(user);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(true);
    const [loader, setLoader] = useState(false);
    const [metaTitle, setMetaTitle] = useState(0);
    const [checkLang, setcheckLang] = useState(false);
    const custGuid = store?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const custId = store1[0]?.cus_id ? store1[0]?.cus_id : ""
    const custtoken = store?.auth?.user?.cus_to || store1[0]?.cus_to;
    const [getCartDetail, setCartDetail] = React.useState(localStorage.getItem("cart_details") ? JSON.parse(localStorage.getItem("cart_details")) : "");
    const [getClick, setClick] = React.useState(false);
    const [getNavigateValue, setNavigateValue] = React.useState({});
    const [getAddToCart, setAddToCart] = React.useState({});
    const [valueState, setValueState] = React.useState(false);
    const [getApplePay, setApplePay] = React.useState(false);





    // useEffect(() => {

    //         if (getAddToCart?.state) {
    //             if (store.Lang.data?.lang) {
    //                 return addToCart(getAddToCart?.path, getAddToCart?.state);
    //             }
    //         }

    // }, [getAddToCart])

    useEffect(() => {
        if(store.Lang.data?.lang) {
            TrackGoogleTagManagerPageView('main page', document.location.origin + document.location.pathname + "");
        }
    },[])


    useEffect(() => {
        setLoader(true);
        setApplePay(false);

        if (store.Lang.data?.lang) {
            dispatch(GetCategory(store.Lang.data?.lang, store?.auth?.user?.cust_guid ? store?.auth?.user?.cust_guid : "45645")).then((response) => {

                if (!response?.data?.succuss) {
                    setLoader(false);
                    toast.error(response?.data?.error, { position: toast.POSITION.TOP_LEFT });
                }
            });
        }


    }, [dispatch]);


    let createCake = null;

    if (store.Lang.data) {
        createCake = store.Lang.data.create_cake;
    }

    useEffect(() => {
        if (store.Category.data) {
            setCategories(store.Category.data.data);
        }
    }, [store.Category.data]);

    const handleClick = async (e, val) => {

        localStorage.removeItem("transaction_status");
        localStorage.removeItem("ShoppingDetails");
        localStorage.removeItem("start_session");
        localStorage.removeItem("try-again");
        localStorage.setItem("catClick", false)
        if (store.auth.isLoggedIn) {
            setValueState(true);
            setShowModal(false);
            let path = '/location';
            let Id = val?.Description;
            let Id2 = val?.Id;
            let categoryName = val?.CatName;
            let apiHit = true;
            let state = { CategoryId: Id, CategoryId2: Id2, apiTrigger: apiHit, categoryName: categoryName, custGuid: custGuid, custId: custId, token: custtoken };
            if (!getClick) {
                await deleteProductShopping(path, state);
            } else {
                await ShopGetShoppingCart(path, state);
            }
        } else {
            setShowModal(true);
        }
    }

    const ShopGetShoppingCart = async (path, state) => {

        let langName = store?.Lang?.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: state?.custGuid,
            CustomerId: state?.custId ? state?.custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "1",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {
            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            if (!response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem) {
                setClick(true)
                await addToCart(path, state);
            }
        } catch (error) {
            logout();
            console.log("err", error);
        }

    }

    const deleteProductShopping = async (path, state) => {
        await ShopGetShoppingCart(path, state);
        localStorage?.removeItem("transaction_failed");
        localStorage?.removeItem("push_state");
        localStorage?.removeItem("transaction_success");
        localStorage?.removeItem("OrderPayload");

        let langName = store.Lang.data?.lang;
        const langId = langName == 'ar' ? 0 : 1;

        let data = {
            langId: langId,
            CustomerGuid: state?.custGuid
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }

            await axios.post(`${MunchBakeryApiUrl}/DeleteProductShoppingCartMunchCake/`, data, header);
            await getShopGetShoppingCart(path, state);

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again");
        }

    }

    const getShopGetShoppingCart = async (path, state) => {
        let langName = store.Lang.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: state?.custGuid,
            CustomerId: state?.custId ? state?.custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            if (response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem) {
                let result = response?.data?.GetShoppingCartSummaryResult;
                result?.LstECommerceShoppingCartItem?.map(async (v, i) => {
                    await AddUpdateShoppingCart(path, state, v);
                })
            } else {
                setClick(true);
                await addToCart(path, state)
            }

        } catch (error) {
            console.log("err", error);
            // return alert("Something went wrong. Please try again");
        }

    }

    const AddUpdateShoppingCart = async (path, state, result) => {
        let order_date = moment(result?.UpdatedOn, 'DD/MM/YYYY');
        let newOrderDate = moment(order_date).format("MM/DD/YYYY");


        const data = {
            CustomerId: state?.custId,
            CustomerGuid: state?.custGuid,
            CityId: -1,
            DistrictId: -1,
            MarkAsDeleted: 1,
            OrderDate: newOrderDate,
            Price: result?.Price,
            ProductId: result?.ProductId,
            Quantity: result?.Quantity,
            ShoppingCartId: result?.ShoppingCartId,
            StoreId: result?.StoreId,
            ZoneId: "15"
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/AddUpdateShoppingCartItemMunchBakery/`, data, header);
            if (response?.data) {
                localStorage.setItem("form_data", JSON.stringify(data));
                // return setAddToCart({ path, state });
                // if (store.Lang.data?.lang) {
                    await addToCart(path, state);
                // }
            }
        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }



    const addToCart = async (path, state) => {

        let product_id;
        if (state?.CategoryId2 == "20") {
            product_id = '2032';
        } else if (state?.CategoryId2 == "81") {
            product_id = '88';
        } else if (state?.CategoryId2 == "21") {
            product_id = '1806';
        } else if (state?.CategoryId2 == "57") {
            product_id = '799';
        } else {
            product_id = '-1'
        }

        let data = {
            ProductId: product_id,
            LanguageId: 1,
            CustomerGuid: state?.custGuid,
            IsAddToCart: true,
            NumberOfPersons: 10,
            NumberOfLayers: 1,
            lstLayersInfo: [
                {
                    SecondHalfFlavorId: 1,
                    FirstHalfFlavorId: 1,
                    LayerNumber: 1
                }
            ],
            LstPhotos: [
                {
                    "PicturePath": ""
                },
                {
                    "PicturePath": ""
                },

            ],
            CakeMessage: "message",
            CakeDescription: "Description"
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/AddToCartMunchCakeProduct/`, data, header);
            if (response?.data?.Response?.Status == "ERROR") {
                toast.error(response?.data?.error, { position: toast.POSITION.TOP_LEFT });
                setValueState(false);
                return;
                // return alert("Something went wrong. Please try again");
            } else {
                // await ShopGetShoppingCart(path, state);
                localStorage.setItem("categoryId", JSON.stringify({ Id: state?.CategoryId2 }));
                localStorage.setItem("categoryName", JSON.stringify({ categoryName: state?.categoryName }));
                localStorage.removeItem("form_data");
                // return navigate(path, { state: state });

                setNavigateValue({ path, state });
            }
        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    useEffect(() => {
        if (getNavigateValue?.state) {
            // addToCart(getNavigateValue?.path, getNavigateValue?.state)
            ShopGetShoppingCart(getNavigateValue?.path, getNavigateValue?.state);
            navigate(getNavigateValue?.path, { state: getNavigateValue?.state });
            setValueState(false);

        }

    }, [getNavigateValue?.state, getNavigateValue?.path, ShopGetShoppingCart, navigate])

    // const initiateSessionHandler = async () => {
    //     setApplePay(false);
    //     const random_generate = Math.random().toString(36).slice(2).toUpperCase();

    //     let form_data = {
    //         "CustomerIdentifier": (random_generate)?.toString(),
    //         "SaveToken": false
    //     }

    //     try {
    //         const response = await InitiateSessionGateway(form_data);

    //         if (response?.data?.IsSuccess) {

    //             localStorage.setItem("session_details", JSON.stringify(response?.data?.Data));
    //             localStorage.setItem("random_number", form_data?.CustomerIdentifier);
    //             setApplePay(true);

    //             return
    //         } else {
    //             setApplePay(false);
    //             console.log(response?.data, "success false")
    //             return;
    //         }

    //     } catch (error) {
    //         console.log(error, "error");
    //     }
    // }


    useEffect(() => {


        if (store.Lang.data?.lang) {
            window.addEventListener('storage', handleStorageChange);

        }
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [])

        // Function to handle storage events
        const handleStorageChange = async (event) => {

            let number = "";
            if (localStorage?.getItem("random_number")) {
                number = localStorage?.getItem("random_number");
            }

            if (event?.key === 'ExeDetails') {

                if (event?.newValue) {
                    await ExecutePaymentGatewayHandler(event?.newValue, number);

                }

            }
        };


        const ExecutePaymentGatewayHandler = async (getSessionId, customer_ref) => {


            let form_data = {
                "PaymentMethodId": 0,
                "SessionId": JSON.parse(getSessionId)?.sessionId,
                "CustomerName": "abc abc",
                "DisplayCurrencyIso": "SAR",
                "MobileCountryCode": "+91",
                "CustomerMobile": "8198081796",
                "CustomerEmail": "munch@mail.com",
                "InvoiceValue": 0.5,
                "Language": "en",
                "CustomerReference": customer_ref?.toString(),
                "CustomerCivilId": customer_ref?.toString(),
                "UserDefinedField": customer_ref?.toString(),
                "CallBackUrl": `${API_URL}/PaymentPath`,
                "ErrorUrl": `${API_URL}/PaymentPath`,
                "CustomerAddress": {
                    "Block": "string",
                    "Street": "string",
                    "HouseBuildingNo": "string",
                    "AddressInstructions": "string"
                },
                "ExpiryDate": new Date(new Date().setDate(new Date().getDate() + 1)),

                "ProcessingDetails": {
                    "AutoCapture": true,
                    "Bypass3DS": false
                },
                "Suppliers": [
                    {
                        "SupplierCode": 4,
                        "ProposedShare": null,
                        "InvoiceShare": 0.5
                    }
                ],
            }


            try {
                const response = await ExecutePaymentGateway(form_data);

                if (response?.data?.IsSuccess) {
                    localStorage.setItem("invoiceId", response?.data?.Data?.InvoiceId);
                    localStorage.setItem("ExecutePaymentPayload", JSON.stringify(form_data));
                    localStorage?.setItem("start_session", "false");
                    localStorage.removeItem("orderHandler");
                    window.open(response?.data?.Data?.PaymentURL, "_self");



                    return;
                } else {
                    let Bypass3DS_err = response?.data?.ValidationErrors;
                    let Bypass3DS_array = Bypass3DS_err?.map((v, i) => v?.Error);
                    alert(Bypass3DS_array);

                    return;
                }

            } catch (error) {
                console.log("error :::", error);
                toast.error(error?.response?.statusText, { position: toast.POSITION.TOP_LEFT });

                return


            }
        }

    return (
        <React.Fragment>
            {!showModal && valueState ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%', position:'fixed', zIndex:9999, margin: 'auto', top:0, left:0, bottom:0, right:0, height:'100%', background: 'radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8))'  }}>
                    <CircularProgress/>
                </Box>
                :""
            }
            <ToastContainer />
            <Box component='div' className='page_default start_next_page'>

                <Box component='div' className='heading'>
                    <Typography component='h1' variant='h1'>
                        {createCake ? createCake.heading : ''}
                    </Typography>
                </Box>
                <Box component='div' className='category_options'>

                    {!loader && categories.length > 0 ? (
                        categories?.map((val, index) => {

                            if (val.MetaTitle === "HomePage") {
                                if (!checkLang) {
                                    setcheckLang(true);
                                    setMetaTitle(1);
                                }

                                return (
                                    <Box style={ valueState ? { pointerEvents:"none" } : {}} component='div' className='category_box box_1' onClick={(event) => handleClick(event, val)} key={index}>
                                        {showModal && <VerificationModal lang={createCake} CategoryId={val?.Description} CategoryId2={val?.Id} CategoryName={val?.CatName} deleteProductShopping={deleteProductShopping} ShopGetShoppingCart={ShopGetShoppingCart} setValueState={setValueState} />}
                                        <Box component='div' className='cat_image'>
                                            <img alt='Category' src={val?.picture?.PicturePath} />
                                        </Box>
                                        <Box component='div' className='cat_title'>
                                            <Typography component='h1' className='cat_name'>{val?.CatName}</Typography>
                                        </Box>
                                    </Box>
                                )
                            }
                        })
                    ) :
                        (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                                <CircularProgress />
                            </Box>
                        )}

                    {!loader && metaTitle == 0 && (
                        <Typography align='center'>لا توجد فئة في اللغة العربية</Typography>
                    )}

                </Box>

                {/* {!getApplePay ?
                    <Button onClick={() => initiateSessionHandler()}>Open</Button>
                    :
                    <Button onClick={() => setApplePay(false)}>Close</Button>
                } */}

            {/* {getApplePay ?
                <Box component='div' className='category_options' >
                    <iframe src={`${API_URL}/embedded_payment.html`} width="100%" height="500px" title="Payment Gateway" style={{ border: 'none' }}></iframe>
                </Box>
                :""
            } */}
            </Box>
        </React.Fragment>
    )
}
