import {
	SEARCH_PRODUCT_START,
    SEARCH_PRODUCT_SUCCESS,
    SEARCH_PRODUCT_FAIL,
} from '../constants/productAction';

const initialState = {
    loading: false,
    data: null,
    error: false,
};

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_PRODUCT_START:
            return { ...state, loading: true };
        case SEARCH_PRODUCT_FAIL:
            return { ...state, loading: false, error: true };
        case SEARCH_PRODUCT_SUCCESS:
            return { ...state, loading: false, data: action.payload };
        default:
            return state;
    }
};



export default ProductReducer