import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Typography, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";
import { API_URL, MunchBakeryApiUrl } from "../../global";
import axios from "axios";
import moment from 'moment';

export default function ChooseLocationModal({ StoreFetch, setAvailableSlot, setBookedSlot,  CategoryId2 }) {
    const store = useSelector((state) => state);
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = useState(user);
    const lang = useSelector((state) => state);
    const [categories, setCategories] = useState([]);
    const [categoryId, SetCategoryId] = useState("");
    const navigate = useNavigate();
	const [getCartDetail, setCartDetail] = React.useState(localStorage.getItem("cart_details") ? JSON.parse(localStorage.getItem("cart_details")) : "");

    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const locationText = lang.Lang.data?.location ? lang.Lang.data.location : '';
	const custtoken = lang?.auth?.user?.cus_to || store1[0]?.cus_to;
	const custId = store1[0]?.cus_id ? store1[0]?.cus_id : ""
    const [getClick, setClick] = React.useState(false);




    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    useEffect(() => {
        if (store.auth.isLoggedIn) {
            setCategories(JSON.parse(localStorage.getItem("category")));
            let categoryId = localStorage.getItem("categoryId");
            SetCategoryId(JSON.parse(categoryId))
        }
    }, [store.auth.isLoggedIn]);

    useEffect(() => {
        if (CategoryId2) {
            SetCategoryId({ Id: CategoryId2 });
        }
    }, [CategoryId2])


    const handleClick = async (e, val) => {
        localStorage.setItem("catClick", false)
        if (store.auth.isLoggedIn) {
            let path = '/location';
            let Id = val?.Description;
            let Id2 = val?.Id;
            let categoryName = val?.CatName;

            let apiHit = true;
            let state = { CategoryId: Id, CategoryId2: Id2, apiTrigger: apiHit, categoryName: categoryName, custGuid: custGuid, custId: custId, token: custtoken };
            // await ShopGetShoppingCart(state);
            // await deleteProductShopping(path, state);

            if (!getClick) {
                await deleteProductShopping(path, state);
            } else {
                await ShopGetShoppingCart(path, state);
            }
        }
    }

    const ShopGetShoppingCart = async (path, state) => {
        let langName = store?.Lang?.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: state?.custGuid,
            CustomerId: state?.custId ? state?.custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "1",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            // console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/GetShoppingcartsummary/, --api');
            if (!response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem) {
                setClick(true)
                await addToCart(path, state);
            }

        } catch (error) {
            console.log("err", error);

        }

    }

    const deleteProductShopping = async (path, state) => {
        await ShopGetShoppingCart(path, state);

        let langName = store.Lang.data?.lang;
        const langId = langName == 'ar' ? 0 : 1;

        let data = {
            langId: langId,
            CustomerGuid: state?.custGuid
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/DeleteProductShoppingCartMunchCake/`, data, header);
            // console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/DeleteProductShoppingCartMunchCake/, --api');

            // await addItemToCart(path, state);
            await getShopGetShoppingCart(path, state);


        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const getShopGetShoppingCart = async (path, state) => {
        let langName = store.Lang.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: state?.custGuid,
            CustomerId: state?.custId ? state?.custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            // console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/GetShoppingcartsummary/, --api');
            if (response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem) {
                let result = response?.data?.GetShoppingCartSummaryResult;
                result?.LstECommerceShoppingCartItem?.map(async (v, i) => {
                    await AddUpdateShoppingCart(path, state, v);
                })
            } else {
                setClick(true);
                await addToCart(path, state)
            }

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again");
        }

    }

    const AddUpdateShoppingCart = async (path, state, result) => {
        let order_date = moment(result?.UpdatedOn, 'DD/MM/YYYY');
        let newOrderDate = moment(order_date).format("MM/DD/YYYY");

        const data = {
            CustomerId: state?.custId,
            CustomerGuid: state?.custGuid,
            CityId: -1,
            DistrictId: -1,
            MarkAsDeleted: 1,
            OrderDate: newOrderDate,
            Price: result?.Price,
            ProductId: result?.ProductId,
            Quantity: result?.Quantity,
            ShoppingCartId: result?.ShoppingCartId,
            StoreId: result?.StoreId,
            ZoneId: "15"
        };

		try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }

			const response = await axios.post(`${MunchBakeryApiUrl}/AddUpdateShoppingCartItemMunchBakery/`, data, header);
			// console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/AddUpdateShoppingCartItemMunchBakery/, --api');
			if (response?.data) {
				localStorage.setItem("form_data", JSON.stringify(data));
                await addToCart(path, state);
			}
		} catch (error) {
			console.log("err", error);
			return alert("Something went wrong. Please try again")
		}

	}

    const addToCart = async (path, state) => {

        let product_id;
        if (state?.CategoryId2 == "20") {
            product_id = '2032';
        } else if (state?.CategoryId2 == "81") {
            product_id = '88';
        } else if (state?.CategoryId2 == "21") {
            product_id = '1806';
        } else if (state?.CategoryId2 == "57") {
            product_id = '799';
        } else {
            product_id = '-1'
        }

        let data = {
            ProductId: product_id,
            LanguageId: 1,
            CustomerGuid: state?.custGuid,
            IsAddToCart: true,
            NumberOfPersons: 10,
            NumberOfLayers: 1,
            lstLayersInfo: [
                {
                    SecondHalfFlavorId: 1,
                    FirstHalfFlavorId: 1,
                    LayerNumber: 1
                }
            ],
            LstPhotos: [
                {
                    "PicturePath": ""
                },
                {
                    "PicturePath": ""
                },

            ],
            CakeMessage: "message",
            CakeDescription: "Description"
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${state?.token}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/AddToCartMunchCakeProduct/`, data, header);
            // console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/AddToCartMunchCakeProduct/, --api');
            if (response?.data?.Response?.Status == "ERROR") {
                return alert("Something went wrong. Please try again")
            } else {
                await ShopGetShoppingCart(path, state);
                localStorage.setItem("categoryId", JSON.stringify({ Id: state?.CategoryId2 }));
                localStorage.setItem("categoryName", JSON.stringify({ categoryName: state?.categoryName }))
                localStorage.removeItem("form_data")
                navigate(path, { state: state, replace: true });
                StoreFetch();
                setAvailableSlot([]);
                setBookedSlot([]);
            }
        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }


    const list = (anchor) => (
        <Box
            className="chooselocation_modal"
            role="presentation"
        >
            <Box component='div' className='location_modal_content'>
                <Typography component='h4' variant='h4'>{locationText?.modal_content_heading1}</Typography>
                <Typography component='h6' variant='h6'>{locationText?.modal_content_heading2}</Typography>
                <Box component='div' className='modal_start_category'>
                    {categories?.length > 0 ? (
                        categories?.map((val, index) => {
                            if (val?.MetaTitle === "HomePage" && val?.Id !== categoryId?.Id) {
                                return (
                                    <Box component='div' className='start_category' onClick={(event) => handleClick(event, val)} key={index}>
                                        <Box component='div' className='category_image'>
                                            {/* <img src='images/cat1.jpg' /> */}
                                            <img src={val?.picture?.PicturePath} />
                                        </Box>
                                        <Box component='div' className='category_title'>
                                            <Typography component='h1' className='cat_name'>{val?.CatName}</Typography>
                                            {/* <Typography component='h1' className='cat_name'>{locationText?.order_now}</Typography> */}

                                        </Box>
                                    </Box>
                                )
                            }
                        })
                    ) :
                        ""
                    }
                    {/* <Box component='div' className='start_category'>
                                    <Box component='div' className='category_image'>
                                        <img src='images/cat1.jpg' />
                                    </Box>
                                    <Box component='div' className='category_title'>
                                        <Typography component='h1' className='cat_name'>{locationText?.order_now}</Typography>
                                    </Box>
                                </Box> */}
                    {/* <Box component='div' className='start_category'>
                                    <Box component='div' className='category_image'>
                                        <img src='images/cat1.jpg' />
                                    </Box>
                                    <Box component='div' className='category_title'>
                                        <Typography component='h1' className='cat_name'>{locationText?.order_now}</Typography>
                                    </Box>
                                </Box> */}
                </Box>
            </Box>
            <Box
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
            </Box>
        </Box>

    );

    return (
        <React.Fragment>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}
                        aria-label="verofiction setup"
                        aria-controls="verification-modal"
                        aria-haspopup="true"
                        className='fully_booked_btn'
                    >
                        {locationText?.fully_booked}
                    </Button>
                    <Drawer
                        className="drawer_main_div_pickup"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
