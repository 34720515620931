import ReactGA4 from "react-ga4";



const InitializeGoogleAnalytics = () => {

  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize("G-T1CWFC4HS6");

//   console.log("GA INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (category = "", action = "", label ="", value = "") => {
//   console.log("GA event :::", category, ":::", action, ":::", label, ":::", value);

  // Send GA4 Event
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
    value:value
  });

};

const TrackGoogleAnalyticsPageView = (hitType = "", page = "", title ="") => {
    // console.log("GA Page view :::", hitType, ":::", page, ":::", title);

    // Send GA4 Page View
    ReactGA4.send({ hitType: hitType, page: page, title: title });

  };


export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, TrackGoogleAnalyticsPageView };
