import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';

export default function BasicSelect(props) {
    const dispatch = useDispatch();
    const lang1 = useSelector((state) => state);

    const getLocal = localStorage.getItem('lang');
    const { langChange } = props;


    let lang = 'en';

    if (getLocal) {
        lang = getLocal
    }


    const changeLanguageDirection = (lang) => {
        if (lang == "ar") {
            document.body.classList.remove('english');
            document.body.classList.add('arabic');
        } else {
            document.body.classList.add('english');
            document.body.classList.remove('arabic');
        }
        document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
    };

    const selectedLanguage = lang;
    changeLanguageDirection(selectedLanguage);

    const handleChange = (event) => {
        langChange(event.target.value, dispatch);
    };

    return (
        <Box className='menu_lng'>
            <FormControl fullWidth>
                {lang1?.Lang?.data?.lang == "en" ?
                    <Button id='lang-selector' value='ar' onClick={handleChange} className='select_lang_item'>{lang1?.Lang?.data?.lang == "en" ? "عربي" : "Arabic"} </Button>
                    :
                    <Button id='lang-selector' value='en' onClick={handleChange} className='select_lang_item'>English</Button>
                }
            </FormControl>
        </Box>
    );
}
