import { combineReducers } from 'redux';
import auth from "./auth";
import Lang from "./lang";
import Category from "./categories";
import CategoryReducer from "./categoriesCake";
import OrderReducer from "./orderDetail";
import ProductReducer from "./product";
import Locations from "./location";



const Reducers = combineReducers({
    auth,
    Lang,
    Category,
    CategoryReducer,
    OrderReducer,
    ProductReducer,
    Locations
});

export default Reducers