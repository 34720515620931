import {
	SEND_OTP_SUCCESS,
	SEND_OTP_FAIL,
	REGISTER_START,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	LOGIN_START,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	VERIFY_OTP_SUCCESS,
	VERIFY_OTP_FAIL
} from '../constants/ActionCategoryTypes';
import CryptoJS from 'crypto-js';

let encryptedUser = localStorage.getItem('user') ? CryptoJS.AES.decrypt(localStorage.getItem('user'), 'secretKey123').toString(CryptoJS.enc.Utf8) : null;

const user = JSON.parse(encryptedUser);
const initialState = user
	? { isLoggedIn: true, user, loading: false, data: null, image: null, error: false }
	: { isLoggedIn: false, user: null, loading: false, data: null, image: null, error: false };

const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_START:
			return { ...state, loading: true };
		case REGISTER_FAIL:
			return { ...state, loading: false, error: true };
		case REGISTER_SUCCESS:
			return { ...state, loading: false, error: false, data: action.payload };
		case LOGIN_START:
			return { ...state, loading: true };
		case LOGIN_FAIL:
			return { ...state, loading: false, error: true };
		case LOGIN_SUCCESS:
			return { ...state, isLoggedIn: true, loading: false, data: action.payload };
		case SEND_OTP_FAIL:
			return { ...state, loading: false, error: true };
		case SEND_OTP_SUCCESS:
			return { ...state, loading: false, data: action.payload };
		case VERIFY_OTP_FAIL:
			return { ...state, loading: false, error: true, data: action.payload };
		case VERIFY_OTP_SUCCESS:
			return { ...state, loading: false, error: false, data: action.payload };
		case "GET_IMAGE":
			return { ...state, isLoggedIn: true, loading: false, image: action.payload };
		case "GET_USER":
			return { ...state, isLoggedIn: true, loading: false, user: action.payload };
		default:
			return state;
	}
};


export default AuthReducer;