import axios from "axios";
import { API_URL, MunchBakeryApiUrl } from "./../global";

import bcrypt from 'bcryptjs';




// const send_otp = (country_code, phone) => {
//   phone = `${country_code}${phone}`
//   var number = phone.replace(/\s+/g, "").replace(/\+/g, "");
//   const data = {
//     phone: number,
//   };

//   return axios.post(
//     API_URL + "/send-otp",
//     data
//   );
// };

// const send_otp = (country_code, phone) => {

//   let random_num = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
//   phone = `${country_code}${phone}`

//   var number = phone.replace(/\s+/g, "").replace(/\+/g, "");

//   const data = {
//     AppSid: "T2gnKUcxyvDb5CwyUUcVSPi6Skqkx6",
//     Recipient: Number(number),
//     Body: `Dear Customer, Your One Time Password(OTP) is ${random_num}.`
//   };

//   let header = {
//     headers: {
//       "Content-Type": "application/json"
//       // "Accept":"application/json"

//     }
//   }

//   let hash = bcrypt.hashSync(random_num, 4);
//   localStorage.setItem("user_otp", JSON.stringify({ phone_number: data?.Recipient, otp: hash, with_otp:random_num }));
//   return axios.post(`${API_URL}/sms`,
//     data,
//     header
//   );
// };

const send_otp = (country_code, phone) => {

    // let random_num = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
    phone = `${country_code}${phone}`

    var number = phone.replace(/\s+/g, "").replace(/\+/g, "");

    const data = {
      recipient: Number(number),
      type:"sms"
    };

    let header = {
      headers: {
        "Content-Type": "application/json"
      }
    }

    // let hash = bcrypt.hashSync(random_num, 4);
    // localStorage.setItem("user_otp", JSON.stringify({ phone_number: data?.Recipient, otp: hash, with_otp:random_num }));
    localStorage.setItem("user_otp", JSON.stringify({ phone_number: data?.recipient }));

    return axios.post(`${API_URL}/sms-otp`,
      data,
      header
    );
  };


// const verify_otp = (phone, otp) => {

//   const data = {
//     phone: phone,
//     otp: otp
//   };

//   return axios.post(
//     API_URL + "/verify-otp",
//     data
//   )

// };

const verify_otp = (phone, otp) => {

    const data = {
      phone_number: phone,
      otp: otp
    };

    return axios.post(
      API_URL + "/verify-sms-otp",
      data
    )

  };

// const register = (userName, email, password, firstName, lastName, captchaResponse, CategoryId) => {
//   const data = {
//     UserName: userName,
//     Email: email,
//     Password: password,
//     FirstName: firstName,
//     LastName: lastName,
//     captcha: captchaResponse,
//     prodId: CategoryId,
//   };

//   return axios.post(
//     API_URL + "/register",
//     data
//   );
// };

const register = (userName, email, password, firstName, lastName, captchaResponse) => {
  const data = {
    UserName: userName,
    Email: email,
    Password: password,
    FirstName: firstName,
    LastName: lastName,
    // Password: "123",
    // FirstName: "abc",
    // LastName: "abc",
    captcha: captchaResponse
  };

  const header = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  return axios.post(`${MunchBakeryApiUrl}/RegisterMunchBakeryGuestCustomer/`, data, header);
};

const login = async (username, token, password) => {

  const data = {
    UserNameEmail: username?.trim(),
    Password: password,
    // MobileTokenId:token
  };

  const header = {
    headers: {
      "Content-Type": "application/json"
    }
  }

  return axios.post(`${MunchBakeryApiUrl}/ValidateMunchBakeryLogin/`, data, header);
};

// const login = async (username, password, cateId) => {

//   const data = {
//     UserName: username.trim(),
//     Password: "123",
//     OldGuestCustomerGuid: "",
//     DeviceSourceType: "2",
//     // MobileTokenId: password,
//     cateId: cateId
//   };
//   return axios.post(
//     API_URL + "/login",
//     data
//   );
//   // if (response.data.accessToken) {
//   //   localStorage.setItem("user", JSON.stringify(response.data));
//   // }

// };

const logout = () => {
  // localStorage.removeItem("user");
  localStorage.clear();
  window.location.replace("/");

};

export default {
  send_otp,
  verify_otp,
  register,
  login,
  logout,
};
