import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	SEND_OTP_SUCCESS,
	SEND_OTP_FAIL,
	VERIFY_OTP_SUCCESS,
	VERIFY_OTP_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT
} from "./../constants/ActionCategoryTypes";

import AuthService from "../services/auth.service";
import CryptoJS from 'crypto-js';
import bcrypt from 'bcryptjs';
import { TrackGoogleAnalyticsEvent } from "../utils/google-analytics/events";
import { TrackGoogleTagManagerEvent } from "../utils/google-tag-manager/events";




export const sendOtp = (country_code, phone) => async (dispatch) => {
	try {
		const response = await AuthService.send_otp(country_code, phone);
		// console.log("https://el.cloud.unifonic.com/rest/SMS/messages --api")
		if (response.data.success) {
			dispatch({
				type: SEND_OTP_SUCCESS,
				payload: response.data
			});
			return response
		} else {
			dispatch({
				type: SEND_OTP_FAIL,
			});
			localStorage.removeItem("user_otp")
			return response
		}
	} catch (error) {
		// let message = "An error occurred while registering.";
		dispatch({
			type: SEND_OTP_FAIL,
		});
		return error;
	}
};

export const verifyOtp = (country_code, phone, otp) => async (dispatch) => {
	try {
        let phone_number = `${country_code}${phone}`
        let number = phone_number.replace(/\s+/g, "").replace(/\+/g, "");

		// let number = `${country_code}${phone.replace(/\s/g, "")}`;
		// let newValue = number.replace("+", "");
		// let user = localStorage?.getItem("user_otp");
		// let detail = JSON.parse(user);
		// let isExist = bcrypt.compareSync(otp, detail?.otp);
		// if (detail?.phone_number == newValue && isExist) {

			// const response = {
			// 	data: {
			// 		'type': 'OTP_VERIFICATION',
			// 		'success': true,
			// 		'message': 'OTP verification successful'
			// 	}
			// }
			// if (response.data.success) {
			// 	dispatch({
			// 		type: VERIFY_OTP_SUCCESS,
			// 		payload: response.data
			// 	});
			// 	localStorage.removeItem("user_otp")
			// 	return response
			// } else {
			// 	dispatch({
			// 		type: VERIFY_OTP_FAIL,
			// 		payload: response.data
			// 	});
			// 	return response
			// }
			const response = await AuthService.verify_otp(number, otp);
			if (response.data.success) {
				dispatch({
					type: VERIFY_OTP_SUCCESS,
					payload: response.data
				});
				localStorage.removeItem("user_otp")
				return response
			} else {
				dispatch({
					type: VERIFY_OTP_FAIL,
					payload: response.data
				});
				return response
			}
		// } else {
		// 	dispatch({
		// 		type: VERIFY_OTP_FAIL,
		// 		// payload: response.data
		// 	});
		// 	return;
		// }

		// return Promise.resolve();
	} catch (error) {
		let message = "An error occurred while registering.";
		dispatch({
			type: VERIFY_OTP_FAIL,
		});
		return Promise.reject(message);
	}
};

// export const register = (username, email, password, firstName, lastName, captchaResponse, CategoryId) => async (dispatch) => {
// 	try {
// 		const response = await AuthService.register(username, email, password, firstName, lastName, captchaResponse, CategoryId);
// 		console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/RegisterMunchBakeryGuestCustomer/", "--api");
// 		if (!response?.data?.success) {
// 			dispatch({
// 				type: REGISTER_FAIL,
// 				payload: response.data
// 			});
// 			return response
// 		} else {
// 			dispatch({
// 				type: REGISTER_SUCCESS,
// 				payload: response.data
// 			});
// 			return response
// 		}


// 	} catch (error) {
// 		let message = "An error occurred while registering.";
// 		dispatch({
// 			type: REGISTER_FAIL,
// 			payload: error
// 		});
// 		return Promise.reject(message);
// 	}
// };

export const register = (username, email, password, firstName, lastName, captchaResponse) => async (dispatch) => {
	try {
		const response = await AuthService.register(username, email, password, firstName, lastName, captchaResponse);

		if (!response?.data) {
			dispatch({
				type: REGISTER_FAIL,
				payload: response.data
			});
			return response
		} else {
			dispatch({
				type: REGISTER_SUCCESS,
				payload: response.data
			});
			return response
		}


	} catch (error) {
		let message = "An error occurred while registering.";
		dispatch({
			type: REGISTER_FAIL,
			payload: error
		});
		return Promise.reject(message);
	}
};


export const login = (username, token, password) => async (dispatch) => {


	try {
		const response = await AuthService.login(username, token, password);
        TrackGoogleAnalyticsEvent("login", "login", "login", 5);
        TrackGoogleTagManagerEvent('login', 'method', 1);


		if (response?.data) {
			if (response.data?.CustomerId) {
				let cus_id = response.data?.CustomerId;
				let cust_guid = response.data?.CustomerGuid;
				let cus_to = response.data?.token;
				let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify({ cus_id, cust_guid, cus_to }), 'secretKey123').toString();
				localStorage.setItem('user', encryptedUser);
			} else {
			}
			dispatch({
				type: LOGIN_SUCCESS,
				payload: response.data,
			});
			// localStorage.removeItem("user_otp")
			return response
		} else {
			dispatch({
				type: LOGIN_FAIL,
				payload: response.data,
			});
			return response
		}

	} catch (error) {
		return Promise.resolve();
	}
};

// export const login = (username, password, cateId) => async (dispatch) => {
// 	try {
// 		const response = await AuthService.login(username, password, cateId);
// 		console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/ValidateMunchBakeryLogin/", "--api");
// 		// console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/DeleteProductShoppingCartMunchCake/", "--api");
// 		// console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/AddUpdateShoppingCartItemMunchBakery/, --api');
// 		// console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/AddToCartMunchCakeProduct/", "--api");
// 		if (response?.data?.success) {
// 			if (response.data?.data?.CustomerId) {
// 				let cus_id = response.data?.data?.CustomerId;
// 				let cust_guid = response.data?.data?.CustomerGuid;
// 				let cus_to = response.data?.data?.token;
// 				let encryptedUser = CryptoJS.AES.encrypt(JSON.stringify({ cus_id, cust_guid, cus_to }), 'secretKey123').toString();
// 				localStorage.setItem('user', encryptedUser);
// 			} else {
// 			}
// 			dispatch({
// 				type: LOGIN_SUCCESS,
// 				payload: response.data,
// 			});
// 			return response
// 		} else {
// 			dispatch({
// 				type: LOGIN_FAIL,
// 				payload: response.data,
// 			});
// 			return response
// 		}

// 	} catch (error) {
// 		return Promise.resolve();
// 	}
// };

export const logout = () => {
	AuthService.logout();
	// dispatch({
	// 	type: LOGOUT,
	// });
};
