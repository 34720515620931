import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material'
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import CryptoJS from "crypto-js";
import moment from 'moment';
import axios from 'axios';
import { MunchBakeryApiUrl } from '../../global';
// import 'moment/locale/ar';




export default function MyOrderDetail() {
    const lang = useSelector((state) => state);
    const [getPhoneNumber, setPhoneNumber] = React.useState(localStorage.getItem("cusMob") ? JSON.parse(localStorage.getItem("cusMob")) : "");


    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = React.useState(user);
    const custtoken = lang?.auth?.user?.cus_to || store1[0]?.cus_to;
    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;


    const orderDetailText = lang.Lang.data?.orders_details ? lang.Lang.data.orders_details : '';
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';

    const langId = lang.Lang.data?.lang ? lang.Lang.data?.lang : "";

    const [getLatestOrder, setLatestOrder] = React.useState("");
    const [getOrderStatus, setOrderStatus] = React.useState("");
    const [getLoader, setLoader] = React.useState(true);
    const [getLoader2, setLoader2] = React.useState(true);





    const getAllOrderList = async () => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.get(`${MunchBakeryApiUrl}/GetCustomerOrderMunchBakery/${custGuid}/`, header);
            // console.log(`${MunchBakeryApiUrl}/GetCustomerOrderMunchBakery/${custGuid}/`, '--api');
            if (response?.data?.length > 0) {
                let latestOrder = response?.data[0];
                await getLatestOrderList(latestOrder);
            }

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const getLatestOrderList = async (latestOrder = "") => {
        setLoader2(true);
        let languageId = langId == 'ar' ? 0 : 1;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.get(`${MunchBakeryApiUrl}/GetOrderDetailMunchBakery/${custGuid}/${latestOrder?.OrderId}/${languageId}`, header);
            // console.log(`${MunchBakeryApiUrl}/GetOrderDetailMunchBakery/${custGuid}/${latestOrder?.OrderId}/${languageId}`, '--api');
            if (response?.data) {
                // await encryptStringHandle(response?.data);
                await statusOfOrder(response?.data);
                setLatestOrder(response?.data);
                setLoader2(false);
            }


        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const encryptStringHandle = async (list) => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                encryptString: list?.OrderId
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/EncryptString/`, data, header);
            // console.log(`${MunchBakeryApiUrl}/EncryptString/`, '--api');
            if (response?.data?.EncryptStringResult) {

                await GetOrderDetailsForInvoice(response?.data?.EncryptStringResult);

            }

        } catch (error) {
            console.log("err", error);
        }

    }

    const GetOrderDetailsForInvoice = async (encryptData) => {
        let language = langId == 'ar' ? "0" : "1";


        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                langId: language,
                OrderId: encryptData != "" ? encryptData : ""
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetOrderDetailsForInvoice/`, data, header);
            // console.log(`${MunchBakeryApiUrl}/GetOrderDetailsForInvoice/`, '--api');

            if (response?.data?.GetOrderDetailsForInvoiceResult) {
                setLatestOrder(response?.data?.GetOrderDetailsForInvoiceResult);
                setLoader2(false);
            }


        } catch (error) {
            console.log("err", error);
        }

    }



    const changeOrderDateFormat = () => {
        let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
        let new_date = moment(order_date).format('DD MMM YYYY');

        let changeToAR = new Date(new_date);
        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).format(changeToAR);

        return langId == "en" ? new_date : In_arabic;
    }

    const timeOrderHandler = () => {
        let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
        let new_date = moment(order_date).format('DD MMM YYYY');

        let changeToAR = new Date(`${new_date} ${getLatestOrder?.DeliveryTime}`);

        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
               hour: 'numeric',
               minute: 'numeric',
               hour12: true
            }).format(changeToAR);

        return langId == "en" ? moment(`${new_date} ${getLatestOrder?.DeliveryTime}`).format("hh:mm A") : In_arabic;
    }

    useEffect(() => {

        getAllOrderList();
        // changeOrderDateFormat();

    }, [langId])


    const statusOfOrder = async (list) => {
        let mob = ((getPhoneNumber?.phone).trim()).length < 10 ? `0${getPhoneNumber?.phone}` : getPhoneNumber?.phone;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                MobileNumber: mob,
                OrderNumber: list?.OrderIdShowCustomer
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetMunchBakeryOrderStatus/`, data, header);
            // console.log(`${MunchBakeryApiUrl}/GetMunchBakeryOrderStatus/`, '--api');
            if (response?.data?.GetMunchBakeryOrderStatusResult) {
                setOrderStatus(response?.data?.GetMunchBakeryOrderStatusResult);
                setLoader(false);
            }

        } catch (error) {
            console.log("err", error);
        }

    }

    return (
        // <React.Fragment>
        <Box component='div' className='page_default my_order_details'>
            <Box component='div' className='content'>
                <Box component='div' className='ocassion_detail'>
                    <Box component='div' className='text_btn'>
                        <Box component='div' className='img_box'>
                            <Typography component='h2' variant='h2'>
                                {orderDetailText?.order_number}
                            </Typography>
                            {/* <Typography component='h1' variant='h1' >
                                    Occassion Cake 22 oct 2023
                                </Typography> */}
                            {!getLoader2 ?
                                <Typography component='h1' variant='h1' >
                                    {getLatestOrder?.OrderGuid ? getLatestOrder?.OrderGuid : ""}
                                </Typography>
                                // getLatestOrder?.LstOrderProducts?.map((value, index) =>
                                //     <Typography component='h1' variant='h1' key={index}>
                                //         {value?.ProductName == "Character" ? flavorText?.paid_char : value?.ProductName} {changeOrderDateFormat()}
                                //     </Typography>
                                // )
                                :
                                <Skeleton animation="wave" width={100} />
                            }
                            {/* <Typography component='h2' variant='h2'>
                                {orderDetailText?.image_attached}
                            </Typography> */}
                        </Box>
                        {!getLoader ?
                            <Button variant="contained">{getOrderStatus}</Button>
                            :
                            <Box>
                                <Skeleton animation="wave" width={150} />
                            </Box>
                        }
                    </Box>
                    {/* <Box component='div' className='images_attached'>
                        {!getLoader2 ?
                            getLatestOrder?.LstOrderProducts?.map((value, index) => {

                                return (<Box component='div' className='img_box' key={index}>
                                    <img src={value?.Picture?.PicturePath} />
                                </Box>)
                            }
                            )
                            // getLatestOrder?.lstPhotosUrls?.length > 0 && (
                            //   <Box component='div' className='img_box' >
                            //     <img src={getLatestOrder?.lstPhotosUrls?.[0]} />
                            //   </Box>
                            // )
                            :
                            <Skeleton variant="circular" animation="wave" width={80} height={80} />
                        }
                    </Box> */}
                </Box>
                <Box component='div' className='other_details'>
                    {getLatestOrder?.Notes ?
                        <Box component='div' className='box'>
                            <Typography component='h3' variant='h3'>
                                {orderDetailText?.description_heading}
                            </Typography>
                            {!getLoader2 ?
                                <Typography component='p' variant='p'>
                                    {getLatestOrder?.Notes}
                                </Typography>
                                :
                                <Skeleton animation="wave" width={200} />
                            }
                        </Box>
                        : ""}
                    {/* <Box component='div' className='box'>
                            <Typography component='h3' variant='h3'>
                                {orderDetailText?.style}
                            </Typography>
                            <List>
                                {!getLoader2 ?
                                    <>
                                        <ListItem>
                                            <ListItemText primary={`${getLatestOrder?.NumberOfPersons} persons cake`}></ListItemText>
                                        </ListItem>
                                        {getLatestOrder?.LstOrderProducts?.map((value, index) =>
                                            <ListItem key={index}>
                                                <ListItemText primary={`${value?.Quantity} Object/Character`} ></ListItemText>
                                            </ListItem>
                                        )}
                                        <ListItem>
                                            <ListItemText primary={`${getLatestOrder?.NumberOfLayers} Level`}></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText primary='Flavor is “Red velvet”'></ListItemText>
                                        </ListItem>
                                    </>

                                    :
                                    <>
                                        <ListItem>
                                            <ListItemText> <Skeleton animation="wave" width={100} /></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> <Skeleton animation="wave" width={100} /></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> <Skeleton animation="wave" width={100} /></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> <Skeleton animation="wave" width={100} /></ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemText> <Skeleton animation="wave" width={100} /></ListItemText>
                                        </ListItem>
                                    </>
                                }
                            </List>
                        </Box> */}
                    <Box component='div' className='box'>
                        <Typography component='h3' variant='h3'>
                            {orderDetailText?.pickup}
                        </Typography>
                        {!getLoader2 ?
                            <Typography component='p' variant='p'>
                                {langId == "en" ? getLatestOrder?.StoreNameEn : getLatestOrder?.StoreNameAr}
                            </Typography>
                            :
                            <Typography component='p' variant='p'>
                                <Skeleton animation="wave" width={50} />
                            </Typography>
                        }
                        {!getLoader2 ?
                            <Typography component='p' variant='p'>
                                {changeOrderDateFormat()} {langId == 'en' ? '@' : 'في'} {timeOrderHandler()}
                            </Typography>
                            :
                            <Typography component='p' variant='p'>
                                <Skeleton animation="wave" width={150} />
                            </Typography>
                        }
                    </Box>
                    <Box component='div' className='box'>
                        <Typography component='h3' variant='h3'>
                            {orderDetailText?.price}
                        </Typography>
                    </Box>
                    <Box component='div' className='box'>
                        {!getLoader2 ?
                            <Typography component='h3' variant='h3'>
                                {orderDetailText?.currency} {getLatestOrder?.OrderTotal ? getLatestOrder?.OrderTotal : ""}
                            </Typography>
                            :
                            <Typography component='h3' variant='h3'>
                                <Skeleton animation="wave" width={100} />
                            </Typography>
                        }
                    </Box>
                </Box>
            </Box>
        </Box>
        // </React.Fragment>
    )
}
