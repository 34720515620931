import {
    ORDER_DETAILS,
    ORDER_DESTROY
} from '../constants/orderAction';

const GetOrder = localStorage.getItem('orderDetails');
const jsonOrder = JSON.parse(GetOrder);
const initialState = jsonOrder ? {
    cake_name: jsonOrder.cake_name,
    store_name: jsonOrder.store_name,
    cake_price: jsonOrder.cake_price,
    total_price: jsonOrder.total_price,
    cake_image: jsonOrder.cake_image,
    cake_layer: jsonOrder.cake_layer,
    layer_price: jsonOrder.layer_price,
    character: jsonOrder.character,
    character_price: jsonOrder.character_price,
    cake_flavor: jsonOrder.cake_flavor,

} : {
    cake_name: '',
    store_name: '',
    cake_price: '',
    total_price: '',
    cake_image: '',
    cake_layer: '',
    layer_price: '',
    character: '',
    character_price: '',
    cake_flavor: '',
};

const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDER_DETAILS:
            return {
                 ...state,
                 cake_name: action.payload.cake_name,
                 store_name: action.payload.store_name,
                 cake_price: action.payload.cake_price,
                 total_price: action.payload.total_price,
                 cake_image: action.payload.cake_image,
                 cake_layer: action.payload.cake_layer,
                 layer_price: action.payload.layer_price,
                 character: action.payload.character,
                 character_price: action.payload.character_price,
                 cake_flavor: action.payload.cake_flavor,
                };
        case ORDER_DESTROY:
            return state;
        default:
            return state;
    }
};



export default OrderReducer