import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Typography, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-input-verification-code";
import { parsePhoneNumber, isValidNumber } from "libphonenumber-js";
import { sendOtp, verifyOtp, register, login } from "../../actions/auth";
import { useDispatch, useSelector } from "react-redux";
// import { Password } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import ResendCount from "./resendOtp";
import ReCAPTCHA from "react-google-recaptcha";

export default function VerificationModal(props) {
    const { lang, dir, CategoryId, CategoryId2, CategoryName, deleteProductShopping, ShopGetShoppingCart, setValueState } = props;
    const lang1 = useSelector((state) => state);

    const langId = lang1?.Lang?.data?.lang ? lang1?.Lang?.data?.lang : '';

    const [verificationCode, setVerificationCode] = useState("");
    const [loader, setLoader] = useState(false);
    const [reSendLoader, setResLoader] = useState(false);
    // const [OtpValue, setOtp] = useState(null);
    // const [responseSent, setResponseSent] = useState(false);
    // const store = useSelector((state) => state);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        active_btn: false,
        disabled: true,
        verify_disabled: true,
        direction: dir,
    });
    const navigate = useNavigate();
    const [numSubmit, setNumSubmit] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState(null);
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [error, setError] = useState("");
    const [country_code, setCountryCode] = useState("+966");
    const [triggerEffect, setTriggerEffect] = useState(false);




    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setValueState(open);
        setState({ ...state, [anchor]: open });
    };

    const handleSubmit = (event) => {
        setLoader(true);
        dispatch(sendOtp(country_code, phoneNumber)).then((res) => {
            if (res?.data?.success) {
                setNumSubmit(true);
                setLoader(false);
            } else {
                setLoader(false);
                langId =='en' ? alert("Could not send code. Please try again.") : alert("لا يمكن إرسال الرمز!. حاول مرة اخرى.");
            }
        });
    };

    const clickVerify = () => {
        setLoader(true);
        let number = phoneNumber.replace(/\s+/g, "").replace(/\+/g, "");
        let stringNumber = (number)?.toString();
        let numberWithZero = stringNumber?.length < 10 ? `0${number}` : number;
        dispatch(verifyOtp(country_code, number, verificationCode))
            .then((response) => {
                if (response?.data?.success) {
                    dispatch(
                        register(numberWithZero, `${numberWithZero}@munch.com`, verificationCode, "new customer", numberWithZero, captchaResponse)
                    ).then((result) => {
                        if (result?.data) {
                            let token = result?.data;
                            dispatch(login(`${numberWithZero}@munch.com`, token, verificationCode)).then(async (res) => {
                                if (res?.data) {
                                    let path = "/location",
                                        Id = CategoryId,
                                        Id2 = CategoryId2,
                                        categoryName = CategoryName,
                                        token = res.data?.token,
                                        custGuid = res.data?.CustomerGuid,
                                        custId = res.data?.CustomerId,
                                        mobNumber = res?.data?.UserName,
                                        customer_name = `${res?.data?.FirstName} ${res?.data?.LastName}`,
                                        customer_email = res?.data?.Email;

                                    let state = {
                                        CategoryId: Id,
                                        CategoryId2: Id2,
                                        categoryName: categoryName,
                                        token: token,
                                        custGuid: custGuid,
                                        custId: custId,
                                        mobNumber: mobNumber
                                    };
                                    await deleteProductShopping(path, state);
                                    setLoader(false);
                                    localStorage.setItem("customer_details", JSON.stringify({ name: customer_name, email: customer_email }));
                                    localStorage.setItem("categoryName", JSON.stringify({ categoryName: state?.categoryName }));
                                    localStorage.setItem("categoryId", JSON.stringify({ Id: state?.CategoryId2 }));
                                    localStorage.setItem("cusMob", JSON.stringify({ phone: state?.mobNumber }));
                                    localStorage.setItem("cusCounteryCode", JSON.stringify({ code: country_code }));
                                    localStorage.setItem('loginTime', new Date().getTime());
                                }
                            });
                        } else {
                            setLoader(false);
                        }
                    });
                } else {
                    setLoader(false);
                    langId == 'en' ? alert("Invalid Code") : alert("الرمز غير صحيح")
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    // const clickVerify = () => {
    //     setLoader(true);
    //     var number = phoneNumber.replace(/\s+/g, "").replace(/\+/g, "");
    //     dispatch(verifyOtp(country_code, phoneNumber, verificationCode))
    //         .then((response) => {
    //             if (response?.data?.success) {
    //                 dispatch(
    //                     register(number, `${number}@munch.com`, "123", "New", "Customer", captchaResponse, CategoryId)
    //                 ).then((result) => {
    //                     if (result?.data?.success && result?.data?.data) {
    //                         setLoader(false);
    //                         let token = result?.data?.data;
    //                         dispatch(login(number, token, CategoryId2)).then( async (res) => {
    //                             if (res.data.data) {
    //                                 let path = `/location`,
    //                                     Id = CategoryId,
    //                                     Id2 = CategoryId2,
    //                                     token = res.data?.data?.token,
    //                                     custGuid = res.data?.data?.CustomerGuid,
    //                                     custId = res.data?.data?.CustomerId,
    //                                     mobNumber = res?.data?.data?.UserName;
    //                                 let state = {
    //                                     CategoryId: Id,
    //                                     CategoryId2: Id2,
    //                                     token: token,
    //                                     custGuid: custGuid,
    //                                     custId: custId,
    //                                     mobNumber: mobNumber
    //                                 };
    //                                 await ShopGetShoppingCart(state);
    //                                 await deleteProductShopping(path, state);

    //                                 // navigate(path, { state: state });
    //                                 localStorage.setItem("categoryId", JSON.stringify({ Id: state?.CategoryId2 }));
    //                                 localStorage.setItem("cusMob", JSON.stringify({ phone: state?.mobNumber }))
    //                                 // if (store.Category.data) {
    //                                 //     localStorage.setItem("category", JSON.stringify(store.Category.data.data))
    //                                 // }
    //                             }
    //                         });
    //                     } else {
    //                         setLoader(false);
    //                     }
    //                 });
    //             } else {
    //                 setLoader(false);
    //                 alert("Invalid Code");
    //             }
    //         })
    //         .catch((error) => {
    //             console.error("Error:", error);
    //         });
    // };




    const handleKeyPressCode = evt => {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 44 && (charCode < 48 || charCode > 57)) {
            return evt.preventDefault();
        }
    }


    const handleKeyPress = event => {
        var regex = /[0-9]|\./;
        var key = event.keyCode || event.which;
        key = String.fromCharCode(key);
        if (!regex.test(key)) {
            return event.preventDefault();
        }

        if (event.charCode === 43 || event.charCode === 46) {
            return event.preventDefault();
        }
    }

    const handlePhoneNumberChange = (value) => {

        let newvalue = value.target.value?.replace("+", "")
        setPhoneNumber(newvalue)
        setError("");

        try {
            const phoneNumberInstance = parsePhoneNumber(
                `${country_code}${newvalue.replace(`${country_code}`, "")}`
            );
            if (phoneNumberInstance) {
                const formattedNumber = phoneNumberInstance.format("INTERNATIONAL");

                setPhoneNumber(formattedNumber.replace(`${country_code}`, ""));
                if (
                    isValidNumber(
                        formattedNumber.replace(`${country_code}`, ""),
                        phoneNumberInstance.country
                    )
                ) {
                    if (captchaResponse) {
                        setState({ ...state, active_btn: true, disabled: false });
                    } else {
                        setState({ ...state, active_btn: false, disabled: true });
                    }
                    setError("");
                } else {
                    setState({ ...state, active_btn: false, disabled: true });
                    setError("Invalid phone number");
                }
            }
        } catch (error) {
            console.log("error", error);
            setState({ ...state, active_btn: false, disabled: true });
            if (value.target.value) {
                setError("Invalid phone number");
            }
        }
    };

    const handleChange = (value) => {
        setVerificationCode(value);
        if (value.length === 4) {
            setState({ ...state, active_btn: true, verify_disabled: false });
        } else {
            setState({ ...state, active_btn: false, verify_disabled: true });
        }
    };


    const ResendOtp = () => {
        // setOtp("");
        setResLoader(true);
        dispatch(sendOtp(country_code, phoneNumber)).then((res) => {
            if (res?.data?.success) {
                setResLoader(false);
                setTriggerEffect(true);
                // setCountdown(30);
                // setTriggerEffect(true);
                // setTimeout(() => {
                //     setTriggerEffect(false);
                // }, 100);
            } else {
                setResLoader(false);
                langId == 'en' ? alert("Could not send code. Please try again.") : alert("لا يمكن إرسال الرمز!. حاول مرة اخرى.");
            }
        });
    };



    const phoneNumberRef = useRef();

    const onChangeCaptcha = (value) => {
        if (value) {
            setCaptchaResponse(value);
            try {
                const phoneNumberInstance = parsePhoneNumber(
                    `${country_code}${phoneNumber.replace(`${country_code}`, "")}`
                );
                if (phoneNumberInstance) {
                    const formattedNumber = phoneNumberInstance.format("INTERNATIONAL");

                    setPhoneNumber(formattedNumber.replace(`${country_code}`, ""));
                    if (
                        isValidNumber(
                            formattedNumber.replace(`${country_code}`, ""),
                            phoneNumberInstance.country
                        )
                    ) {
                        setState({ ...state, active_btn: true, disabled: false });
                        setError("");
                    } else {
                        setState({ ...state, active_btn: false, disabled: true });
                        setError("Invalid phone number");
                    }
                }
            } catch (error) {
                console.log("error", error);
                setState({ ...state, active_btn: false, disabled: true });
                if (phoneNumber) {
                    setError("Invalid phone number");
                }
            }
        }
    };

    const list = (anchor) => (
        <Box className="verification_popup" role="presentation">
            {!numSubmit ? (
                <Box component="div" className="verif_content">
                    <Typography component="h4" variant="h4">
                        {lang ? lang.phone_no_heading : ""}
                    </Typography>
                    <Box component="div" className="num_auth_code">
                        <Box component="div" style={{ display: "flex" }}>
                            <TextField
                                id="country_code"
                                size="small"
                                variant="standard"
                                value={country_code}
                                onChange={(e) => setCountryCode(e?.target?.value)}
                                onKeyPress={e => handleKeyPressCode(e)}
                                disabled
                                style={{ width: '50px' }}
                            />
                            <TextField
                                id="phone_number"
                                size="small"
                                variant="standard"
                                value={phoneNumber?.trimStart()}
                                ref={phoneNumberRef}
                                // onPaste={handlePaste}
                                onKeyPress={e => handleKeyPress(e)}
                                onChange={handlePhoneNumberChange}
                                style={{ direction: dir ?? dir }}
                            />
                        </Box>
                        <ReCAPTCHA
                            // sitekey={"6LdUONAjAAAAAEvP0UZgetokdQCsD6v-X50qGT0Z"} // dev
                            sitekey={"6Lc-Lx4pAAAAAKtquWVk23ThhbUVEbvVNpc8H6RN"} // live
                            onChange={onChangeCaptcha}
                        />
                        {error && <p style={{ color: "red" }}>{error}</p>}
                        {langId == 'en' ?
                            <Typography component="p" variant="p">
                                By clicking Confirm you accept the <a href='https://www.munchbakery.com/terms-and-conditions-en' style={{ color:'#B7B7B7' }} target="_blank">Terms and Conditions</a> and <a href='https://www.munchbakery.com/privacy-policy-en' style={{ color:'#B7B7B7' }} target="_blank" >Privacy Policy</a>.
                            </Typography>
                            :
                            <Typography component="p" variant="p">
                                بالنقر على تأكيد ، فإنك توافق على <a  href='https://www.munchbakery.com/terms-and-conditions-ar' style={{ color:'#B7B7B7' }} target="_blank"> الشروط والأحكام</a> و <a href='https://www.munchbakery.com/privacy-policy-ar' style={{ color:'#B7B7B7' }} target="_blank"> سياسة الخصوصية </a>
                            </Typography>
                        }
                        {/* <Typography component="p" variant="p">
                            {lang ? lang.heading_term_condition : ""}
                        </Typography> */}
                        {!loader ? (
                            <Button
                                className={`${state?.active_btn ? "active_btn" : ""} submit_btn`}
                                onClick={handleSubmit}
                                disabled={state?.disabled}
                            >
                                {(phoneNumber?.trimStart() == "" || error) ? lang?.submit : ""}
                                {(!error && phoneNumber) ? lang?.send :""}
                            </Button>
                        ) : (
                            <Button
                                className={`${state?.active_btn ? "active_btn" : ""} submit_btn`}
                                disabled
                            >
                                <CircularProgress />
                            </Button>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box component="div" className="verif_content code_verify">
                    <Typography component="h4" variant="h4">
                        {lang ? lang.sms_code_heading : ""}
                    </Typography>
                    <Box component="div" className="num_auth_code">
                        <ReactCodeInput
                            value={verificationCode}
                            autoFocus
                            placeholder=""
                            onChange={handleChange}
                        />
                        <Typography component="p" variant="p">
                            <ResendCount
                                lang={lang}
                                loader={reSendLoader}
                                ResendOtp={ResendOtp}
                                setTriggerEffect={setTriggerEffect}
                                triggerEffect={triggerEffect}
                            />
                        </Typography>
                        {!loader ? (
                            <Button
                                onClick={clickVerify}
                                disabled={state.verify_disabled}
                                className={`${state.active_btn ? "active_btn" : ""} verify_btn`}
                            >
                                {lang ? lang.verify_btn : ""}
                            </Button>
                        ) : (
                            <Button
                                onClick={clickVerify}
                                className={`${state.active_btn ? "active_btn" : ""} verify_btn`}
                                disabled
                            >
                                <CircularProgress />
                            </Button>
                        )}
                    </Box>
                </Box>
            )}
            <Box
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            ></Box>
        </Box>
    );

    return (
        <React.Fragment>
            {["bottom"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button
                        onClick={toggleDrawer(anchor, true)}
                        size="large"
                        aria-label="verofiction setup"
                        aria-controls="verification-modal"
                        aria-haspopup="true"
                        color="inherit"
                        className="drawer_btn_verif"
                    ></Button>

                    <Drawer
                        className="drawer_main_div"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
