import {
	GET_CATEGORY_START,
	GET_CATEGORY_SUCCESS,
	GET_CATEGORY_FAIL,
	GET_CATEGORY_CAKES_START,
	GET_CATEGORY_CAKES_SUCCESS,
	GET_CATEGORY_CAKES_FAIL,
	GET_STORES_DISTANCE_START,
	GET_STORES_DISTANCE_SUCCESS,
	GET_STORES_DISTANCE_FAIL
} from "../constants/ActionCategoryTypes";

import CategoryService from "../services/category.service";

export const GetCategory = (lang, guid) => (dispatch) => {
	dispatch({
		type: GET_CATEGORY_START,
	});

	return CategoryService.get_category(lang, guid).then(
		(response) => {
			if (response?.data?.success) {
				dispatch({
					type: GET_CATEGORY_SUCCESS,
					payload: response.data,
				});
				return response;
			} else {
				dispatch({
					type: GET_CATEGORY_FAIL,
					payload: response.data,
				});
				return response;
			}

		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject();
		}
	);
};

export const GetCategoryCakes = (lang, id, custtoken) => (dispatch) => {
	dispatch({
		type: GET_CATEGORY_CAKES_START,
	});

	return CategoryService.get_category_cakes(lang, id, custtoken).then(
		(response) => {
			// console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/GetMunchBakeryProducts/", "--api");

			dispatch({
				type: GET_CATEGORY_CAKES_SUCCESS,
				payload: response.data,
			});

			return response;
		},
		(error) => {
			const message =
				(error.response &&
					error.response.data &&
					error.response.data.message) ||
				error.message ||
				error.toString();
			dispatch({
				type: GET_CATEGORY_CAKES_FAIL,
				payload: message,
			});
			return Promise.reject();
		}
	);
};

export const GetStoresDistance = (lang, lat, lngId, date, custGuid, custtoken, custId, cateId) => async (dispatch) => {
	dispatch({
		type: GET_STORES_DISTANCE_START,
	});
	try {
		const response = await CategoryService.get_stores_distance(lang, lat, lngId, date, custGuid, custtoken, custId, cateId);
		// console.log(response?.config?.url, "--api");
		// console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/GetTimeSlotsMunchBakeryNew/", "--api");

		if (response?.data?.success) {
			dispatch({
				type: GET_STORES_DISTANCE_SUCCESS,
				payload: response.data,
			});
			return response
		} else {
			dispatch({
				type: GET_STORES_DISTANCE_FAIL,
				payload: response.data,
			});
			return response
		}
	} catch (error) {
		return Promise.resolve();
	}
};
