import axios from "axios";
import { API_URL } from "../global.js";

export const sendPaymentGateway = (data) => {

   return axios.post(`${API_URL}/SendPayment`, data);

}

export const InitiateSessionGateway = (data) => {

    return axios.post(`${API_URL}/InitiateSession`, data);

}

export const InitiatePaymentGateway = (data) => {

    return axios.post(`${API_URL}/InitiatePayment`, data);

}

export const GetPaymentStatusGateway = (data) => {

    return axios.post(`${API_URL}/GetPaymentStatus`, data);

}

export const ExecutePaymentGateway = (data) => {

    return axios.post(`${API_URL}/ExecutePayment`, data);

}

