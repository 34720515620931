import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLang } from '../../actions/lang';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LangSelector from '../LangSelector';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import { useNavigate, Link, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { logout } from "../../actions/auth";
import axios from 'axios';
import { MunchBakeryApiUrl } from '../../global';
import moment from 'moment';
// import 'moment/locale/ar';




const drawerWidth = 261;


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));


export default function PersistentDrawerRight() {

    const lang = useSelector((state) => state);
    const navigate = useNavigate();
    const location = useLocation();
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = React.useState(user);
    const custtoken = lang?.auth?.user?.cus_to || store1[0]?.cus_to;
    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;

    const langId = lang.Lang.data?.lang ? lang.Lang.data?.lang : "";
    const langText = lang.Lang.data?.side_bar ? lang.Lang.data.side_bar : '';
    const profileText = lang.Lang.data?.profile ? lang.Lang.data.profile : '';

    const langSelectorRef = (val, dispatch) => {
        dispatch(getLang(val));
    };
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);

    const [opened, setOpened] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';


    const [htmlDir, setHtmlDir] = useState('');
    const [getVal, setVal] = React.useState(false);
    const [getLatestOrder, setLatestOrder] = React.useState("");
    const [getTrue, setTrue] = React.useState(false);

    const [getLoader, setLoader] = React.useState(true);



    const handleDrawerOpen = () => {
        setOpen(true);
        setShow(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setShow(false);
    };


    useEffect(() => {
        setHtmlDir(document.documentElement.getAttribute('dir'));
    }, []);

    // useEffect(() => {
    //     if (location?.state?.category_id != 57) {
    //         if (location?.pathname == "/customizeThird") {
    //             setVal(true)
    //         }
    //     } else {
    //         if (location?.pathname == "/customizeNext") {
    //             setVal(true)
    //         }
    //     }
    //     if (location?.pathname == "/thankyouPage") {
    //         setVal(false)
    //     }

    // }, [location])







    window.onpopstate = (e) => {

        if (location?.state?.category_id == 57) {

            if (location?.pathname == "/customizeNext") {
                let state = location?.state;
                navigate("/customizeNext", { state: state, replace: false });
                return setOpened(true);
            }

        } else {

            if (location?.pathname == "/customizeThird") {
                let state = location?.state;
                navigate("/customizeThird", { state: state, replace: false });
                return setOpened(true);
            }
        }

        if (location?.pathname == "/thankyouPage") {
            return window.location.replace("/");
            return navigate("/", { replace: true });
        }
    }



    const handleButton = async (val) => {
        if (val == 0) {
            setOpened(false);
        } else {
            setOpened(false);
            navigate("/", { replace: true })
            setVal(false)
        }
    };

    const handleNavigate = () => {
        if (location?.state?.category_id == 57) {
            if (location?.pathname == "/customizeNext") {
                return setOpened(true);
            }
        } else {
            if (location?.pathname == "/customizeThird") {
                return setOpened(true);
            } else {
                return setOpened(false);
            }
        }
    }

    const Logout = () => {
        if (lang.auth.isLoggedIn) {
            logout();
        }
    }

    const getAllOrderList = async () => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.get(`${MunchBakeryApiUrl}/GetCustomerOrderMunchBakery/${custGuid}/`, header);

            if (response?.data?.length > 0) {
                let latestOrder = response?.data[0];
                await getLatestOrderList(latestOrder);
            } else {
                setTrue(true);
            }

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const getLatestOrderList = async (latestOrder = "") => {
        setLoader(true);

        let languageId = langId == 'ar' ? 0 : 1;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const response = await axios.get(`${MunchBakeryApiUrl}/GetOrderDetailMunchBakery/${custGuid}/${latestOrder?.OrderId}/${languageId}`, header);

            if (response?.data) {
                setLatestOrder(response?.data);
                setLoader(false);
                setTrue(false);
                // await encryptStringHandle(response?.data);
            }


        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }


    const encryptStringHandle = async (list) => {

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                encryptString: list?.OrderId
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/EncryptString/`, data, header);
            // console.log(`${MunchBakeryApiUrl}/EncryptString/`, '--api');
            if (response?.data?.EncryptStringResult) {

                await GetOrderDetailsForInvoice(response?.data?.EncryptStringResult);

            }

        } catch (error) {
            console.log("err", error);
        }

    }

    const GetOrderDetailsForInvoice = async (encryptData) => {
        let language = langId == 'ar' ? "0" : "1";


        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                langId: language,
                OrderId: encryptData != "" ? encryptData : ""
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetOrderDetailsForInvoice/`, data, header);


            if (response?.data?.GetOrderDetailsForInvoiceResult) {
                setLatestOrder(response?.data?.GetOrderDetailsForInvoiceResult);
                setLoader(false);
                setTrue(false);
            } else {
                setTrue(true);
            }


        } catch (error) {
            console.log("err", error);
        }

    }


    useEffect(() => {
        if (open) {
            if (lang.auth.isLoggedIn) {
                getAllOrderList();
                // changeOrderDateFormat();
            }
        }
    }, [open])

    // const changeOrderDateFormat = () => {
    //     let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
    //     let newOrderDate = moment(order_date).format('DD MMM YYYY');
    //     return newOrderDate;
    // }

    // const changeOrderDateFormat = () => {
    //     let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
    //     let new_date = langId == "en" ? moment(order_date).locale('en').format('DD MMM YYYY') : moment(order_date).locale('ar').format('DD MMM YYYY')
    //     return new_date;
    // }

    // const timeOrderHandler = () => {
    //     let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
    //     let new_date = moment(order_date).locale('en').format('DD MMM YYYY')
    //     return langId == "en" ? moment(`${new_date} ${getLatestOrder?.DeliveryTime}`).locale('en').format("hh:mm A") : moment(`${new_date} ${getLatestOrder?.DeliveryTime}`).locale('ar').format("hh:mm A");
    // }


    const changeOrderDateFormat = () => {
        let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
        let new_date = moment(order_date).format('DD MMM YYYY');

        let changeToAR = new Date(new_date);
        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).format(changeToAR);

        return langId == "en" ? new_date : In_arabic;
    }

    const timeOrderHandler = () => {
        let order_date = moment(getLatestOrder?.DeliveryDate, 'DD/MM/YYYY');
        let new_date = moment(order_date).format('DD MMM YYYY');

        let changeToAR = new Date(`${new_date} ${getLatestOrder?.DeliveryTime}`);

        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(changeToAR);

        return langId == "en" ? moment(`${new_date} ${getLatestOrder?.DeliveryTime}`).format("hh:mm A") : In_arabic;
    }


    useEffect(() => {
        const loginTime = localStorage.getItem('loginTime');
        if (loginTime) {
            const currentTime = new Date().getTime();
            const timeElapsed = currentTime - loginTime;

            // Calculate remaining time until 24 hours
              const remainingTime = 24 * 60 * 60 * 1000 - timeElapsed;
            // const remainingTime = 50 * 1000 - timeElapsed;


            if (remainingTime > 0) {
                // Set timeout for the remaining time
                const timeout = setTimeout(Logout, remainingTime);
                // Clear timeout on component unmount
                return () => clearTimeout(timeout);
            } else {
                // If 24 hours have already passed
                Logout();
            }
        }
    }, [location]);

    return (
        <Box className="header_main">
            <CssBaseline />
            <AppBar position="static" className='header_appbar'>
                <Toolbar>
                    {location.pathname !== "/" && location.pathname !== "/thankyouPage" ? (
                        <IconButton
                            className={'page_back_btn_' + htmlDir}
                            aria-label="back button"
                            onClick={() => getVal ? handleNavigate() : navigate(-1)}
                        >

                            <img src='images/left_back_arrow.svg' alt='Left Arrow' />
                        </IconButton>
                    )
                        :
                        (
                            <IconButton
                                // className={'page_back_btn_' + htmlDir}
                                aria-label="back button"
                            >
                            </IconButton>
                        )
                    }
                    <Box component="a" className="main_logo">
                        <img src="images/Logo.svg" className='logo' />
                    </Box>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        className='nav_icon'
                        onClick={handleDrawerOpen}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box className={`drawer_dropshadow ${show && "sidebar_active"}`}>
                <Button onClick={handleDrawerClose}></Button>
            </Box>
            <Drawer
                sx={{
                    width: drawerWidth,
                    overflow: 'hidden',
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        overflow: 'visible',
                        height: '100%',
                        left: 'auto',
                    },
                }}
                variant="persistent"
                anchor="right"
                open={open}
                className='menu_drawer'
            >
                <DrawerHeader className='menu_drawer_header'>
                    {/*<IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>*/}
                    <Box component='div' className='menu_logo_lan'>
                        <Box component="a" className="menu_main_logo">
                            <img src="images/Logo.svg" />
                        </Box>
                        <Box component='div' className='lang_selector'>
                            <LangSelector langChange={langSelectorRef} />
                        </Box>
                    </Box>
                </DrawerHeader>
                <Box component='div' className='menu_scrollbar'>
                    <List className='menu_list'
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    // subheader={
                    //     <ListSubheader component="div" id="nested-list-subheader">
                    //         Hello Bashher
                    //     </ListSubheader>
                    // }
                    >
                        {lang.auth.isLoggedIn ?
                            <ListItem disablePadding>
                                <Link to='/profile' onClick={handleDrawerClose} className='text-decoration'>
                                    <ListItemButton disableTouchRipple className='profile_menu'>
                                        <ListItemText primary={langText?.my_profile} />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                            : ""}
                        {/* <ListItem disablePadding>
                                    <Link to={'/myOrders'} onClick={handleDrawerClose} className='text-decoration'>
                                        <ListItemButton disableTouchRipple className='order_menu'>
                                            <ListItemText primary={langText?.my_latest_order} />
                                        </ListItemButton>
                                    </Link>
                                </ListItem>
                        <ListItem disablePadding>
                            <Link to={'/myOrderDetail'} onClick={handleDrawerClose} className='text-decoration'>
                                <Box component='div' className='menu_order_confirm'>
                                    <Box component='div'>
                                        <Typography component='p' variant='p'>
                                            {langText?.accept_order}
                                        </Typography>
                                        <Typography component='p' variant='p'>
                                            {getLatestOrder?.OrderIdShowCustomer ? `#${1234566545}` : ""}
                                        </Typography>
                                        <Box component='div' className='inner_h5'>
                                            <Typography component='h5' variant='h5'>
                                                Pickup @ Street name, Hamra
                                            </Typography>
                                            <Typography component='h5' variant='h5'>
                                                At 22 Apr 2023 - 3:40 PM
                                            </Typography>
                                        </Box>
                                        <Typography component='p' variant='p'>
                                            {langText?.questions}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Link>

                        </ListItem> */}
                        {lang.auth.isLoggedIn ?
                            <>
                                <ListItem disablePadding>
                                    {/* <Link to={'/myOrders'} onClick={handleDrawerClose} className='text-decoration'> */}
                                    <ListItemButton disableTouchRipple className='order_menu'>
                                        <ListItemText primary={langText?.my_latest_order} />
                                    </ListItemButton>
                                    {/* </Link> */}
                                </ListItem>
                                {getTrue ?
                                    <ListItem disablePadding>
                                        <Box component='div' className='menu_order_confirm'>
                                            <Box component='div'>
                                                <Box component='div' className='inner_h5'>
                                                    <Typography component='h5' variant='h5'>
                                                        {langId == 'en' ? 'No order has been placed yet.' : 'لم يتم وضع أي أمر حتى الآن'}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </ListItem>
                                    :
                                    <>
                                        {
                                            !getLoader && getLatestOrder ?
                                                <ListItem disablePadding>
                                                    <Link to={'/myOrderDetail'} onClick={handleDrawerClose} className='text-decoration'>
                                                        <Box component='div' className='menu_order_confirm'>
                                                            <Box component='div'>
                                                                <Typography component='p' variant='p'>
                                                                    {langText?.order_number}
                                                                </Typography>
                                                                <Typography component='p' variant='p'>
                                                                    {getLatestOrder?.OrderGuid ? `#${getLatestOrder?.OrderGuid}` : ""}
                                                                </Typography>
                                                                <Box component='div' className='inner_h5'>
                                                                    <Typography component='h5' variant='h5'>
                                                                        {langId == "en" ? `Pickup @ ${getLatestOrder?.StoreNameEn}` : `استلام من ${getLatestOrder?.StoreNameAr}`}
                                                                    </Typography>
                                                                    <Typography component='h5' variant='h5'>
                                                                        {langId == "en" ? 'At' : 'في'} {changeOrderDateFormat()} - {timeOrderHandler()}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography component='p' variant='p'>
                                                                    {langText?.questions}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Link>

                                                </ListItem>
                                                :
                                                <ListItem disablePadding>
                                                    <Box component='div' className='menu_order_confirm'>
                                                        <Box component='div'>
                                                            <Typography component='p' variant='p'>
                                                                <Skeleton animation="wave" width={120} />
                                                            </Typography>
                                                            <Typography component='p' variant='p'>
                                                                <Skeleton animation="wave" width={80} />
                                                            </Typography>
                                                            <Box component='div' className='inner_h5'>
                                                                <Typography component='h5' variant='h5'>
                                                                    <Skeleton animation="wave" width={90} />
                                                                </Typography>
                                                                <Typography component='h5' variant='h5'>
                                                                    <Skeleton animation="wave" width={160} />
                                                                </Typography>
                                                            </Box>
                                                            <Typography component='p' variant='p'>
                                                                <Skeleton animation="wave" width={160} height={30} />
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                        }
                                    </>

                                }

                            </>
                            : ""
                        }

                        <ListItem disablePadding>
                            <Link to='/' onClick={handleDrawerClose} className='text-decoration'>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.home} />
                                </ListItemButton>
                            </Link>
                        </ListItem>
                        <a href='https://order.munchbakery.com/' style={{ textDecoration: "none" }} target="_blank">
                            <ListItem disablePadding>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.munch_product} />
                                </ListItemButton>
                            </ListItem>
                        </a>
                        {/* <ListItem disablePadding>
                            <ListItemButton disableTouchRipple>
                                <ListItemText primary={langText?.ocassion_cakes} />
                            </ListItemButton>
                        </ListItem> */}
                        <a href='https://www.b2b.munchbakery.com/' style={{ textDecoration: "none" }} target="_blank">
                            <ListItem disablePadding>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.b2b} />
                                </ListItemButton>
                            </ListItem>
                        </a>
                        <a href='https://admin.munchbakery.com/docs/CorporateProfile.pdf' style={{ textDecoration: "none" }} target="_blank">
                            <ListItem disablePadding>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.about_us} />
                                </ListItemButton>
                            </ListItem>
                        </a>

                        {/* <Link to='/about-us' onClick={handleDrawerClose} className='text-decoration'>
                            <ListItem disablePadding>
                                <ListItemButton disableTouchRipple>
                                <a href='https://admin.munchbakery.com/docs/CorporateProfile.pdf' style={{textDecoration: "none" }} target="_blank"> <ListItemText primary={langText?.about_us} /></a>
                                </ListItemButton>
                            </ListItem>
                        </Link> */}
                        {/* <ListItem disablePadding>
                            <ListItemButton disableTouchRipple>
                                <ListItemText primary={langText?.munch_blog} />
                            </ListItemButton>
                        </ListItem> */}
                        <a href='https://www.google.com/maps/d/u/0/embed?mid=1-B8Jm7Ocs16dvjmSPOxTIFriyC70bCA&ehbc=2E312F&ll=22.62458079833854%2C43.4019252607879&z=6' style={{ textDecoration: "none" }} target="_blank">
                            <ListItem disablePadding>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.our_location} />
                                </ListItemButton>
                            </ListItem>
                        </a>
                        {/* <ListItem disablePadding>
                            <Link to='/our-location' onClick={handleDrawerClose} className='text-decoration'>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.our_location} />
                                </ListItemButton>
                            </Link>
                        </ListItem> */}
                        <a href='https://api.whatsapp.com/send/?phone=966920015010&text=customcakechat' style={{ textDecoration: "none" }} target="_blank">

                            <ListItem disablePadding>
                                <ListItemButton disableTouchRipple>
                                    <ListItemText primary={langText?.contact_us} />
                                </ListItemButton>
                            </ListItem>
                        </a>
                        {lang.auth.isLoggedIn ?
                            <Link to='#' onClick={() => Logout()} className='text-decoration'>
                                <ListItem disablePadding>
                                    <ListItemButton disableTouchRipple>
                                        <ListItemText primary={profileText?.logout} />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                            : ""
                        }
                    </List>
                    <Box component='div' className='menu_footer'>
                        <Box component='div' className='policy_pages'>
                            <List className='footer_list_nav'>

                                <ListItem disablePadding>
                                    <a href={langId == "en" ? 'https://www.munchbakery.com/privacy-policy-en' : 'https://www.munchbakery.com/privacy-policy-ar'} style={{ textDecoration: "none" }} target="_blank">
                                        <ListItemButton disableTouchRipple>
                                            <ListItemText primary={langText?.privacy_policy} />
                                        </ListItemButton>
                                    </a>
                                </ListItem>

                                <ListItem disablePadding>
                                    <a href={langId == "en" ? 'https://www.munchbakery.com/terms-and-conditions-en' : 'https://www.munchbakery.com/terms-and-conditions-ar'} style={{ textDecoration: "none" }} target="_blank">
                                        <ListItemButton disableTouchRipple>
                                            <ListItemText primary={langText?.term_condition} />
                                        </ListItemButton>
                                    </a>
                                </ListItem>
                            </List>
                            <Typography component='p' variant='p' className='copywrite_text'>munchbakery.com Ⓒ  2023</Typography>
                        </Box>
                    </Box>
                </Box>
            </Drawer>

            {/* <Button  variant="outlined" onClick={handleClickOpen}>
                    Open alert dialog
                </Button> */}
            <Dialog
                open={opened}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={() => handleButton("0")}
                aria-labelledby="responsive-dialog-title"
                aria-describedby="responsive-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title">
                    {flavorText?.progress}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="responsive-dialog-description">
                        {/* You will loose your progress, are you sure to move back ? */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleButton("0")}>{flavorText?.cancel}</Button>
                    <Button onClick={() => handleButton("1")} autoFocus>
                        {flavorText?.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
