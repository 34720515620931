import axios from 'axios';
import { API_URL } from '../global';

// Define your action types
export const LANG_GET = 'LANG_GET';
export const LANG_FAILED = 'LANG_FAILED';
export const LANG_SUCCESS = 'LANG_SUCCESS';

// Define your API endpoint
// const API_URL = 'https://development.brstdev.com:5020/';

// Define your action creator
export const getLang = (lang) => async (dispatch) => {
  dispatch({ type: LANG_GET });

  try {
    const response = await axios.get(API_URL + '/language', { params: { lang } });

    if (response.data.translate) {
      localStorage.setItem('lang', response.data.lang);
      const groupedTranslations = {};

      response.data.translate.map((translation) => {
        const { page_name, value, key, language } = translation;
        if (!groupedTranslations[page_name]) {
          groupedTranslations[page_name] = {};
        }
        if (!groupedTranslations[page_name][key]) {
          groupedTranslations[page_name][key] = value;
        }

        if (!groupedTranslations["lang"]) {
          groupedTranslations["lang"] = language;
        }
      });

      dispatch({ type: LANG_SUCCESS, payload: groupedTranslations });
    }
  } catch (error) {
    dispatch({ type: LANG_FAILED });
  }
};
