import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { Typography, Skeleton } from '@mui/material';
import CheckoutPayDrawer from '../../components/CheckoutPayDrawer';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

export default function CheckoutDrawer(props) {
    const {
        others,
        cupCake,
        getFlavourMax,
        getCartDetail,
        custtoken,
        setState,
        state,
        getText,
        setText,
        productDetail,
        ShoppingDetails,
        setShoppingDetails,
        getProductId,
        CategoryId,
        IsClick,
        getThisValue,
        getTrueState
    } = props;

    const navigate = useNavigate();
    const location = useLocation();
    const lang = useSelector((state) => state);
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';
    const langId = lang.Lang.data?.lang ? lang.Lang.data?.lang : "";

    const [GetCategoryId, setCategoryId] = React.useState(localStorage.getItem("categoryId") ? JSON.parse(localStorage.getItem("categoryId"))?.Id : "");

    const OrderValue = GetCategoryId == 57 ? parseFloat(cupCake?.cupCakeAmount) : parseFloat(others?.cakeAmount);
    const [getProductData, setProductData] = React.useState([]);
    const [getPaidCharacter, setCharacter] = React.useState([]);
    const [getFreeCharacter, setFreeCharacter] = React.useState([]);

    const [getStryoBaseLargeId, setStryoBaseLargeId] = React.useState([]);
    const [getStryoBaseSmallId, setStryoBaseSmallId] = React.useState([]);
    const buttonRef = useRef(null);




    useEffect(() => {

        let session_init = localStorage.getItem("start_session");

        return () => {
            // console.log('Component unmounted');
            if (session_init && session_init == "true") {
                document.getElementById('popup-ID')?.click();
            }
        };
    },[])

    useEffect(() => {


        if (ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0) {

            let product = ShoppingDetails?.LstECommerceShoppingCartItem?.filter((v, i) => v?.ProductId == (productDetail?.prodId ? productDetail?.prodId : 1736));
            setProductData(product[0]);
            let product_character = ShoppingDetails?.LstECommerceShoppingCartItem?.filter((v, i) => v?.ProductId == others?.ProductId);
            setCharacter(product_character[0])
            let free_character = ShoppingDetails?.LstECommerceShoppingCartItem?.filter((v, i) => v?.ProductId == others?.freeCharacterId);
            setFreeCharacter(free_character[0])
            let StryoBaseLargeId = ShoppingDetails?.LstECommerceShoppingCartItem?.filter((v, i) => v?.ProductId == cupCake?.StryoBaseLargeId);
            setStryoBaseLargeId(StryoBaseLargeId[0])
            let StryoBaseSmallId = ShoppingDetails?.LstECommerceShoppingCartItem?.filter((v, i) => v?.ProductId == cupCake?.StryoBaseSmallId);
            setStryoBaseSmallId(StryoBaseSmallId[0])
        }

    }, [ShoppingDetails?.LstECommerceShoppingCartItem])



    useEffect(() => {
        if (state.bottom && buttonRef.current) {
            buttonRef.current.click(); // Trigger click event on the button
        }
    }, [state.bottom]);

    const mouseDown = (event) => {
        let startY = 0;
        let isDragging = false;

        const handleSliderDrag = (event) => {

            if (isDragging && event.clientY > startY) {
                // Dragging from top to bottom
                setState((prevState) => ({
                    ...prevState,
                    bottom: false,
                }));
            }
        };

        const handleSliderTouchMove = (event) => {
            if (event.touches != undefined) {
                if (isDragging && event.touches[0]?.clientY > startY) {
                    // Sliding from top to bottom
                    setState((prevState) => ({
                        ...prevState,
                        bottom: false,
                    }));
                }
            }

        };


        const handleSliderTouchStart = (event) => {

            if (event.touches != undefined) {
                startY = event.touches[0]?.clientY;
            } else {
                startY = 0;
            }

            isDragging = true;
        };

        const handleSliderTouchEnd = () => {
            isDragging = false;
        };

        document.addEventListener('mousemove', handleSliderDrag);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', handleSliderDrag);
        });

        document.addEventListener('touchmove', handleSliderTouchMove, { passive: false });
        document.addEventListener('touchend', handleSliderTouchEnd);

        handleSliderTouchStart(event);
    };


    const handleSliderTouchStart = (event) => {
        setState((prevState) => ({
            ...prevState,
            bottom: false,
        }));
        let startY;
        let isDragging;
        if (event.touches != undefined) {
            startY = event.touches[0]?.clientY || 0;
            isDragging = true;
        }

    };


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }


        setState({ ...state, [anchor]: open });

    };

    const characterPrice = () => {
        if (GetCategoryId == 57) {
            return parseFloat(cupCake?.styroBaseBig * 50) + parseFloat(cupCake?.styroBaseSmall * 30)
        } else if (GetCategoryId == 81) {
            return parseFloat(others?.characterAmount)
        } else {
            return 0
        }
    }

    const TotalAmountWithVat = () => {
        return ((characterPrice() + OrderValue) + (15 / 100) * (characterPrice() + OrderValue))?.toFixed(2);
    }

    const categoryNameHandle = () => {

        if (GetCategoryId == 81) {
            return flavorText?.ocassion_layer;
        } else if (GetCategoryId == 20) {
            return flavorText?.photo_occasions;
        } else if (GetCategoryId == 21) {
            return flavorText?.frosting_cakes;
        } else if (GetCategoryId == 57) {
            return flavorText?.decorated_cupcakes;
        } else {
            return "";
        }

    }

    const setLayerValue = () => {
        return `(${others?.layer} ${flavorText?.layer})`
    }

    const ValCal = () => {
        let val = ((15 / 100) * (ShoppingDetails?.OrderTotal ? parseFloat(ShoppingDetails?.OrderTotal) : 0))?.toFixed(2)
        return parseFloat(val);

    }


    const setTermCondition = (e) => {
        e.preventDefault();
        window.open(e.target.href);
    }

    const setPrivacyPolicy = (e) => {
        e.preventDefault();
        window.open(e.target.href);
    }


    const list = (anchor) => (
        <Box
            className="checkout_drawer"
            role="presentation"
            component="div"
            style={ langId == "en" ? { direction: 'ltr' } : { direction: 'rtl' }}
            onClick={() => CategoryId == 57 ? IsClick() : ""}

        >
            <Box component='div' className='checkout_drawer_content' >
                <Box onMouseDown={mouseDown} onTouchStart={handleSliderTouchStart} ></Box>
                <Box component='div' className='content_main' id='close_drawer'>
                    <Typography component='h1' variant='h1' className='head_title' onMouseDown={mouseDown} onTouchStart={handleSliderTouchStart}>{flavorText?.review_your_order}</Typography>
                    <Box component='div' className='inner_box' >

                        <Box component='div' className='headers'>
                            {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                                <>
                                    <Typography component='h2' variant='h2'>{getProductData?.ProductName}</Typography>
                                    <Typography component='h2' variant='h2'>{getProductData?.Price ? `${flavorText?.currency} ${getProductData?.Price * getProductData?.Quantity}` : ""}</Typography>
                                </>
                                :
                                <>
                                    <Skeleton animation="wave" width={250} />
                                    <Skeleton animation="wave" width={50} />
                                </>
                            }
                        </Box>
                        <Box component='div' className='details'>
                            {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                                <>
                                    {getFlavourMax && getFlavourMax[0]?.Name && <Typography component='p' variant='p'>({flavorText?.flavor_name} 1) {getFlavourMax[0]?.Name}</Typography>}
                                    {getFlavourMax && getFlavourMax[1]?.Name && <Typography component='p' variant='p'>({flavorText?.flavor_name} 2) {getFlavourMax[1]?.Name}</Typography>}
                                </>
                                :
                                <>
                                    <Skeleton animation="wave" width={100} />
                                    <Skeleton animation="wave" width={100} />
                                </>
                            }
                        </Box>
                        <Box component='div' className='totals'>
                            {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                                <>
                                    {GetCategoryId == 57 && <Typography component='h2' variant='h2'>{getStryoBaseLargeId?.Quantity > 0 ? `${getStryoBaseLargeId?.Quantity} ${getStryoBaseLargeId?.ProductName}` : ""}{getStryoBaseLargeId?.Quantity && getStryoBaseSmallId?.Quantity ? "," : ""} {getStryoBaseSmallId?.Quantity > 0 ? `${getStryoBaseSmallId?.Quantity} ${getStryoBaseSmallId?.ProductName}` : ""}</Typography>}
                                    {GetCategoryId == 57 && (getStryoBaseLargeId?.Price && getStryoBaseSmallId?.Price) && <Typography component='h2' variant='h2'> {`${flavorText?.currency} ${(parseFloat(getStryoBaseLargeId?.Quantity) * parseFloat(getStryoBaseLargeId?.Price)) + (parseFloat(getStryoBaseSmallId?.Quantity) * parseFloat(getStryoBaseSmallId?.Price))}`}</Typography>}
                                    {GetCategoryId == 57 && (getStryoBaseLargeId?.Price && !getStryoBaseSmallId?.Price) && <Typography component='h2' variant='h2'> {`${flavorText?.currency} ${(parseFloat(getStryoBaseLargeId?.Quantity) * parseFloat(getStryoBaseLargeId?.Price))}`} </Typography>}
                                    {GetCategoryId == 57 && (getStryoBaseSmallId?.Price && !getStryoBaseLargeId?.Price) && <Typography component='h2' variant='h2'> {`${flavorText?.currency} ${(parseFloat(getStryoBaseSmallId?.Quantity) * parseFloat(getStryoBaseSmallId?.Price))}`}</Typography>}

                                    {GetCategoryId == 81 && <Typography component='h2' variant='h2'>{getPaidCharacter?.Quantity > 0 ? `${getPaidCharacter?.Quantity} ${getPaidCharacter?.ProductName ? flavorText?.paid_char : ''}` : ""} <br /> {getFreeCharacter?.Quantity > 0 ? `${getFreeCharacter?.Quantity} ${getFreeCharacter?.ProductName ? flavorText?.free_char : ""}` : ""} </Typography>}
                                    {GetCategoryId == 81 && <Typography component='h2' variant='h2'>{getPaidCharacter?.Quantity > 0 ? `${flavorText?.currency} ${parseFloat(getPaidCharacter?.Quantity) * parseFloat(getPaidCharacter?.Price)}` : ""} <br /> {getFreeCharacter?.Quantity > 0 ? `${flavorText?.currency} ${getFreeCharacter?.Price}` : ""}</Typography>}
                                </>
                                :
                                <>
                                    <Skeleton animation="wave" width={150} />
                                    <Skeleton animation="wave" width={50} />
                                </>

                            }
                        </Box>
                    </Box>
                    <Box component='div' className='inner_box'>
                        <Box component='div' className='totals_main'>
                            {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                                <>
                                    <Typography component='h2' variant='h2'>{flavorText?.total_amount}</Typography>
                                    <Typography component='h2' variant='h2'>{flavorText?.currency} {ShoppingDetails?.OrderTotal ? (ShoppingDetails?.OrderTotal - ValCal())?.toFixed(2) : 0}</Typography>
                                </>
                                :
                                <>
                                    <Skeleton animation="wave" width={170} />
                                    <Skeleton animation="wave" width={50} />
                                </>
                            }

                        </Box>
                        <Box component='div' className='totals_vat'>
                            {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                                <>
                                    <Typography component='h2' variant='h2'>{flavorText?.vat} 15%</Typography>
                                    <Typography component='h2' variant='h2'>{flavorText?.currency} {ValCal()}</Typography>
                                </>
                                :
                                <>
                                    <Skeleton animation="wave" width={50} />
                                    <Skeleton animation="wave" width={60} />
                                </>
                            }

                        </Box>
                        <Box component='div' className='vat_details'>
                            {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                                <>
                                    <Typography component='span' variant='span'>{flavorText?.registration_no}</Typography>
                                    <Typography component='span' variant='span'>{getCartDetail?.val?.VAT ? getCartDetail?.val?.VAT : "300091893700003" }</Typography>
                                </>
                                :
                                <>
                                    <Skeleton animation="wave" width={120} />
                                    <Skeleton animation="wave" width={80} />
                                </>
                            }
                        </Box>
                    </Box>
                    {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                        <Box component='div' className='inner_box_contain'>
                            {langId == 'en' ?
                                <Typography component="p" variant="p">
                                    By clicking Confirm you accept the <a onClick={(e) => setTermCondition(e)} href='https://www.munchbakery.com/terms-and-conditions-en' target="_blank">Terms and Conditions</a> and <a onClick={(e) => setPrivacyPolicy(e)} href='https://www.munchbakery.com/privacy-policy-en' target="_blank" >Privacy Policy</a>.
                                </Typography>
                                :
                                <Typography component="p" variant="p">
                                    بالنقر على تأكيد ، فإنك توافق على <a onClick={(e) => setTermCondition(e)} href='https://www.munchbakery.com/terms-and-conditions-ar' target="_blank"> الشروط والأحكام</a> و <a onClick={(e) => setPrivacyPolicy(e)} href='https://www.munchbakery.com/privacy-policy-ar' target="_blank"> سياسة الخصوصية </a>
                                </Typography>
                            }
                        </Box>
                        :
                        <Box component='div' className='inner_box_contain'>
                            <Skeleton animation="wave" />
                        </Box>
                    }
                </Box>
                <Box component='div' className='step_button_box Confirm_pay'>
                    {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                        <Box component='div' className='text'>
                            <Typography component='h4' variant='h4'>{flavorText?.currency} {ShoppingDetails?.OrderTotal ? ShoppingDetails?.OrderTotal : 0}</Typography>
                            <Typography component='p' variant='p'>{flavorText?.vat_included}</Typography>
                        </Box>
                        :
                        <Box component='div' className='text'>
                            <Typography component='h4' variant='h4'><Skeleton animation="wave" width={80} /></Typography>
                            <Typography component='p' variant='p'><Skeleton animation="wave" width={60} height={17}/></Typography>
                        </Box>
                    }

                    <Box component='div' className='btn' >
                        <CheckoutPayDrawer totalPrice={TotalAmountWithVat} getCartDetail={getCartDetail} custtoken={custtoken} characterPrice={characterPrice} OrderValue={OrderValue} GetCategoryId={GetCategoryId} categoryNameHandle={categoryNameHandle} setLayerValue={setLayerValue} getFlavourMax={getFlavourMax} getText={getText} setText={setText} setShoppingDetails={setShoppingDetails} ShoppingDetails={ShoppingDetails} getProductData={getProductData} getPaidCharacter={getPaidCharacter} ValCal={ValCal} getFreeCharacter={getFreeCharacter} getStryoBaseLargeId={getStryoBaseLargeId} getStryoBaseSmallId={getStryoBaseSmallId} getTrueState={getTrueState}/>
                    </Box>
                </Box>
            </Box>
            <Box
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
            </Box>
        </Box>
    );

    return (
        <React.Fragment>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button ref={buttonRef} className="upload_btn" id='popup-ID' onClick={toggleDrawer(anchor, true)} aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                        {flavorText?.next_btn}
                    </Button>
                    <Drawer
                        className="drawer_main_checkout"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}

                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
