import React, { useEffect, useState } from "react";
import Routers from "./Routers";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Header from './components/Header';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getLang } from './actions/lang';
import { TrackGoogleAnalyticsPageView } from "./utils/google-analytics/events";
import CryptoJS from "crypto-js";







export default function App() {
    const store = useSelector((state) => state);
    const navigate = useNavigate();
    const location = useLocation();
    const isLoading = store.Lang.loading;
    const dispatch = useDispatch();
    const getCategoryId = localStorage?.getItem("categoryId") ? JSON.parse(localStorage?.getItem("categoryId"))?.Id : "";
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const stores = useState(user);




    // window.dataLayer.push({
    //     event: 'pageview',
    //     page: {
    //       url: document.location.origin + document.location.pathname + document.location.search,
    //       title: "no page"
    //     }
    // });


    useEffect(() => {

        // Checking if user is not loggedIn
        if (location?.pathname !== "/" && !store?.auth?.isLoggedIn) {
            navigate("/");
        }

        console.log("App rendering ----");


        if (location) {


            let loc = location.pathname + location.search;

            if (loc === "/") {

                TrackGoogleAnalyticsPageView("pageview",  (location.pathname + location.search), "main page");

            } else if (loc === "/location") {

                TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "branches");

            } else if (loc === "/gallery") {

                if (getCategoryId == 81) {
                    TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "products/3D");
                } else if (getCategoryId == 20) {
                    TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "products/Photo occasion");
                } else if (getCategoryId == 21) {
                    TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "products/frosting");
                } else if (getCategoryId == 57) {
                    TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "products/decoration cupcake");
                }

            } else if (loc === "/customizeNext") {
                if (getCategoryId == 81) {
                    TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "size of cake");
                }
            } else if (loc === "/customizeThird") {
                if (getCategoryId != 57) {
                    TrackGoogleAnalyticsPageView("pageview", (location.pathname + location.search), "flavors");
                }
            }
        }




    }, [location]);

    // return <Routers />;



    var langSymbol = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar';

    useEffect(() => {
        dispatch(getLang(langSymbol));

        const changeLanguageDirection = (langSymbol) => {
            if (langSymbol == "ar") {
                document.body.classList.remove('english');
                document.body.classList.add('arabic');
            } else {
                document.body.classList.add('english');
                document.body.classList.remove('arabic');
            }
            document.documentElement.dir = langSymbol === 'ar' ? 'rtl' : 'ltr';
        };


        const selectedLanguage = langSymbol;
        changeLanguageDirection(selectedLanguage);

    }, [dispatch]);


    return (
        <React.Fragment>
            <CssBaseline />
            <Container className='main_container'>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <React.Fragment>
                        <Header />
                        <Routers />
                    </React.Fragment>
                )}
            </Container>
        </React.Fragment>
    );


}
