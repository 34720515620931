import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const style = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.1)',
};

export default function CakeDesignModal(props) {
    const { lang, picturePath, prodId, Price } = props;
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const cakeDesign = (e, Id, picturePath) => {

        let path = '/customize';
        let state = { prodId: Id, picturePath, Price };
        localStorage.setItem("ProductDetails", JSON.stringify(state))
        navigate(path);
        dispatch({ type: "GET_IMAGE", payload: null })
        localStorage.removeItem("image_path");
        localStorage.removeItem("customize");
        localStorage.setItem("image_path", JSON.stringify([picturePath]))
    }



    return (
        <React.Fragment>
            <Button onClick={handleOpen} className='cake_modal_btn'></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='cake_modal_main'
            >
                <Box sx={style} className='cake_modal_box'>
                    <Button onClick={handleClose} className='cross_btn'><CloseIcon /></Button>
                    <img src={picturePath?.PicturePath} alt="cake_img" />
                    <Button className='submit_btn' onClick={(e) => cakeDesign(e, prodId, picturePath)}>{lang?.popup_btn}</Button>
                </Box>
            </Modal>
        </React.Fragment>
    );
}
