import {
	ORDER_DETAILS,
	ORDER_DESTROY,
} from "./../constants/orderAction";

export const OrderDetailsInsert = (Details) => (dispatch) => {
    localStorage.setItem("orderDetails", JSON.stringify(Details))
    dispatch({
        type: ORDER_DETAILS,
        payload: Details
    });

}

export const OrderDestroy = () => (dispatch) => {
    dispatch({
        type: ORDER_DESTROY,
    });
}