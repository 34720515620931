import {
    SEARCH_PRODUCT_START,
    SEARCH_PRODUCT_SUCCESS,
    SEARCH_PRODUCT_FAIL,
} from "./../constants/productAction";

import ProductService from "../services/product.service";

export const ProductSearch = (search_input, lang) => async (dispatch) => {
    dispatch({
        type: SEARCH_PRODUCT_START,
    });

    const response = await ProductService.search_product(search_input, lang);
    console.log("https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/GetMunchCakeProducts/", "--api");

    if (response?.data?.success) {
        dispatch({
            type: SEARCH_PRODUCT_SUCCESS,
            payload: response.data?.data?.SearchProductsMunchBakeryResult,
        });
        return response;
    } else {
        dispatch({
            type: SEARCH_PRODUCT_FAIL,
            payload: response.data,
        });
        return response;
    }
};