import React, { useEffect } from 'react'
import { Box, Button, Typography, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import CheckoutDrawer from '../../components/CheckoutDrawer'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { MunchBakeryApiUrl } from '../../global';
import CryptoJS from "crypto-js";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { TrackGoogleTagManagerPageView } from '../../utils/google-tag-manager/events';




export default function CustomizeThird() {
    const lang = useSelector((state) => state);
    const location = useLocation();
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';
    const langId = lang?.Lang?.data?.lang ? lang?.Lang?.data?.lang : '';
    const [getCustomize, setCustomize] = React.useState(localStorage.getItem("customize") ? JSON.parse(localStorage.getItem("customize")) : "");
    const [getImagePath, setImagePath] = React.useState(localStorage.getItem("image_path") ? JSON.parse(localStorage.getItem("image_path")) : "");
    const [GetCategoryId, setCategoryId] = React.useState(localStorage.getItem("categoryId") ? JSON.parse(localStorage.getItem("categoryId"))?.Id : "");
    const [getCartDetail, setCartDetail] = React.useState(localStorage.getItem("cart_details") ? JSON.parse(localStorage.getItem("cart_details")) : "");
    const [productDetail, setProductDetail] = React.useState(localStorage.getItem("ProductDetails") ? JSON.parse(localStorage.getItem("ProductDetails")) : "");

    const [ShoppingDetails, setShoppingDetails] = React.useState([]);
    const [ShopDetail, setShopDetail] = React.useState([]);
    const [getProductId, setProductId] = React.useState("");

    const [opened, setOpened] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [getCustomText, setCustomText] = React.useState('');

    const [getOutofStock, setOutofStock] = React.useState(false);


    const [getTrueState, setTrueState] = React.useState(false);



    const [getText, setText] = React.useState("");
    const [getCakeFlavour, setCakeFlavour] = React.useState([])
    const [getFlavourMax, setFlavourMax] = React.useState([]);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = React.useState(user);
    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const custtoken = lang?.auth?.user?.cus_to || store1[0]?.cus_to;
    const custId = store1[0]?.cus_id ? store1[0]?.cus_id : ""
    const cupCake = location?.state?.cup_cake;
    const others = location?.state?.others;


    // useEffect(() => {
    //     if (lang.Lang.data?.lang) {
    //         if (ShoppingDetails) {
    //             if (GetCategoryId != 57) {
    //                 if ((ShoppingDetails?.LstECommerceShoppingCartItem)?.length > 0) {
    //                     let shopItemsArray = ShoppingDetails?.LstECommerceShoppingCartItem;
    //                     let outOfStockItem = shopItemsArray?.filter((v) => v?.InStock)
    //                     if (outOfStockItem?.length > 0) {
    //                         setState({ ...state, ["bottom"]: false });
    //                         toast.error("Some Items out of stock", { position: toast.POSITION.TOP_LEFT });
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }, [ShoppingDetails])


    useEffect(() => {
        if (lang.Lang.data?.lang) {
            getFlavour();

            if (lang?.Lang?.data?.lang) {
                if(GetCategoryId == 81) {
                    TrackGoogleTagManagerPageView('flavors', document.location.origin + document.location.pathname + document.location.search);
                }
            }

            let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";

            if (transaction_err?.err) {
                window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                window.location.replace("/");
            }
            let get_failed_state = localStorage.getItem("push_state") ? localStorage.getItem("push_state") : "";
            if (!get_failed_state) {
                window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                window.location.replace("/");
            }

            let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";
            if (get_success_state) {
                window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                window.location.replace("/");
            }
        }
    }, [])




    const getFlavour = async () => {
        let lngId = lang?.Lang?.data?.lang
        let langId = lngId == 'ar' ? 0 : 1;

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            let data = {
                CustomerGuid: custGuid,
                langId: langId
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetCakeFlavorsMunchCake/`, data, header);
            if (response?.data?.GetCakeFlavorsMunchCakeResult.length > 0) {
                setCakeFlavour(response?.data?.GetCakeFlavorsMunchCakeResult)
            }
        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }

    }

    const personCount = () => {
        if (GetCategoryId == 81) {
            return others?.persons;
        } else if (GetCategoryId == 20) {
            return others?.photoOcassionPersons;
        } else {
            return others?.frostingPersons;
        }
    }



    const addCart = async () => {
        setOutofStock(false);
        setTrueState(false);

        if (GetCategoryId != 57) {

            const data = {
                ProductId: productDetail?.prodId ? productDetail?.prodId?.toString() : "-1",
                LanguageId: 1,
                CustomerGuid: custGuid,
                IsAddToCart: 1,
                NumberOfPersons: personCount(),
                NumberOfLayers: others?.layer ? others?.layer : 1,
                // lstLayersInfo: [
                //     {
                //         SecondHalfFlavorId: getFlavourMax?.length > 0 ? getFlavourMax[1]?.Id : 0,
                //         FirstHalfFlavorId: getFlavourMax?.length > 0 ? getFlavourMax[0]?.Id : 0,
                //         LayerNumber: 1
                //     }
                // ],
                // LstPhotos: [
                //     {
                //         "PicturePath": getImagePath[0] ? getImagePath[0] : ""
                //     },
                //     {
                //         "PicturePath": getImagePath[1] ? getImagePath[1] : ""
                //     },
                //     {
                //         "PicturePath": getImagePath[2] ? getImagePath[2] : ""
                //     },
                //     {
                //         "PicturePath": getImagePath[3] ? getImagePath[3] : ""
                //     }
                // ],
                CakeMessage: "message",
                CakeDescription: getCustomize?.text ? getCustomize?.text : ""
            };


            if (getImagePath?.length > 0) {
                let imageUrl = getImagePath?.map((v, i) => v);
                data["LstPhotos"] = imageUrl;
            } else {
                data["LstPhotos"] = [{
                    "DisplayOrder": 0,
                    "PictureAlt": "",
                    "PictureName": "no_pic.png",
                    "PicturePath": "https://fe-occasionimages-dec2023.s3.us-east-2.amazonaws.com/uploads/no_pic.png",
                    "PictureTitle": ""
                }];
                // data["LstPhotos"] = [{
                //     "DisplayOrder":1,
                //     "PictureAlt":"",
                //     "PictureName":"nopic.png",
                //     "PicturePath":"https://admin.munchbakery.com/docs/nopic.png",
                //     "PictureTitle":""
                // }];
            }

            let arr = []
            for (let i = 1; i <= others?.layer; i++) {
                arr?.push({
                    SecondHalfFlavorId: getFlavourMax?.length > 0 ? getFlavourMax[1]?.Id : 0,
                    FirstHalfFlavorId: getFlavourMax?.length > 0 ? getFlavourMax[0]?.Id : 0,
                    LayerNumber: i
                })
            }

            data["lstLayersInfo"] = arr;

            try {

                const header = {
                    headers: {
                        "Authorization": `basic ${custtoken}`,
                        "Content-Type": "application/json"
                    }
                }
                const response = await axios.post(`${MunchBakeryApiUrl}/AddToCartMunchCakeProduct/`, data, header);
                if (response?.data?.Response?.Status == "ERROR") {
                    return alert("Something went wrong. Please try again")
                } else {
                    setProductId(response?.data?.ProductId);
                    setTrueState(true);
                    await getShopGetShoppingCart();
                }

            } catch (error) {
                console.log("err", error);
                return alert("Something went wrong. Please try again")
            }
        }

    }

    const setImage = (val) => {

        if (val?.Id == 5) {
            return "images/Chocolate Slice Cake.jpg"
        } else if (val?.Id == 9) {
            return "images/Red Velvet Slice Cake.jpg"
        } else if (val?.Id == 10) {
            return "images/StrawberrySlice.jpg"
        } else if (val?.Id == 11) {
            return "images/VanillaSlice.jpg"
        } else if (val?.Id == 12) {
            return "images/ChocoVelvetSlice.jpg"
        } else if (val?.Id == 1) {
            return "images/lotus.jpg"
        } else if (val?.Id == 4) {
            return "images/CarrotSlice.jpg"
        } else if (val?.Id == 8) {
            return "images/DreamSLice.jpg"
        } else if (val?.Id == 2) {
            return "images/BluevelvetSlice.jpg"
        } else {
            return "images/DreamSLice.jpg"
        }
    }

    const setImageStyle = (val) => {

        if (val?.Id === getFlavourMax[0]?.Id) {
            return {
                border: '2px solid', borderRadius: '25px', padding: '2px'
            }
        } else if (val?.Id === getFlavourMax[1]?.Id) {
            return {
                border: '2px solid', borderRadius: '25px', padding: '2px'
            }
        } else {
            return {

            }
        }
    }

    useEffect(() => {
        if (lang.Lang.data?.lang) {
            let session_init = localStorage.getItem("start_session");
            if (session_init && session_init == "true") {
                let flavours = localStorage?.getItem("flavours") ? JSON.parse(localStorage?.getItem("flavours")) : "";
                SelectFlavour(flavours);
            }
        }

    }, [])

    const cartHandler = async () => {
        let session_init = localStorage.getItem("start_session");
        if (session_init && session_init == "true") {

        } else {
            return await addCart();
        }
    }




    const SelectFlavour = (val) => {

        localStorage.setItem("flavours", JSON.stringify(val));
        setFlavourMax((setVal) => [...setVal?.filter((v, i) => { return v?.Id !== val?.Id && setVal?.shift() }), val]);
        if (getFlavourMax.length > 0) {
            getFlavourMax?.map((v, i) => {
                if (v?.Id === val?.Id) {
                    return removeElementAtIndex(val)
                }
            })
        }

    }

    const removeElementAtIndex = (val) => {
        setFlavourMax(prevState => {
            const newArray = [...prevState];
            newArray?.splice(newArray?.findIndex(el => el?.Id === val?.Id), 1);
            return newArray;
        });
    }

    const revokeCouponCode = async (text = "") => {



        const data = {
            CustomerGuid: custGuid,
            CouponCode: text ? text : getText ? getText : ShopDetail?.CouponCode,
            IsApplyCopoun: "0",
            CustomerId: custId ? custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: "1",
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "-1"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/ApplyOrRevokeMunchCouponCode/`, data, header);

            if (response?.data?.ApplyOrRevokeMunchCouponCodeResult?.Status == "SUCCESS") {
                setText("")
                localStorage.removeItem("CouponCode");
                localStorage.removeItem("coupon_submit")
            }

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again")
        }
    }

    const getShopGetShoppingCart = async () => {
        let langName = lang.Lang.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: custGuid,
            CustomerId: custId ? custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            if (response?.data?.GetShoppingCartSummaryResult) {
                let shopCartItems = response?.data?.GetShoppingCartSummaryResult;
                setShopDetail(shopCartItems);
                if ((shopCartItems?.LstECommerceShoppingCartItem)?.length > 0) {
                    let shopItemsArray = shopCartItems?.LstECommerceShoppingCartItem;
                    let outOfStockItem = shopItemsArray?.filter((v) => !v?.InStock)
                    if (outOfStockItem?.length > 0) {
                        setOutofStock(true);
                    }
                }

            }

        } catch (error) {
            console.log("err", error);
            return alert("Something went wrong. Please try again");
        }

    }

    const handleStockUpdate = () => {
        return toast.error("Some Items out of stock", { position: toast.POSITION.TOP_LEFT });
    }



    useEffect(() => {
        if (lang.Lang.data?.lang) {
            // if (custGuid) {
            //     getShopGetShoppingCart();

            // }
            let coupen_submit = localStorage?.getItem("coupon_submit") ? JSON.parse(localStorage?.getItem("coupon_submit")) : "";
            if(coupen_submit?.value && coupen_submit?.value == 1) {
                revokeCouponCode(coupen_submit?.text);
            }

            if (ShopDetail?.CouponCode) {
                revokeCouponCode();
            }
        }

    }, [])

    useEffect(() => {
        if (!state?.bottom) {
            if (ShoppingDetails?.CouponCode) {
                revokeCouponCode();
            }
        }
    }, [!state?.bottom])



    const TotalAmount = () => {

        if (GetCategoryId == 57) {
            return cupCake?.cupCakeAmount ? cupCake?.cupCakeAmount : 0;
        } else if (GetCategoryId == 81) {
            return others?.cakeAmount ? others?.cakeAmount : 0;
        } else if (GetCategoryId == 20) {
            return others?.photoCakeAmount ? others?.photoCakeAmount : 0;
        } else if (GetCategoryId == 21) {
            return others?.frostingCakeAmount ? others?.frostingCakeAmount : 0;
        } else {
            return 0;
        }
    }

    const handleTextButton = (val) => {
        if (val === 1) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Price should be greater than 0.') : setCustomText('يجب أن يكون السعر أكبر من 0')
        } else {
            setOpened(true);
            return langId == 'en' ? setCustomText('Please select atleast one flavor.') : setCustomText('الرجاء اختيار نكهة واحدة على الأقل')
        }
    }




    // useEffect(() => {
    //     if (lang.Lang.data?.lang) {
    //         console.log("here___");
    //         let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";
    //         if (transaction_err?.err) {
    //             window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
    //             window.location.replace("/");
    //         }
    //         let get_failed_state = localStorage.getItem("push_state") ? localStorage.getItem("push_state") : "";
    //         if (!get_failed_state) {
    //             window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
    //             window.location.replace("/");
    //         }

    //         let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";
    //         if (get_success_state) {
    //             window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
    //             window.location.replace("/");
    //         }
    //     }
    // }, [lang])




    return (

        <Box component='div' className='page_default customize_page cust_third_step'>
            <ToastContainer />
            <Box component='div' className='custom_cake'>
                <img alt='cake step' src='images/cake_third_step.png' />
            </Box>
            <Typography component='h1' variant='h1'>
                {GetCategoryId == 81 ? flavorText?.heading3Dcake : flavorText?.heading}
            </Typography>
            <Box component='div' className='flavor_main'>
                {getCakeFlavour?.length > 0 ?
                    getCakeFlavour?.map((val, ind) => {
                        return (
                            <Box component='div' className='flavor_box' key={ind}>
                                <Box component='div' className={((val?.Name)?.trim())?.length < 12 ? 'flavored_text' : ''}>
                                    <Tooltip title={val?.Name} arrow>
                                        <Typography component='label' variant='label' >
                                            {val?.Name}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <Box component='div' className='flavor_img flavoured_box' >
                                    <div key={ind} className="uploaded-image">
                                        <img alt='flavours' src={setImage(val)} style={setImageStyle(val)} id={val?.Id} onClick={() => SelectFlavour(val)} />
                                    </div>
                                </Box>
                            </Box>
                        )
                    })
                    :
                    ""
                }
            </Box>
            <Box component='div' className='step_button_box'>
                <Box component='div' className='text'>
                    <Typography component='h4' variant='h4'>{flavorText?.currency} {TotalAmount()}</Typography>
                    <Typography component='p' variant='p'>{flavorText?.vat_included}</Typography>
                </Box>
                {/* {GetCategoryId == 57 || (getFlavourMax?.length > 0 && getFlavourMax?.length <= 2) ? */}
                {getFlavourMax?.length > 0 && getFlavourMax?.length <= 2 ?
                    (TotalAmount() == 0 ?
                        <Box component='div' className='btn' onClick={() => handleTextButton(1)}>
                            <Button className="upload_btn" aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                                {flavorText?.next_btn}
                            </Button>
                        </Box>
                        :
                        getOutofStock ?
                            <Box component='div' className='btn' onClick={() => handleStockUpdate()}>
                                <Button className="upload_btn" aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                                    {flavorText?.next_btn}
                                </Button>
                            </Box>
                            :
                            <Box component='div' className='btn' onClick={() => !state?.bottom ? cartHandler() : ""}>
                                <CheckoutDrawer others={others} cupCake={cupCake} getFlavourMax={getFlavourMax} getCartDetail={getCartDetail} custtoken={custtoken} setState={setState} state={state} getText={getText} setText={setText} productDetail={productDetail} ShoppingDetails={ShoppingDetails} setShoppingDetails={setShoppingDetails} revokeCouponCode={revokeCouponCode} getProductId={getProductId}  getTrueState={getTrueState}/>
                            </Box>

                    )

                    :
                    <Box component='div' className='btn'>
                        <Button className="upload_btn" onClick={() => handleTextButton(2)} aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                            {flavorText?.next_btn}
                        </Button>
                    </Box>
                }
            </Box>
            <Dialog
                open={opened}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={() => setOpened(false)}
                aria-labelledby="responsive-dialog-title"
                aria-describedby="responsive-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title">
                    {getCustomText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="responsive-dialog-description">
                        {/* You will loose your progress, are you sure to move back ? */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => handleButton("0")}>{flavorText?.cancel}</Button> */}
                    <Button onClick={() => setOpened(false)} autoFocus>
                        {flavorText?.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
}
