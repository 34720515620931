import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

import "./App.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { InitializeGoogleAnalytics } from "./utils/google-analytics/events";
import { InitializeGoogleTagManager } from "./utils/google-tag-manager/events";
// import AuthLayout from './container/Pages/AuthLayout';

const store = createStore(rootReducer, applyMiddleware(thunk));

InitializeGoogleAnalytics();
InitializeGoogleTagManager();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    //   <React.StrictMode>

        <Provider store={store}>
            <BrowserRouter>
                {/* <AuthLayout> */}
                <App />
                {/* </AuthLayout> */}
            </BrowserRouter>
        </Provider>

    //    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
