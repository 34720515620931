import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation } from 'react-router-dom';
import CakeDesignModal from '../../components/CakeDesignModal'
import { useDispatch, useSelector } from 'react-redux';
import { GetCategoryCakes, GetCategory } from '../../actions/category';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { ProductSearch } from '../../actions/product';
import { ToastContainer, toast } from "react-toastify";
import { LOGIN_SUCCESS } from "./../../constants/ActionCategoryTypes";
import CryptoJS from "crypto-js";
import { API_URL } from '../../global';
import axios from 'axios';
import ReactGA from "react-ga4";
import { TrackGoogleAnalyticsEvent } from '../../utils/google-analytics/events';
import { TrackGoogleTagManagerEvent, TrackGoogleTagManagerPageView } from '../../utils/google-tag-manager/events';




const PerRow = 6;
export default function Gallery() {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const location = useLocation();
    const [CakeCategoryId, setCakeCategoryId] = useState(localStorage.getItem("categoryId") ? JSON.parse(localStorage.getItem("categoryId"))?.Id : "");
    const [CategoryId, setCategoryId] = useState(location.state?.CategoryId);
    const [CategoryId2, setCategoryId2] = useState(location.state?.CategoryId2);
    const [CategoryCakes, setCategoryCakes] = useState([]);
    const [Categories, setCategories] = useState([]);
    const [Loader, setLoader] = useState(false);
    const [Loader1, setLoader1] = useState(false);
    const [searchIcon, setSearchIcon] = useState(true);
    const [search_input, setSearch] = useState('');
    const [ActiveCategory, setActiveCategory] = useState(location.state?.CategoryId2);
    const store = useSelector((state) => state);
    const customizeText = store.Lang.data?.customize ? store.Lang.data.customize : '';
    const [isActive, setIsActive] = useState(false);
    const [next, setNext] = useState(PerRow);
    const [getLoadVAl, setLoadVAl] = useState(false);


    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = useState(user);


    const custGuid = store?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const custtoken = store?.auth?.user?.cus_to || store1[0]?.cus_to;
    const custId = store?.auth?.user?.cus_id || store1[0]?.cus_id;


    const handleClick = event => {
        setIsActive(current => {
            return !current
        });
    };

    useEffect(() => {
        setLoader1(true);
        setLoader(true);
        setNext(PerRow);

        if (store.Lang.data?.lang) {
            dispatch(GetCategoryCakes(store?.Lang?.data?.lang, CategoryId2, custtoken)).then((response) => {
                if (response) {
                    setLoader1(false);
                }
            });

            dispatch(GetCategory(store.Lang.data?.lang, custGuid)).then((response) => {

                if (!response?.data?.succuss) {
                    setLoader(false);
                    toast.error(response?.data?.error, { position: toast.POSITION.TOP_LEFT });
                }
            });
        }


    }, [dispatch]);

    useEffect(() => {

        if (store.Lang.data?.lang) {
            if(CakeCategoryId == 81) {
                TrackGoogleTagManagerPageView('products/3D', document.location.origin + document.location.pathname + document.location.search);
            } else if (CakeCategoryId == 20){
                TrackGoogleTagManagerPageView('products/photo_occasion', document.location.origin + document.location.pathname + document.location.search );
            } else if (CakeCategoryId == 21) {
                TrackGoogleTagManagerPageView('products/frosting', document.location.origin + document.location.pathname + document.location.search);
            } else if (CakeCategoryId == 57) {
                TrackGoogleTagManagerPageView('products/decoration cupcake', document.location.origin + document.location.pathname + document.location.search);
            }
        }



        let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";
        if (transaction_err?.err && transaction_err?.err == "declined") {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }

        let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";
        if(get_success_state) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }
    }, [])

    useEffect(() => {
        if (store.Category.data) {
            setCategories(store.Category.data.data);
        }
        if (store.CategoryReducer.data) {
            if (CategoryId2 == 57) {
                if (store.CategoryReducer?.data?.success) {
                    if (store.CategoryReducer?.data?.data?.documents?.length > 0) {
                        let inArray = store.CategoryReducer?.data?.data?.documents;
                        setCategoryCakes(inArray?.filter((v, i) => v?.BE_id != 1689 && v?.BE_id != 1688));
                    }
                }
            } else {
                setCategoryCakes(store.CategoryReducer.data);
            }
        }
    }, [store?.Category.data, store.CategoryReducer.data]);


    const GetCategoryCakess = (e, val) => {
        setCategoryId2(val?.Id)
        setActiveCategory(val?.Id);
        setCategoryId(val?.Description);
        setLoader1(true);
        setNext(PerRow)

        dispatch(GetCategoryCakes(store.Lang.data?.lang, val?.Id, custtoken)).then((response) => {
            setLoader1(false);
        });
    }


    const inputSearch = (event) => {
        if (event.target.value) {
            setSearchIcon(false)
        } else {
            setSearchIcon(true)
        }

        setSearch(event.target.value);
    }
    const handleKeyPress = (event) => {
        if (event?.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearch = () => {
        if (search_input) {
            setLoader(true);
            dispatch(ProductSearch(search_input, store.Lang.data?.lang)).then((response) => {
                if (response?.data?.success) {
                    setLoader(false)
                    setCategoryCakes(response.data?.data?.GetMunchCakeProductsResult?.lstECommerceProductsSearchResults);
                } else {
                    setLoader(false)
                    setCategoryCakes([]);
                }

            })
            // const filteredProducts = Categories.filter((product) => product.CatName.toLowerCase().includes(search_input.toLowerCase()) && product.MetaTitle !== "HomePage" && product.Description === ActiveCategory );
            setLoader(false);
            // setCategories(filteredProducts);
        } else {
            toast.warning("please enter any key for search product", { position: toast.POSITION.TOP_LEFT });
        }

    }


    const [loadedImages, setLoadedImages] = useState([]);

    const handleImageLoad = (imageUrl) => {
        // console.log(imageUrl, "jasgdjkasghjk")
        setLoadedImages((prevLoadedImages) => [...prevLoadedImages, imageUrl]);
    };

    const RemoveItem = () => {
        dispatch({ type: "GET_IMAGE", payload: null });
        localStorage.removeItem("customize")
        localStorage.removeItem("image_path");
        localStorage.removeItem("ProductDetails");


        // Send a custom event
        TrackGoogleAnalyticsEvent("Click on", "make your cake - Start now", "make your cake - Start now", 1);
        TrackGoogleTagManagerEvent("make your cake - Start now", "Click on", 5);


    }

    const galleryText = store.Lang.data?.gallery ? store.Lang.data.gallery : '';

    const bothHandle = () => {
        handleClick();
        setLoader1(true);
        dispatch(GetCategoryCakes(store.Lang.data?.lang, CakeCategoryId, custtoken)).then((response) => {
            setLoader1(false);
        });
        setNext(PerRow)

        setSearch('')
        setActiveCategory(CakeCategoryId)
        setCategoryId2(CakeCategoryId)
    }

    const wheel = (e) => {
        if (e?.deltaY == -120) {
            return false;
        }
        if (!Loader1) {
            setLoadVAl(true);
            setTimeout(() => {
                if (next < CategoryCakes?.length) {
                    loadMore()
                    setLoadVAl(false);
                }
            }, 700)
        }
    }

    const Mouse = (e) => {
        if (!Loader1) {
            setLoadVAl(true);
            setTimeout(() => {
                if (next < CategoryCakes?.length) {
                    loadMore()
                    setLoadVAl(false);
                }
            }, 700)
        }
    }

    const loadMore = () => {
        setNext(next + PerRow);
    };





    return (
        <React.Fragment>
            <ToastContainer />
            <Box component='div' className='page_default gallery_page_main'>
                {
                    CakeCategoryId == 81 && <Box component='div' className='card_with_bg'>
                        <Box component='div' className='card_with_bg_inner'>
                            <Typography component='h1' variant='h1'>
                                {galleryText?.banner_heading}
                            </Typography>
                            <Link to='/customize' className='text-decoration' onClick={() => RemoveItem()}>
                                <Button>
                                    {galleryText?.banner_btn}
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                }
                <Box component='div' className='gallery_main'>
                    {
                        CakeCategoryId == 81 && <Typography component='h2' variant='h2'>
                            {galleryText?.heading2}
                        </Typography>
                    }
                    <Box component='div' className={`slider_btns_main ${isActive ? 'search_active' : ''}`}>
                        <search className='search_bar_custom'>
                            {!isActive ?
                                (
                                    <Button className='search_btn' onClick={handleClick}>
                                        <SearchIcon />
                                    </Button>
                                ) :
                                (
                                    <>
                                        <Button className='search_btn' onClick={() => bothHandle()}>
                                            <CloseIcon />
                                        </Button>

                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            id="id_search"
                                            name="search"
                                            placeholder={galleryText?.search_by}
                                            autoFocus
                                            value={search_input}
                                            onChange={inputSearch}
                                            onKeyDown={handleKeyPress}
                                            ref={inputRef}
                                        />
                                    </>
                                )
                            }
                            {!Loader ?
                                (
                                    <Button type="submit" className={`${searchIcon && 'search_close_none'} search_close`} onClick={handleSearch}>
                                        <SearchIcon />
                                    </Button>
                                ) :
                                (
                                    <Box className='search_close search_loader' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4vh', width: '-webkit-fill-available' }}>
                                        <CircularProgress style={{ width: "20px", height: "20px" }} />
                                    </Box>
                                )
                            }

                        </search>
                        <Box component='div' className='slider_btns'>
                            {Categories.length > 0 ?
                                (
                                    Categories.map((val, index) => {
                                        if (val.Description === CategoryId) {
                                            return (
                                                <Button key={index} className={ActiveCategory === val.Id ? 'with_text active_category' : 'with_text'} onClick={(e) => GetCategoryCakess(e, val)}>
                                                    {val?.MetaTitle == "HomePage" && (val?.MetaKeyword == "All" || val?.MetaKeyword == "الكل") ? val?.MetaKeyword : val?.CatName}
                                                </Button>
                                            )
                                        }

                                    })
                                ) :
                                (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4vh', width: '-webkit-fill-available' }}>
                                        <CircularProgress style={{ width: "20px", height: "20px" }} />
                                    </Box>
                                )

                            }
                        </Box>
                    </Box>
                    {!Loader1 ?
                        (
                            <Box component='div' className='gallery_boxes' onTouchMove={(e) => next < CategoryCakes?.length ? Mouse(e) : ""} onWheel={(e) => next < CategoryCakes?.length ? wheel(e) : ""}>
                                {CategoryCakes?.length > 0 ? (
                                    CategoryCakes?.slice(0, next)?.map((val) => {

                                        const picturePath = CakeCategoryId == 57 ? val?.url : val?.lstPictures[0]?.PicturePath;
                                        const pictureDetails = CakeCategoryId == 57 ? { DisplayOrder: 0, PictureAlt: "", PictureName: "", PicturePath: val?.url } : val?.lstPictures[0];
                                        const Price = CakeCategoryId == 57 ? val?.Price_in_SAR : val?.Price;
                                        const ProductId = CakeCategoryId == 57 ? val?.BE_id : val?.Id;
                                        const ProductName = CakeCategoryId == 57 ? val?.decoration_cupcake_item : val?.Name;

                                        return (
                                            <Box component='div' className='box_g' key={ProductId} >
                                                <Box component='div' className='img'>
                                                    {!loadedImages.includes(picturePath) ? (
                                                        <Box className='search_close search_loader' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '4vh', width: '-webkit-fill-available' }}>
                                                            <CircularProgress style={{ width: "20px", height: "20px" }} />
                                                            <img src={picturePath} alt={ProductId} onLoad={() => handleImageLoad(picturePath)} style={{ display: "none" }} />
                                                        </Box>
                                                    ) : (
                                                        <>
                                                            <CakeDesignModal lang={galleryText} picturePath={pictureDetails} prodId={ProductId} Price={Price} />
                                                            <img src={picturePath} alt={ProductId} onLoad={() => handleImageLoad(picturePath)} />
                                                            {CakeCategoryId != 81 ?
                                                                <>
                                                                    <Box component='div' className='name_text'>
                                                                        {ProductName}
                                                                    </Box>
                                                                    <Box component='div' className='price_text'>
                                                                        {CakeCategoryId != 57 ? customizeText?.from : ""} {Price} {customizeText?.currency}
                                                                    </Box>
                                                                </>
                                                                : ""
                                                            }
                                                        </>
                                                    )
                                                    }
                                                </Box>
                                            </Box>
                                        );

                                        // } else {
                                        //     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available' }}>
                                        //         <Typography component='h2' variant='p'>
                                        //             Not Found
                                        //         </Typography>
                                        //     </Box>
                                        // }

                                    }))
                                    :
                                    (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available', minWidth: '100%' }}>
                                            <Typography component='h2' variant='p'>
                                                Not Found
                                            </Typography>
                                        </Box>
                                    )
                                }
                                {(!Loader1 && next <= CategoryCakes?.length) && getLoadVAl ?
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "5vh",
                                            width: "-webkit-fill-available",
                                            minWidth: "100%"

                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                    : ""
                                }
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', width: '-webkit-fill-available' }}>
                                <CircularProgress />
                            </Box>
                        )
                    }

                </Box>
            </Box>
        </React.Fragment>
    )
}
