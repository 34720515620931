import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from './container/Pages/AboutUs';
import Ourlocation from './container/Pages/ourlocation';
import Profile from './container/Pages/profile';
import MyOrders from './container/Pages/myOrders';
import MyOrderDetail from './container/Pages/myOrderDetail';
import LandingPage from './container/Pages/start';
import StartNext from './container/Pages/startNext';
import Location from './container/Pages/location';
import Gallery from './container/Pages/gallery';
import Customize from './container/Pages/customize';
import Customizenext from './container/Pages/customizeNext';
import PaymentLoader from './container/Pages/PaymentLoader';
import CustomizeThird from './container/Pages/customizeThird';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import ThankyouPage from './container/Pages/thankyouPage';


const Router = () => {
    return (
        <Routes>
            {/***************** Page Router *****************/}
            {/* <Route exact path="/" element={<LandingPage />} /> */}
            {/* <Route exact path="/create-cake" element={<StartNext />} /> */}
            {/* <Route exact path="/about-us" element={<AboutUs />} /> */}
            {/* <Route exact path="/our-location" element={<Ourlocation />} /> */}
            {/* <Route exact path="/myOrders" element={<MyOrders />} /> */}


            <Route exact path="/" element={<StartNext />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/myOrderDetail" element={<MyOrderDetail />} />
            <Route exact path="/location" element={<Location />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/customize" element={<Customize />} />
            <Route exact path="/customizeNext" element={<Customizenext />} />
            <Route exact path="/customizeThird" element={<CustomizeThird />} />
            <Route exact path="/thankyouPage" element={<ThankyouPage />} />
            <Route exact path="/PaymentPath" element={<PaymentLoader />} />
        </Routes>
    );
}

export default Router;
