import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ChooseLocationModal from "../../components/ChooseLocationModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetStoresDistance } from "../../actions/category";
import { OrderDetailsInsert } from "../../actions/orderDetail";
import CircularProgress from "@mui/material/CircularProgress";
import DeliveryLocationDrawer from "../../components/DeliveryLocationDrawer";
import { MunchBakeryApiUrl, API_URL } from "../../global";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import axios from 'axios';
import moment from "moment";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import haversine from "haversine-distance";
import { Mouse } from "@mui/icons-material";
import "dayjs/locale/ar-sa";
import { logout } from "../../actions/auth";
import { TrackGoogleTagManagerPageView } from "../../utils/google-tag-manager/events.js";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


const PerRow = 6;
export default function BasicTabs(props) {
    const {
        CategoryId,
        CategoryId2,
        token,
        customerGuid,
        customerId,
        apiTrigger,
    } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const minDateTimePick = moment().add(48, "hours").format("MM/DD/YYYY 17:00");
    const DateTimePick = moment().add(48, "hours").format("MM/DD/YYYY hh:mm A");
    const [day, setDay] = useState(moment().add(48, "hours").format("dddd"));
    const [selectedDateTime, setSelectedDateTime] = useState(minDateTimePick);
    const timeFormat2 = moment(minDateTimePick).format("HH:mm");
    const [Id, setCategoryId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [storesDistance, setStoreDistance] = useState(null);
    const [getStore, setStoreDetails] = useState([]);
    const [lngLat, setLngLat] = useState(null);
    const [value, setValue] = useState(1);
    const [getDateTime, setDateTime] = useState(minDateTimePick);
    const [getTime, setTime] = useState(timeFormat2);
    const [bookSlot, setBookedSlot] = useState([]);
    const [availableSlot, setAvailableSlot] = useState([]);
    const [newSlot, setNewSlot] = useState([]);
    const [next, setNext] = useState(PerRow);
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store = useState(user);
    const lang = useSelector((state) => state);
    const locationText = lang.Lang.data?.location ? lang.Lang.data.location : "";
    const custGuid = lang?.auth?.user?.cust_guid || customerGuid || store[0]?.cust_guid;
    const custtoken = lang?.auth?.user?.cus_to || token || store[0]?.cus_to;
    const custId = lang?.auth?.user?.cus_id || customerId || store[0]?.cus_id;
    const langId = lang.Lang.data?.lang ? lang.Lang.data?.lang : "";

    const [getLoadVAl, setLoadVAl] = useState(false);
    const [getError, setError] = useState(false);

    const [isTime, setIsTime] = useState(false);
    const [errors, setErrors] = React.useState(null);
    const [loader2, setLoader1] = useState(false);
    const in_arabic = Intl.DateTimeFormat('ar-eg', { hour: 'numeric', minute: "numeric", hour12: true }).format(moment().add(48, "hours"))
    const in_english = Intl.DateTimeFormat('en', { hour: 'numeric', minute: "numeric", hour12: true }).format(moment().add(48, "hours"))
    const [timer, setTimer] = React.useState(in_english);








    // useEffect(() => {

    //     if (navigator.geolocation) {

    //         navigator.geolocation.watchPosition(() => {
    //             console.log("hii");
    //         });

    //         navigator.geolocation.getCurrentPosition((position) => {

    //             const { longitude, latitude } = position.coords;
    //             setLngLat({ longitude, latitude });

    //             if (getDateTime) {

    //                 if (lang.Lang.data?.lang) {

    //                     setAvailableSlot([]);
    //                     setBookedSlot([]);
    //                     newStoreList();

    //                     setNext(PerRow);
    //                 }
    //             }
    //         }, () => {

    //             if (getDateTime) {
    //                 if (lang.Lang.data?.lang) {
    //                     setAvailableSlot([]);
    //                     setBookedSlot([]);
    //                     newStoreList();

    //                     setNext(PerRow);
    //                 }
    //             }
    //         }, { maximumAge: 0, enableHighAccuracy: true })
    //     } else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }

    // }, [navigator.geolocation, getDateTime])

    useEffect(() => {

        if(langId) {
            TrackGoogleTagManagerPageView('branches', document.location.origin + document.location.pathname + document.location.search);
        }



        let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";
        if (transaction_err?.err && transaction_err?.err == "declined") {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }

        let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";
        if(get_success_state) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }
    }, [])



    useEffect(() => {
        setNewSlot([]);
        const slot_1 = availableSlot?.sort((a, b) => lngLat != null ? (DistanceCovered(a?.Latitude, a?.Longitude) > DistanceCovered(b?.Latitude, b?.Longitude) ? 1 : -1) : a.branch_name > b.branch_name ? 1 : -1)
        const slot_2 = bookSlot?.sort((a, b) => lngLat != null ? (DistanceCovered(a?.Latitude, a?.Longitude) > DistanceCovered(b?.Latitude, b?.Longitude) ? 1 : -1) : a.branch_name > b.branch_name ? 1 : -1)
        const val = slot_1.concat(slot_2)
        setNewSlot(val);
    }, [availableSlot, bookSlot])






    useEffect(() => {
        if (CategoryId2) {
            localStorage.setItem("categoryId", JSON.stringify({ Id: CategoryId2 }));
            dispatch({ type: "GET_USER", payload: { cus_id: custId, cust_guid: custGuid, cus_to: custtoken } });
        }
    }, [CategoryId2])



    // useEffect(() => {
    //     if (getDateTime) {
    //         newStoreList();
    //         setNext(PerRow);
    //     }
    // }, [getDateTime])


    useEffect(() => {

        let catClick = localStorage.getItem("catClick");
        // if (!lang?.Locations?.data?.data?.length > 0 || catClick == "false") {
            setCategoryId(CategoryId);

            // Function to get the current position
            const getPosition = () => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const { longitude, latitude } = position.coords;
                        setLngLat({ longitude, latitude });

                        if (getDateTime) {

                            if (lang.Lang.data?.lang) {

                                setAvailableSlot([]);
                                setBookedSlot([]);
                                newStoreList();

                                setNext(PerRow);
                            }
                        }
                    },
                        (error) => {
                            if (getDateTime) {
                                if (lang.Lang.data?.lang) {
                                    setAvailableSlot([]);
                                    setBookedSlot([]);
                                    newStoreList();

                                    setNext(PerRow);
                                }
                            }
                            // setLoader(false);
                            console.log(error.message);
                        }, { maximumAge: 0, enableHighAccuracy: true }
                    );
                } else {
                    alert("Geolocation is not supported by this browser.");
                }
            };
            // setLoader(true);
            getPosition();
            localStorage.setItem("catClick", true);
        // } else {
        //     setStoreDistance(lang?.Locations?.data?.data);
        // }
    }, [navigator.geolocation, getDateTime]);

    useEffect(() => {
        const get_category = async () => {
            let langName = lang.Lang.data?.lang;
            const langId = langName == 'ar' ? 0 : 1;
            const response = await axios.get(`${API_URL}/get-categories?langId=${langId}&guid=${custGuid}`, {
                headers: {
                    'Cookie': 'ASP.NET_SessionId=ocwv0fu0kxr2oyhk2hc5nn5r'
                }
            });
            if (response?.data?.success) {
                localStorage.setItem("category", JSON.stringify(response.data.data))
            }
        };
        if (lang.Lang.data?.lang) {
            get_category();
        }
    }, [lang.Lang.data?.lang, dispatch])

    useEffect(() => {
        if (errors) {
            setLoader1(false);
        } else {
            setLoader1(true)
        }

    }, [errors])


    useEffect(() => {

        const myInterval = setInterval(() => {
            langId == 'en' ? setTimer(in_english) : setTimer(in_arabic);
            NotFridayMessageReturn();
            FridayMessageReturn();
        }, 1000);

        return () => {
            clearInterval(myInterval);
        }

    }, [errors, day, timer, langId])

    const clickLocation = (e, val) => {

        const { branch_name, branch_id } = val;

        const cakeDetails = {
            cake_name: "",
            store_name: branch_name,
            cake_price: "",
            total_price: "",
            cake_image: "",
            cake_layer: "",
            layer_price: "",
            character: "",
            character_price: "",
            cake_flavor: "",
        };
        dispatch(OrderDetailsInsert(cakeDetails));
        let path = `/gallery`;
        let Id = CategoryId;

        let state = { CategoryId: Id, CategoryId2: CategoryId2 };
        navigate(path, { state: state });
        let formattedDate = moment(getDateTime).format("MM/DD/YYYY");
        localStorage.setItem("cart_details", JSON.stringify({ StoreId: branch_id, OrderDate: formattedDate, OrderTime: getTime, val }))
    };

    const handleDateTimeChange = async (value) => {
        setLoader(true);
        setAvailableSlot([]);
        setBookedSlot([]);
        setSelectedDateTime(value.$d);
        let dateFormat = moment(value.$d).format("MM/DD/YYYY hh:mm A");
        let timeFormat = moment(dateFormat).format("HH:mm");
        setTime(timeFormat);
        setDateTime(dateFormat);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { longitude, latitude } = position.coords;
                    setLngLat({ longitude, latitude });

                    // dispatch(
                    //   GetStoresDistance(
                    //     longitude,mam, Working on this point
                    //     dateFormat,
                    //     custGuid,
                    //     custtoken,
                    //     custId,
                    //     CategoryId2
                    //   )
                    // ).then((response) => {
                    //   if (response?.data?.success) {
                    //     setLoader(false);
                    //     setStoreDistance(response.data.data);
                    //   } else {
                    //     setLoader(false);
                    //     // alert("could not found stores distance")
                    //   }
                    // });
                },
                (error) => {
                    // setLoader(false);
                    console.log(error.message);
                }
            );
        } else {
            setLoader(false);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    // const fetchDataAll = async (dateFormat, val) => {

    //     const { StroreID, Coordinates, StoreName_Eng, Published, Busy, StoreNameAr, VAT } = val;


    //     try {

    //         const formattedDate = moment(dateFormat).format("MM/DD/YYYY");

    //         const requestBody = {
    //             LocationSelected: 0,
    //             OrderDate: formattedDate,
    //             DistrictId: "31",
    //             CityId: "-1",
    //             ZoneId: "-1",
    //             OrderType: "15",
    //             StoreId: StroreID,
    //             CustomerId: custId
    //         };

    //         const header = {
    //             headers: {
    //                 "Authorization": `basic ${custtoken}`,
    //                 "Content-Type": "application/json"
    //             }
    //         }

    //         const response = await axios.post(`${MunchBakeryApiUrl}/GetTimeSlotsMunchBakeryNew/`, requestBody, header);

    //         if(response?.data) {

    //             let timeSlotResponse = response?.data;
    //             const Latitude = (Coordinates?.split(",")[0])?.trim();
    //                 const Longitude = (Coordinates?.split(",")[1])?.trim();

    //                 let json = {
    //                     branch_id: StroreID,
    //                     branch_name: langId == "en" ? StoreName_Eng : StoreNameAr,
    //                     DisplayOrder: 1,
    //                     Distance: 0,
    //                     Latitude,
    //                     LocationBusinessUrl: "",
    //                     Longitude,
    //                     StateId: 1,
    //                     VAT
    //                 }


    //                 if (timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult?.length > 0 && Busy == "NO") {
    //                     let listing  = {
    //                         branch_id: json?.branch_id,
    //                         time_slot: timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult,
    //                         date: requestBody?.OrderDate
    //                     }
    //                     console.log(listing,  "<<<<<<<<<<<<<< listing");
    //                     json['isFully'] = false;
    //                     json['timeSlot'] = timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult;
    //                     setAvailableSlot((availableSlot) => [...availableSlot?.filter((v) => { return v?.branch_id != json?.branch_id }), json]);
    //                 } else {
    //                     json['isFully'] = true;
    //                     json['timeSlot'] = timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult;
    //                     setBookedSlot((bookSlot) => [...bookSlot?.filter((v) => { return v?.branch_id != json?.branch_id }), json]);
    //                 }

    //         } else {
    //             console.log("out");

    //         }


    //     } catch (error) {
    //          console.log("timeSlotResponse-----error", error);
    //         setError(true);
    //     }

    // };

    const fetchAll = (dateFormat, val) => {

        const { StroreID, Coordinates, StoreName_Eng, Published, Busy, StoreNameAr, VAT, PaymentGatewayID } = val;

        return new Promise((resolve, reject) => {

            const formattedDate = moment(dateFormat).format("MM/DD/YYYY");

            const requestBody = {
                LocationSelected: 0,
                OrderDate: formattedDate,
                DistrictId: "31",
                CityId: "-1",
                ZoneId: "-1",
                OrderType: "15",
                StoreId: StroreID,
                CustomerId: custId
            };


            const headers = new Headers();
            headers.append("Authorization", "Basic " + custtoken);
            headers.append("Content-Type", "application/json");

            fetch(`${MunchBakeryApiUrl}/GetTimeSlotsMunchBakeryNew/`,
                {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify(requestBody),
                }
            )
                .then((response) => response?.json())
                .then((timeSlotResponse) => {

                    const Latitude = (Coordinates?.split(",")[0])?.trim();
                    const Longitude = (Coordinates?.split(",")[1])?.trim();

                    let json = {
                        branch_id: StroreID,
                        branch_name: langId == "en" ? StoreName_Eng : StoreNameAr,
                        DisplayOrder: 1,
                        Distance: 0,
                        Latitude,
                        LocationBusinessUrl: "",
                        Longitude,
                        StateId: 1,
                        VAT,
                        PaymentGatewayID
                    }


                    if (timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult?.length > 0 && Busy == "NO") {
                        json['isFully'] = false;
                        json['timeSlot'] = timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult;
                        setAvailableSlot((availableSlot) => [...availableSlot?.filter((v) => { return v?.branch_id != json?.branch_id }), json]);
                    } else {
                        json['isFully'] = true;
                        json['timeSlot'] = timeSlotResponse?.GetTimeSlotsMunchBakeryNewResult;
                        setBookedSlot((bookSlot) => [...bookSlot?.filter((v) => { return v?.branch_id != json?.branch_id }), json]);
                    }


                })
                .catch((error) => {
                    console.log("timeSlotResponse-----error", error);
                    setError(true);
                    logout()

                });
        });
    };

    const newStoreList = async () => {
        setLoader(true)

        try {
            const result = await axios.get(`${API_URL}/store-list`);
            if (result?.data?.data?.documents.length > 0) {
                let array = result?.data?.data?.documents;
                //     let test_static = {
                //         "_id": "657088a8fd5b0e1871da34d5",
                //         "StroreID": 58,
                //         "City_En": "Test",
                //         "City_Ar": "جدة",
                //         "StoreName_Eng": "Virtual Store (For Test)",
                //         "StoreNameAr": "الحمراء",
                //         "URL": "https://goo.gl/maps/iZm8Aii7no3hG21Q8",
                //         "Coordinates": "21.538289915231427, 39.164344555303",
                //         "VAT": "1111310924354400003",
                //         "Busy": "YES",
                //         "Published": "NO",
                //         "FutureDays": "",
                //         "WT": "",
                //         "CapacityGroup": "",
                //         "Factory": "",
                //         "PaymentGatewayID": "",
                //         "DeliveryKM": 1,
                //         "DeliveryEnabled": ""
                //     }
                // array.push(test_static);
                setAvailableSlot([]);
                setBookedSlot([]);
                // let newArray = array?.filter((val) => {
                //     if (val?.Published == "YES") {
                //         return fetchAll(getDateTime, val);
                //         // await fetchDataAll(getDateTime, val)
                //     }
                // });
                let newArray = array?.filter(val => val?.Published == "YES" && fetchAll(getDateTime, val))
                setStoreDetails(newArray)
                setLoader(false)
            } else {
                setLoader(false);
                setError(true)
            }

        } catch (error) {
            setError(true)
            console.log("-----error", error);
        }
    }

    const DistanceCovered = (latitude, longitude) => {

        //First point in your haversine calculation
        let point1 = { lat: lngLat?.latitude, lng: lngLat?.longitude }

        //Second point in your haversine calculation
        let point2 = { lat: latitude, lng: longitude }

        let haversine_m = haversine(point1, point2); //Results in meters (default)
        let haversine_km = haversine_m / 1000; //Results in kilometers
        return Math.round(haversine_km);
    }

    const loadMore = () => {
        setNext(next + PerRow);
    };

    const wheel = (e) => {
        if (e?.deltaY == -120) {
            return false;
        }
        if (!loader) {
            setLoadVAl(true);
            setTimeout(() => {
                if (next < newSlot?.length) {
                    loadMore()
                    setLoadVAl(false);
                }
            }, 700)
        }
    }

    const Mouse = (e) => {
        if (!loader) {
            setLoadVAl(true);
            setTimeout(() => {
                if (next < newSlot?.length) {
                    loadMore()
                    setLoadVAl(false);
                }
            }, 700)
        }
    }

    const DateTimeChangeHandler = (e) => {

        let selectedDay = moment(e?.$d).format("dddd");
        setDay(selectedDay);
        setIsTime(true);
    }


    const NotFridayMessageReturn = () => {

        if (!isTime) {
            if (dayjs(DateTimePick).get('hour') < 11) {
                return langId != 'en' ? `يرجى تحديد الوقت بين الساعة الحادية عشر صباحا والعاشرة مساءً` : `Please choose a time between 11:00 AM and 10:00 PM`;
            }
        } else {
            return langId != 'en' ? `يرجى تحديد الوقت بين الساعة الحادية عشر صباحا والعاشرة مساءً` : `Please choose a time between 11:00 AM and 10:00 PM`;
        }
    }

    const FridayMessageReturn = () => {

        if (!isTime) {
            if (dayjs(DateTimePick).get('hour') < 15) {
                return langId != 'en' ? `يرجى تحديد الوقت بين الساعة  الثالثة مساءً والعاشرة مساءً` : `Please choose a time between 03:00 PM to 10:00 PM`;
            }
        } else {
            return langId != 'en' ? `يرجى تحديد الوقت بين الساعة  الثالثة مساءً والعاشرة مساءً` : `Please choose a time between 03:00 PM to 10:00 PM`;
        }
    }


    const errorMessage = React.useMemo(() => {

        let notFridayError = NotFridayMessageReturn();
        let isFridayError = FridayMessageReturn();

        switch (errors) {
            case 'maxTime': {
                return day != 'Friday' ? notFridayError : isFridayError;
            }
            case 'minTime': {
                return day != 'Friday' ? notFridayError : isFridayError;
            }

            case 'invalidDate': {
                return 'Your date is not valid';
            }

            default: {
                return '';
            }
        }
    }, [errors, day]);

    const changeDateNotFridayTimePickHander = () => {
        return dayjs(DateTimePick).set('hour', 11).set('minute', 0);
    }

    const changeDateFridayTimePickHander = () => {
        return dayjs(DateTimePick).set('hour', 15).set('minute', 0);
    }

    const dateTimeDisableHandler = () => {
        if(newSlot?.length == 0) {
            return true;
        } else if (newSlot?.length != getStore?.length) {
            return true;
        }
    }


    // console.log({newSlot, getStore});
    // console.log({availableSlot, bookSlot});

    return (
        <Box sx={{ width: "100%" }} className="delivery_pickup_tabs" >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="tabs_button"
                >
                    <Tab
                        label={locationText?.delivery}
                        icon={<img src="images/location_svg.svg" />}
                        {...a11yProps(0)}
                        className="delivery"
                    />
                    <Tab
                        label={locationText?.pickup}
                        icon={<img src="images/location_svg.svg" />}
                        {...a11yProps(1)}
                        className="pickup"
                    />
                </Tabs>
            </Box>
            <Box component="div" className="pickup_datepicker"></Box>
            <TabPanel value={value} index={0}>
                <Box component="div" className="delivery_tab_content">
                    <Typography
                        component="h4"
                        variant="h4"
                        className="coming_soon"
                        gutterBottom
                    >
                        {locationText?.coming_soon}
                    </Typography>
                    {/* <Box component='div' className='map_iframe'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57775.171756022275!2d46.38095855006717!3d25.171225687162654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ea7a20b5c98c3%3A0x2cc8ef0ad6ab49fe!2z2YPZiNio2LHZiiDZhdmE2YfZhSDYp9mE2KzYr9mK2K8!5e0!3m2!1sen!2sin!4v1687185868944!5m2!1sen!2sin" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Box> */}
                    {/* <DeliveryLocationDrawer /> */}
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box component="div" className="date_picker_main_box">
                    <LocalizationProvider adapterLocale={langId == "en" ? "en" : "ar-SA"} dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateTimePicker"]}>
                            <DateTimePicker
                                label={locationText?.date_time_picker}
                                value={dayjs(selectedDateTime)}
                                disabled={dateTimeDisableHandler()}
                                onAccept={handleDateTimeChange}
                                onChange={(e) => DateTimeChangeHandler(e)}
                                defaultValue={dayjs(minDateTimePick)}
                                minDate={dayjs(minDateTimePick)}
                                minTime={day != "Friday" ? changeDateNotFridayTimePickHander() : changeDateFridayTimePickHander()}
                                maxTime={dayjs(minDateTimePick).set('hour', 22).set('minute', 0)}
                                closeOnSelect={false}
                                onError={(newError) => setErrors(newError)}
                                slotProps={{
                                    textField: {
                                        helperText: errorMessage,
                                    },
                                    actionBar: {
                                        actions: ['cancel', 'accept'],
                                    },
                                }}
                                ampm={true}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </Box>

                {loader2 ?
                    <Box component="div" className="location_boxes pickup_tab" onTouchMove={(e) => (next < newSlot?.length && newSlot?.length == getStore.length) ? Mouse(e) : ""} onWheel={(e) => (next < newSlot?.length && newSlot?.length == getStore.length) ? wheel(e) : ""}>

                        {!loader && newSlot?.length != 0 ? (
                            newSlot?.length > 0 ? (
                                newSlot?.slice(0, next)?.map((val, i) => {
                                    var km = DistanceCovered(val?.Latitude, val?.Longitude);
                                    {
                                        return !val?.isFully ? (
                                            newSlot?.length != getStore.length ?
                                                <Box component="div" className="boxes" key={i} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '180px', width: '-webkit-fill-available' }}>
                                                    <CircularProgress style={{ width: "20px", height: "20px" }} />
                                                </Box>
                                                :
                                                <Box component="div" className="boxes" key={i}>
                                                    <Typography component="h2" variant="h2">
                                                        {val?.branch_name}
                                                    </Typography>
                                                    <Box component="div" className="text_with_icon">
                                                        <Box component="div" className="icon">
                                                            <img src="images/distance_icon.svg" />
                                                        </Box>
                                                        <Typography component="h5" variant="h5">
                                                            {locationText?.Available}
                                                        </Typography>
                                                    </Box>
                                                    <Box component="div" className="text_with_icon">
                                                        <Box component="div" className="icon">
                                                            <img src="images/watch_icon.svg" />
                                                        </Box>
                                                        <Box component="div" className="text">
                                                            <Typography component="p" variant="p">
                                                                {lngLat != null ? km : 0} {locationText?.km}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button onClick={(e) => clickLocation(e, val)}>
                                                        {locationText?.book_now}
                                                    </Button>
                                                </Box>
                                        ) : (
                                            newSlot?.length != getStore.length ?
                                                <Box component="div" className="boxes" key={i} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '180px', width: '-webkit-fill-available' }}>
                                                    <CircularProgress style={{ width: "20px", height: "20px" }} />
                                                </Box>
                                                :
                                                <Box component="div" className="boxes" key={i}>
                                                    <Typography component="h2" variant="h2">
                                                        {val?.branch_name}
                                                    </Typography>
                                                    <Box component="div" className="text_with_icon">
                                                        <Box component="div" className="icon">
                                                            <img src="images/distance_icon.svg" />
                                                        </Box>
                                                        <Box component="div" className="text">
                                                            <Typography component="h5" variant="h5">
                                                                {locationText?.fully_booked}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box component="div" className="text_with_icon">
                                                        <Box component="div" className="icon">
                                                            <img src="images/watch_icon.svg" />
                                                        </Box>
                                                        <Box component="div" className="text">
                                                            <Typography component="p" variant="p">
                                                                {lngLat != null ? km : 0} {locationText?.km}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <ChooseLocationModal StoreFetch={newStoreList} setAvailableSlot={setAvailableSlot} setBookedSlot={setBookedSlot} CategoryId2={CategoryId2} />

                                                </Box>
                                        )
                                    }
                                })
                            ) : (
                                <>
                                    <Typography>{langId == "en" ?  "No Store Found" : "لم يتم العثور على متجر"}</Typography>
                                </>
                            )
                        ) : (
                            getError && <Typography style={{ color: 'red' }}>{langId == "en" ?  "Oops, something went wrong. Please try again" : "تبا شيء ما حدث بشكل خاطئ. حاول مرة اخرى"}</Typography>
                        )}
                        {(!loader && next <= newSlot?.length) && getLoadVAl ?
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "5vh",
                                    width: "-webkit-fill-available",
                                    minWidth: "100%"

                                }}
                            >
                                <CircularProgress />
                            </Box>
                            : ""
                        }
                    </Box>
                    :
                    <Box component="div" className="location_boxes pickup_tab_tab" >
                    </Box>

                }
            </TabPanel >
        </Box >
    );
}
