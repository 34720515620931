export const SignIn_START = "SignIn_START";
export const SignIn_SUCCESS = "SignIn_SUCCESS";
export const SignIn_FAIL = "SignIn_FAIL";

export const GetAuthUser_START = "GetAuthUser_START";
export const GetAuthUser_SUCCESS = "GetAuthUser_SUCCESS";
export const GetAuthUser_FAIL = "GetAuthUser_FAIL";

export const SignUp_START = "SignUp_START";
export const SignUp_SUCCESS = "SignUp_SUCCESS";
export const SignUp_FAIL = "SignUp_FAIL";

export const ForgotPassword_START = "ForgotPassword_START";
export const ForgotPassword_SUCCESS = "ForgotPassword_SUCCESS";
export const ForgotPassword_FAIL = "ForgotPassword_FAIL";

export const ResetPassword_START = "ResetPassword_START";
export const ResetPassword_SUCCESS = "ResetPassword_SUCCESS";
export const ResetPassword_FAIL = "ResetPassword_FAIL";

export const LANG_GET = "LANG_GET";
export const LANG_FAILED = "LANG_FAILED";
export const LANG_SUCCESS = "LANG_SUCCESS";