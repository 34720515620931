export const GET_CATEGORY_START = "GET_CATEGORY_START";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const GET_CATEGORY_CAKES_START = "GET_CATEGORY_CAKES_START";
export const GET_CATEGORY_CAKES_SUCCESS = "GET_CATEGORY_CAKES_SUCCESS";
export const GET_CATEGORY_CAKES_FAIL = "GET_CATEGORY_CAKES_FAIL";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL";

export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const GET_STORES_DISTANCE_START = "GET_STORES_DISTANCE_START";
export const GET_STORES_DISTANCE_SUCCESS = "GET_STORES_DISTANCE_SUCCESS";
export const GET_STORES_DISTANCE_FAIL = "GET_STORES_DISTANCE_FAIL";