import axios from "axios";
import { API_URL, MunchBakeryApiUrl } from "./../global"

const get_category = (lang, guid) => {
  const langId = lang == 'ar' ? 0 : 1;
  return axios.get(`${API_URL}/get-categories?langId=${langId}&guid=${guid}`, {
    headers: {
    //   'Cookie': 'ASP.NET_SessionId=ocwv0fu0kxr2oyhk2hc5nn5r'
    }
  });
};

// const get_category_cakes = (lang, categoryId) => {
//   const langId = lang == 'ar' ? 0 : 1;
//   return axios.get(`${API_URL}/get-categories-cake?langId=${langId}&categoryId=${categoryId}`, {
//     headers: {
//       'Cookie': 'ASP.NET_SessionId=ocwv0fu0kxr2oyhk2hc5nn5r'
//     }
//   });
// };

const get_category_cakes = (lang, categoryId, custtoken) => {
  if(categoryId != 57) {

      const langId = lang == 'ar' ? 0 : 1;

      const header = {
        headers: {
          "Authorization": `basic ${custtoken}`,
        //   'Cookie': 'ASP.NET_SessionId=1n2nhfybyxamymvrmvtnp3hd'

        }
      }
      return axios.get(`${MunchBakeryApiUrl}/GetMunchBakeryProducts/${langId}/${categoryId}/-1/-1/-1`, header);
  } else {

    const form_data = {
        category: "decorated_cupcake",
    }
    return  axios.post(`${API_URL}/collection-fetch`, form_data);
  }
};


const get_stores_distance = (lng, lat, lngId, date, custGuid, custtoken, custId, cateId) => {
  const langId = lngId == 'ar' ? 0 : 1;
  const data = {
    lng: lng,
    lat: lat,
    lngId: langId,
    date: date,
    token: custtoken,
    custId: custId,
    custGuid: custGuid,
    cateId: cateId
  };

  return axios.post(
    API_URL + "/get-stores-distance",
    data
  );
};

export default {
  get_category_cakes,
  get_category,
  get_stores_distance
};
