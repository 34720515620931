import React, { useState, useEffect } from 'react';
import axios from "axios";
import CryptoJS from "crypto-js";
import moment from "moment";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import CircularProgress from "@mui/material/CircularProgress";
import { TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton, Radio } from '@mui/material';
import { API_URL, MunchBakeryApiUrl } from "../../global.js";
import { ExecutePaymentGateway, GetPaymentStatusGateway, InitiateSessionGateway, sendPaymentGateway } from "../../actions/payment_gateways.js";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { TrackGoogleAnalyticsEvent } from '../../utils/google-analytics/events.js';
import { TrackGoogleTagManagerEvent } from '../../utils/google-tag-manager/events.js';





export default function CheckoutPayDrawer({ totalPrice, getCartDetail, custtoken, characterPrice, OrderValue, GetCategoryId, categoryNameHandle, setLayerValue, getFlavourMax, getText, setText, setShoppingDetails, ShoppingDetails, getProductData, getPaidCharacter, ValCal, getFreeCharacter, getStryoBaseLargeId, getStryoBaseSmallId, getTrueState }) {
    const lang = useSelector((state) => state);
    const navigate = useNavigate();
    const langId = lang?.Lang?.data?.lang ? lang?.Lang?.data?.lang : '';
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';


    const [getCouponParam, setCouponParam] = useState(false);
    const [getPhoneNumber, setPhoneNumber] = useState(localStorage.getItem("cusMob") ? JSON.parse(localStorage.getItem("cusMob")) : "");
    const [getDesc, setDesc] = useState(localStorage.getItem("customize") ? JSON.parse(localStorage.getItem("customize")) : "");
    const [customerDetails, setCustomers] = useState(localStorage.getItem("customer_details") ? JSON.parse(localStorage.getItem("customer_details")) : "");
    const [OrderPayloadState, setOrderPayloadState] = useState(localStorage.getItem("OrderPayload") ? JSON.parse(localStorage.getItem("OrderPayload")) : "");
    const [getCounteryCode, setCounteryCode] = React.useState(localStorage.getItem("cusCounteryCode") ? JSON.parse(localStorage.getItem("cusCounteryCode")) : "");

    const [opened, setOpened] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('xs');
    const [getCustomText, setCustomText] = useState('');

    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = useState(user);
    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const custId = store1[0]?.cus_id ? store1[0]?.cus_id : "";
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [loader, setLoader] = useState(false);
    const [OpenPaymentPortal, setPaymentPortal] = useState(false);
    const [getPaymentMethods, setPaymentMethodState] = useState([]);
    const [getLoaderState, setLoaderState] = useState(true);

    const [selectedValue, setSelectedValue] = useState("");
    const [getDisableLoader, setDisableLoader] = useState(false);



    // console.log(selectedValue, "selectedValue")
    const mouseDown = (event) => {
        let startY = 0;
        let isDragging = false;

        const handleSliderDrag = (event) => {

            if (isDragging && event.clientY > startY) {
                // Dragging from top to bottom
                setState((prevState) => ({
                    ...prevState,
                    bottom: false,
                }));
            }
        };

        const handleSliderTouchMove = (event) => {
            if (event.touches != undefined) {
                if (isDragging && event.touches[0]?.clientY > startY) {
                    // Sliding from top to bottom
                    setState((prevState) => ({
                        ...prevState,
                        bottom: false,
                    }));
                }
            }

        };

        const handleSliderTouchStart = (event) => {
            if (event.touches != undefined) {
                startY = event.touches[0]?.clientY;
            } else {
                startY = 0;
            }

            isDragging = true;
        };

        const handleSliderTouchEnd = () => {
            isDragging = false;
        };

        document.addEventListener('mousemove', handleSliderDrag);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', handleSliderDrag);
        });

        document.addEventListener('touchmove', handleSliderTouchMove, { passive: false });
        document.addEventListener('touchend', handleSliderTouchEnd);

        handleSliderTouchStart(event);
    };

    const handleSliderTouchStart = (event) => {
        setState((prevState) => ({
            ...prevState,
            bottom: false,
        }));
        let startY;
        let isDragging;
        if (event.touches != undefined) {
            startY = event.touches[0]?.clientY || 0;
            isDragging = true;
        }

    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {

            if (GetCategoryId != 57) {
                TrackGoogleAnalyticsEvent("Confirm button", "add to cart", "add to cart", 2);
                TrackGoogleTagManagerEvent('add to cart', 'Confrim button', 2);
            }
        }

        setState({ ...state, [anchor]: open });
    };

    const [htmlDir, setHtmlDir] = useState('');

    useEffect(() => {
        setHtmlDir(document.documentElement.getAttribute('dir'));
    }, []);

    const applyCouponCode = async (val) => {

        TrackGoogleAnalyticsEvent("Apply button", "apply discount", "apply discount", 3);
        TrackGoogleTagManagerEvent("apply discount", "Apply button", 3)

        if (!getText && val == "1") {
            return handleTextButton(2);
        } else {

            let language = langId == 'en' ? '1' : '0';

            const data = {
                CustomerGuid: custGuid,
                CouponCode: getText ? getText : "",
                IsApplyCopoun: val,
                CustomerId: custId ? custId : "",
                OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
                StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
                langId: language,
                CityId: "-1",
                DistrictId: "-1",
                ZoneId: "-1",
                OrderType: "-1"
            }

            try {

                const header = {
                    headers: {
                        "Authorization": `basic ${custtoken}`,
                        "Content-Type": "application/json"
                    }
                }
                const response = await axios.post(`${MunchBakeryApiUrl}/ApplyOrRevokeMunchCouponCode/`, data, header);
                if (response?.data?.ApplyOrRevokeMunchCouponCodeResult?.Status == "ERROR") {
                    return handleTextButton(3, response?.data?.ApplyOrRevokeMunchCouponCodeResult?.ErrorResponse?.ErrorMessage);
                }
                if (val == "1") {
                    setCouponParam(true);
                    localStorage.setItem("coupon_submit", JSON.stringify({ text: data?.CouponCode, value: 1 }));
                } else {
                    localStorage.setItem("coupon_submit", JSON.stringify({ text: data?.CouponCode, value: 0 }));
                    setText("");
                    setCouponParam(false);
                }

                await getShopGetShoppingCart();

            } catch (error) {
                console.log("err", error);
                return alert(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
            }

        }
    }


    useEffect(() => {
        if (lang?.Lang?.data?.lang) {
            if (getTrueState) {
                getShopGetShoppingCart();
            }
        }
    }, [getTrueState])

    const getShopGetShoppingCart = async () => {
        let language = langId == 'en' ? '1' : '0';

        const data = {
            CustomerGuid: custGuid,
            CustomerId: custId ? custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: language,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            if (response?.data?.GetShoppingCartSummaryResult) {
                setShoppingDetails(response?.data?.GetShoppingCartSummaryResult);

            }

        } catch (error) {
            console.log("err", error);
            return alert(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }

    }

    const setIds = () => {
        if (GetCategoryId == 57) {
            if (getProductData?.Quantity && (!getStryoBaseLargeId?.Quantity && !getStryoBaseSmallId?.Quantity)) {
                return [getProductData?.ProductId];
            } else if (getStryoBaseLargeId?.Quantity && (!getProductData?.Quantity && !getStryoBaseSmallId?.Quantity)) {
                return [getStryoBaseLargeId?.ProductId];
            } else if (getStryoBaseSmallId?.Quantity && (!getProductData?.Quantity && !getStryoBaseLargeId?.Quantity)) {
                return [getStryoBaseSmallId?.ProductId];
            } else if (getProductData?.Quantity && getStryoBaseLargeId?.Quantity && getStryoBaseSmallId?.Quantity) {
                return [getProductData?.ProductId, getStryoBaseLargeId?.ProductId, getStryoBaseSmallId?.ProductId];
            } else if ((getStryoBaseLargeId?.Quantity && getProductData?.Quantity) && !getStryoBaseSmallId?.Quantity) {
                return [getProductData?.ProductId, getStryoBaseLargeId?.ProductId];
            } else if ((getStryoBaseSmallId?.Quantity && getProductData?.Quantity) && !getStryoBaseLargeId?.Quantity) {
                return [getProductData?.ProductId, getStryoBaseSmallId?.ProductId];
            } else if (!getProductData?.Quantity && getStryoBaseLargeId?.Quantity && getStryoBaseSmallId?.Quantity) {
                return [getStryoBaseLargeId?.ProductId, getStryoBaseSmallId?.ProductId];
            } else {
                return [];
            }
        } else if (GetCategoryId == 81) {
            if ((getProductData?.Quantity && getPaidCharacter?.Quantity) && !getFreeCharacter?.Quantity) {
                return [getPaidCharacter?.ProductId, getProductData?.ProductId];
            } else if ((getProductData?.Quantity && getFreeCharacter?.Quantity) && !getPaidCharacter?.Quantity) {
                return [getFreeCharacter?.ProductId, getProductData?.ProductId];
            } else if (getProductData?.Quantity && getPaidCharacter?.Quantity && getFreeCharacter?.Quantity) {
                return [getPaidCharacter?.ProductId, getFreeCharacter?.ProductId, getProductData?.ProductId];
            } else {
                return [];
            }
        } else {
            if (getProductData?.Quantity) {
                return [getProductData?.ProductId];
            } else {
                return [];
            }
        }
    }



    const handleTextButton = (val, message = "") => {
        if (val === 1) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Price should be greater than 0.') : setCustomText('يجب أن يكون السعر أكبر من 0')
        } else if (val === 2) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Coupon Code is required.') : setCustomText('رمز القسيمة مطلوب')
        } else if (val === 3) {
            setOpened(true);
            return setCustomText(message);
        } else if (val === 4) {
            setOpened(true);
            return langId == 'en' ? setCustomText("Please select any payment method from given options.") : setCustomText("يرجى تحديد أي طريقة دفع من الخيارات المحددة.");
        } else if (val === 5) {
            setOpened(true);
            return setCustomText(message);
        } else {
            return setOpened(false);
        }
    }

    const changeOrderDateFormat = () => {
        let order_date = moment(getCartDetail?.OrderDate).format("MM/DD/YYYY");


        let changeToAR = new Date(order_date);
        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).format(changeToAR);

        return langId == "en" ? order_date : In_arabic;
    }

    const timeOrderHandler = () => {

        let changeToAR = new Date(`${getCartDetail?.OrderDate} ${getCartDetail?.OrderTime}`);

        let In_arabic = new Intl.DateTimeFormat("ar-EG", {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).format(changeToAR);

        return langId == "en" ? moment(`${getCartDetail?.OrderDate} ${getCartDetail?.OrderTime}`).format("hh:mm A") : In_arabic;
    }

    useEffect(() => {
        setPaymentPortal(false)
        localStorage.removeItem("ExeDetails");
    }, [])

    const createOrderWithSMS = async () => {

        // Send a custom event
        TrackGoogleAnalyticsEvent("create order button", "Create the order", "Create the order", 4);
        TrackGoogleTagManagerEvent("Create the order", "create order button", 4);

        if (ShoppingDetails?.OrderTotal > 0) {

            if (!selectedValue) {
                return handleTextButton(4);
            } else {
                setLoader(true);

                let langName = lang.Lang.data?.lang;
                const langId = langName == 'ar' ? "0" : "1";

                const data = {
                    CustomerId: custId,
                    CustomerGuid: custGuid,
                    OrderTotalExcludeTax: ShoppingDetails?.OrderTotal ? (ShoppingDetails?.OrderTotal - ValCal())?.toFixed(2) : 0,
                    OrderTotal: ShoppingDetails?.OrderTotal,
                    VatAmount: ValCal(),
                    DeliveryAmount: ShoppingDetails?.DeliveryFees,
                    DiscountAmount: ShoppingDetails?.DiscountAmount,
                    OrderTypeId: 15,
                    PaymentTypeId: 6,
                    DeliveryDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
                    DeliveryTime: getCartDetail?.OrderTime ? getCartDetail?.OrderTime : "",
                    TimeSlotsValue: `${getCartDetail?.val?.timeSlot[0]?.FromTime} to ${getCartDetail?.val?.timeSlot[0]?.ToTime}`,
                    StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
                    StateId: getCartDetail?.val?.StateId ? getCartDetail?.val?.StateId : "",
                    DistrictId: "-1",
                    StockZoneId: "2",
                    LanguageId: langId,
                    CustomerIP: "-1",
                    LstInStockProductIds: setIds(),
                    AddressId: getCartDetail?.val?.branch_id ? getCartDetail?.val?.branch_id : "",
                    AddressName: getCartDetail?.val?.branch_name ? getCartDetail?.val?.branch_name : "",
                    Latitude: getCartDetail?.val?.Latitude ? getCartDetail?.val?.Latitude : "",
                    Longitude: getCartDetail?.val?.Longitude ? getCartDetail?.val?.Longitude : "",
                    IsSaveAddressForLater: false,
                    LoyalityPoints: 0,
                    LoyalityAmount: 0,
                    CustomerMobileNumber: ((getPhoneNumber?.phone).trim()).length < 10 ? `0${getPhoneNumber?.phone}` : getPhoneNumber?.phone,
                    CustomerPinCard: null,
                    OrderDescription: getDesc ? getDesc?.text : "",
                    PhotoUrl: "",
                    Remarks: "",
                    IsCurbsidePickup: "0",
                    CurbSideNotes: "",
                    OrderSourceTypeId: 1
                }

                const header = {
                    headers: {
                        "Authorization": `basic ${custtoken}`,
                        "Content-Type": "application/json"
                    }
                }

                try {
                    const response = await axios.post(`${MunchBakeryApiUrl}/CreateOrderMunchBakery/`, data, header);
                    if (response?.data?.Status == "ERROR") {
                        console.log("err", response?.data);
                        alert(response?.data?.ErrorResponse?.ErrorMessage);
                        window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                        window.location.replace("/");
                        // navigate("/", {replace:true});
                        setLoader(false);

                        return;
                    } else {
                        let orderNumber = response?.data?.SuccessResponse;
                        let path = '/thankyouPage';
                        let state = { order_id: orderNumber, categoryNameHandle: categoryNameHandle(), setLayerValue: setLayerValue(), getFlavourMax: getFlavourMax, totalPrice: totalPrice(), getCartDetail: getCartDetail, GetCategoryId: GetCategoryId, ShoppingDetails: ShoppingDetails, getProductData: getProductData, getPaidCharacter: getPaidCharacter };
                        if (selectedValue == 2) {
                            state["payment_method"] = selectedValue;
                            await paymentLinkHandler(path, state);
                        } else {
                            localStorage?.setItem("OrderPayload", JSON.stringify({ orders: data, token: custtoken, state }));
                            await sendPaymentHandler(orderNumber, data?.CustomerId);
                        }
                    }

                } catch (error) {
                    console.log({ "err": error });
                }
            }
        } else {
            return handleTextButton(1);
        }
    }



    const createOrderWithPayment = async (newValue = "", number = "", shop_detail = "", code = "", mob_number = "", customer_detail = "", order_Payloads = "", language = "", order_details = "", cart_details = "") => {

        // Send a custom event
        // TrackGoogleAnalyticsEvent("Create the order", "Event", "create order button", 4);
        TrackGoogleAnalyticsEvent("create order button", "Create the order", "Create the order", 4);
        TrackGoogleTagManagerEvent("Create the order", "create order button", 4);

        if (order_details?.orders?.OrderTotal > 0) {



            const data = order_details?.orders;

            // const data = {
            //     CustomerId: custId,
            //     CustomerGuid: custGuid,
            //     OrderTotalExcludeTax: ShoppingDetails?.OrderTotal ? (ShoppingDetails?.OrderTotal - ValCal())?.toFixed(2) : 0,
            //     OrderTotal: ShoppingDetails?.OrderTotal,
            //     VatAmount: ValCal(),
            //     DeliveryAmount: ShoppingDetails?.DeliveryFees,
            //     DiscountAmount: ShoppingDetails?.DiscountAmount,
            //     OrderTypeId: 15,
            //     PaymentTypeId: 6,
            //     DeliveryDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            //     DeliveryTime: getCartDetail?.OrderTime ? getCartDetail?.OrderTime : "",
            //     TimeSlotsValue: `${getCartDetail?.val?.timeSlot[0]?.FromTime} to ${getCartDetail?.val?.timeSlot[0]?.ToTime}`,
            //     StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            //     StateId: getCartDetail?.val?.StateId ? getCartDetail?.val?.StateId : "",
            //     DistrictId: "-1",
            //     StockZoneId: "2",
            //     LanguageId: langId,
            //     CustomerIP: "-1",
            //     LstInStockProductIds: setIds(),
            //     AddressId: getCartDetail?.val?.branch_id ? getCartDetail?.val?.branch_id : "",
            //     AddressName: getCartDetail?.val?.branch_name ? getCartDetail?.val?.branch_name : "",
            //     Latitude: getCartDetail?.val?.Latitude ? getCartDetail?.val?.Latitude : "",
            //     Longitude: getCartDetail?.val?.Longitude ? getCartDetail?.val?.Longitude : "",
            //     IsSaveAddressForLater: false,
            //     LoyalityPoints: 0,
            //     LoyalityAmount: 0,
            //     CustomerMobileNumber: ((getPhoneNumber?.phone).trim()).length < 10 ? `0${getPhoneNumber?.phone}` : getPhoneNumber?.phone,
            //     CustomerPinCard: null,
            //     OrderDescription: getDesc ? getDesc?.text : "",
            //     PhotoUrl: "",
            //     Remarks: "",
            //     IsCurbsidePickup: "0",
            //     CurbSideNotes: "",
            //     OrderSourceTypeId: 1
            // }

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            try {
                const response = await axios.post(`${MunchBakeryApiUrl}/CreateOrderMunchBakery/`, data, header);
                if (response?.data?.Status == "ERROR") {
                    console.log("err", response?.data);
                    alert(response?.data?.ErrorResponse?.ErrorMessage);
                    window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
                    window.location.replace("/");
                    // navigate("/", { replace: true });

                    return;
                } else {
                    let orderNumber = response?.data?.SuccessResponse;
                    let path = '/thankyouPage';
                    let state = { order_id: orderNumber, categoryNameHandle: categoryNameHandle(), setLayerValue: setLayerValue(), getFlavourMax: getFlavourMax, totalPrice: totalPrice(), getCartDetail: getCartDetail, GetCategoryId: GetCategoryId, ShoppingDetails: ShoppingDetails, getProductData: getProductData, getPaidCharacter: getPaidCharacter };
                    if (selectedValue == 2) {
                        state["payment_method"] = selectedValue;
                        await paymentLinkHandler(path, state);
                    } else {
                        let order_state = order_details?.state;
                        order_state["order_id"] = orderNumber;
                        localStorage?.setItem("OrderPayload", JSON.stringify({ orders: order_details?.orders, token: custtoken, state: order_state }));

                        await ExecutePaymentGatewayHandler(newValue || '', number || '', shop_detail || '', code || '', mob_number || '', customer_detail || '', order_details || '', language || '', cart_details || '');

                        // await sendPaymentHandler(orderNumber, data?.CustomerId);
                    }
                }

            } catch (error) {
                setDisableLoader(false);
                console.log({ "err": error });
            }

        } else {
            setDisableLoader(false);
            return handleTextButton(1);
        }
    }

    const paymentLinkHandler = async (path, state) => {

        let language = langId == 'ar' ? "0" : "1";

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                OrderNumber: state?.order_id,
                langId: language
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/CreatePaymentForPayTabsWeb/`, data, header);
            if (response?.data?.CreatePaymentForPayTabsWebResult?.Status == "SUCCESS") {
                await GetInfoTextMunchCake(path, state, response?.data?.CreatePaymentForPayTabsWebResult?.SuccessResponse)
            }
        } catch (error) {
            console.log("err", error);
        }

    }

    const GetInfoTextMunchCake = async (path, state, paymentLink) => {

        let language = langId == 'ar' ? "2" : "1";

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            const data = {
                langId: language
            }

            const response = await axios.post(`${MunchBakeryApiUrl}/GetInfoTextMunchCake/`, data, header);
            if (response?.data?.GetInfoTextMunchCakeResult?.length > 0) {
                let TextExist = response?.data?.GetInfoTextMunchCakeResult?.filter((v, i) => v?.Name == "TextforSMS");
                let descript = TextExist[0]?.Description;
                let dynamic_replace = descript?.replace("[order Number]", state?.order_id);
                let new_replace_text = dynamic_replace.replace("[payment link]", paymentLink);
                let convertible_text = await convertToPlain(new_replace_text);
                await new Promise((resolve, reject) => setTimeout(() => resolve()), 1000);
                await sendLinkOnSms(path, state, convertible_text);
            }
        } catch (error) {
            console.log("err", error);
        }

    }

    const convertToPlain = async (html) => {

        // Create a new div element
        let tempDivElement = document.createElement("div");

        // Set the HTML content with the given value
        tempDivElement.innerHTML = html;

        // Retrieve the text property of the element
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }


    const sendLinkOnSms = async (path, state, convertible_text) => {
        let country_code = getCounteryCode?.code;
        let mobile = getPhoneNumber?.phone;
        let mob = mobile.replace(/^0+/, "");
        let number = country_code?.replace(/\s+/g, "").replace(/\+/g, "");
        let new_number = `${number}${mob}`;

        try {

            const header = {
                headers: {
                    "Content-Type": "application/json"
                }
            }

            const data = {
                recipient: new_number,
                body: convertible_text,
                type: "text"
            };


            const response = await axios.post(`${API_URL}/sms-otp`, data, header);
            if (response?.data?.data?.success) {
                setLoader(false);
                navigate(path, { state: state });
                return;
            } else {
                setOpened(true);
                setLoader(false);
                return langId == 'en' ? setCustomText(`${response?.data?.data?.Status}. ${response?.data?.data?.message}`) : setCustomText('لم يتم تسليمها')
            }
        } catch (error) {
            console.log("err", error);
        }

    }










    let shops = [];

    // useEffect(() => {

    //     let session_init = localStorage.getItem("start_session");

    //     return () => {

    //         if (session_init && session_init == "true") {
    //             document.getElementById('create-ID')?.click();
    //             sendPaymentHandler(session_init || '');
    //         }
    //     };
    // }, [])

    const sendPaymentHandler = async () => {

        shops = [];
        localStorage.removeItem("session_details");
        const random_generate = Math.random().toString(36).slice(2).toUpperCase();

        localStorage?.setItem("ShoppingDetails", JSON.stringify(ShoppingDetails));


        let code = "";
        let mob_number = "";
        if (localStorage?.getItem("cusCounteryCode") && localStorage?.getItem("cusMob")) {
            code = JSON.parse(localStorage?.getItem("cusCounteryCode"))?.code;
            mob_number = JSON.parse(localStorage?.getItem("cusMob"))?.phone;
        }

        if (ShoppingDetails && (ShoppingDetails?.LstECommerceShoppingCartItem)?.length > 0) {
            let product_list = ShoppingDetails?.LstECommerceShoppingCartItem;
            product_list?.map((v, i) => {
                let products = {
                    ItemName: v?.ProductName,
                    Quantity: v?.Quantity,
                    UnitPrice: v?.Price
                }
                shops?.push(products);
            });
        }

        let form_data = {
            "NotificationOption": "LNK",
            "CustomerName": customerDetails?.name ? customerDetails?.name : "",
            "DisplayCurrencyIso": "SAR",
            "MobileCountryCode": code?.toString(),
            "CustomerMobile": mob_number?.toString(),
            "CustomerEmail": customerDetails?.email ? customerDetails?.email : "",
            "InvoiceValue": ShoppingDetails?.OrderTotal ? ShoppingDetails?.OrderTotal : "",
            "CallBackUrl": `${API_URL}/PaymentPath`,
            "ErrorUrl": `${API_URL}/PaymentPath`,
            "Language": langId,
            "CustomerReference": random_generate?.toString(),
            "CustomerCivilId": random_generate?.toString(),
            "UserDefinedField": random_generate?.toString(),
            "ExpireDate": new Date(new Date().setHours(new Date().getHours() + 4)),
            // "InvoiceItems": shops
        }

        try {
            const response = await sendPaymentGateway(form_data);
            if (response?.data?.IsSuccess) {
                localStorage.setItem("invoiceId", response?.data?.Data?.InvoiceId);
                await GetPaymentStatusHandler(response?.data?.Data?.InvoiceId, true);
                return;
            } else {
                setLoader(false);
                return alert(response?.data?.ValidationErrors[0]?.Error);
            }

        } catch (error) {
            console.log(error, "error");
            alert(error?.Message);
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            return window?.location?.replace("/");
        }

    }

    const GetPaymentStatusHandler = async (InvoiceId, isExist = false) => {

        let form_data = {
            "Key": InvoiceId?.toString(),
            "KeyType": "InvoiceId"
        }

        try {
            const response = await GetPaymentStatusGateway(form_data);
            if (response?.data?.IsSuccess) {

                let invoice_details = response?.data?.Data;
                if (invoice_details?.InvoiceStatus == "Canceled") {
                    return alert(invoice_details?.InvoiceStatus);
                }
                localStorage.setItem("invoice_details", JSON.stringify(invoice_details));
                if (isExist) {
                    await initiateSessionHandler(invoice_details);
                }

            } else {
                return console.log(response?.data);
            }

        } catch (error) {
            console.log(error, "error");
            alert(error?.response?.data?.error);
        }
    }

    const initiateSessionHandler = async (invoice_detail) => {

        let form_data = {
            "CustomerIdentifier": (invoice_detail?.CustomerReference)?.toString(),
            "SaveToken": false
        }

        try {
            const response = await InitiateSessionGateway(form_data);
            if (response?.data?.IsSuccess) {
                setLoader(false);
                localStorage.setItem("session_details", JSON.stringify(response?.data?.Data));
                localStorage.setItem("random_number", form_data?.CustomerIdentifier);
                await new Promise((resolve, reject) => setTimeout(() => resolve()), 2000);

                let langName = lang.Lang.data?.lang;
                let langId = langName == 'ar' ? "0" : "1";

                const form_handler = {
                    CustomerId: custId,
                    CustomerGuid: custGuid,
                    OrderTotalExcludeTax: ShoppingDetails?.OrderTotal ? (ShoppingDetails?.OrderTotal - ValCal())?.toFixed(2) : 0,
                    OrderTotal: ShoppingDetails?.OrderTotal,
                    VatAmount: ValCal(),
                    DeliveryAmount: ShoppingDetails?.DeliveryFees,
                    DiscountAmount: ShoppingDetails?.DiscountAmount,
                    OrderTypeId: 15,
                    PaymentTypeId: 6,
                    DeliveryDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
                    DeliveryTime: getCartDetail?.OrderTime ? getCartDetail?.OrderTime : "",
                    TimeSlotsValue: `${getCartDetail?.val?.timeSlot[0]?.FromTime} to ${getCartDetail?.val?.timeSlot[0]?.ToTime}`,
                    StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
                    StateId: getCartDetail?.val?.StateId ? getCartDetail?.val?.StateId : "",
                    DistrictId: "-1",
                    StockZoneId: "2",
                    LanguageId: langId,
                    CustomerIP: "-1",
                    LstInStockProductIds: setIds(),
                    AddressId: getCartDetail?.val?.branch_id ? getCartDetail?.val?.branch_id : "",
                    AddressName: getCartDetail?.val?.branch_name ? getCartDetail?.val?.branch_name : "",
                    Latitude: getCartDetail?.val?.Latitude ? getCartDetail?.val?.Latitude : "",
                    Longitude: getCartDetail?.val?.Longitude ? getCartDetail?.val?.Longitude : "",
                    IsSaveAddressForLater: false,
                    LoyalityPoints: 0,
                    LoyalityAmount: 0,
                    CustomerMobileNumber: ((getPhoneNumber?.phone).trim()).length < 10 ? `0${getPhoneNumber?.phone}` : getPhoneNumber?.phone,
                    CustomerPinCard: null,
                    OrderDescription: getDesc ? getDesc?.text : "",
                    PhotoUrl: "",
                    Remarks: "",
                    IsCurbsidePickup: "0",
                    CurbSideNotes: "",
                    OrderSourceTypeId: 1
                }
                let state = { categoryNameHandle: categoryNameHandle(), setLayerValue: setLayerValue(), getFlavourMax: getFlavourMax, totalPrice: totalPrice(), getCartDetail: getCartDetail, GetCategoryId: GetCategoryId, ShoppingDetails: ShoppingDetails, getProductData: getProductData, getPaidCharacter: getPaidCharacter };

                let orders = { orders: form_handler, state: state }
                localStorage.setItem("orderHandler", JSON.stringify(orders));
                setPaymentPortal(true);
                setLoader(false);
                return
            } else {
                setLoader(false);
                handleTextButton(5, response?.data?.Message);
                return;
            }

        } catch (error) {
            setLoader(false);
            console.log(error, "error");
        }
    }

    useEffect(() => {

        if (lang.Lang.data?.lang) {
            window.addEventListener('storage', handleStorageChange);
            paymentMethods();
        }
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        }
    }, [])

    // Function to handle storage events
    const handleStorageChange = async (event) => {
        setDisableLoader(true);

        let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";

        if (transaction_err?.err) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }

        let get_failed_state = localStorage.getItem("push_state") ? localStorage.getItem("push_state") : "";

        if (!get_failed_state) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }

        let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";

        if (get_success_state) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }

        let number = "";
        if (localStorage?.getItem("random_number")) {
            number = localStorage?.getItem("random_number");
        }

        let code = "";
        let mob_number = "";
        if (localStorage?.getItem("cusCounteryCode") && localStorage?.getItem("cusMob")) {
            code = JSON.parse(localStorage?.getItem("cusCounteryCode"))?.code;
            mob_number = JSON.parse(localStorage?.getItem("cusMob"))?.phone;
        }

        let shop_detail = ""
        if (localStorage?.getItem("ShoppingDetails")) {
            shop_detail = JSON.parse(localStorage?.getItem("ShoppingDetails"));
        }

        let customer_detail = ""
        if (localStorage?.getItem("customer_details")) {
            customer_detail = JSON.parse(localStorage?.getItem("customer_details"));
        }

        let order_Payloads = [];
        if (localStorage?.getItem("OrderPayload")) {
            order_Payloads = JSON.parse(localStorage?.getItem("OrderPayload"));
        }

        let language = [];
        if (localStorage?.getItem("lang")) {
            language = localStorage?.getItem("lang");
        }

        let order_details = [];
        if (localStorage?.getItem("orderHandler")) {
            order_details = JSON.parse(localStorage?.getItem("orderHandler"));
        }

        let cart_details = [];
        if (localStorage?.getItem("cart_details")) {
            cart_details = JSON.parse(localStorage?.getItem("cart_details"));
        }



        if (event?.key === 'ExeDetails') {

            if (event?.newValue) {
                await createOrderWithPayment(event?.newValue, number, shop_detail, code, mob_number, customer_detail, order_Payloads, language, order_details, cart_details);

            }

        }
    };

    let shops1 = [];

    const ExecutePaymentGatewayHandler = async (getSessionId, customer_ref, shop_detail, code, mob_number, customer_detail, order_Payloads, language, cart_details) => {
        // shops1 = [];
        // if (shop_detail && (shop_detail?.LstECommerceShoppingCartItem)?.length > 0) {
        //     let product_list = shop_detail?.LstECommerceShoppingCartItem;
        //     product_list?.map((v, i) => {
        //         let products = {
        //             ItemName: v?.ProductName,
        //             Quantity: v?.Quantity,
        //             UnitPrice: v?.Price
        //         }
        //         shops1?.push(products);
        //     });
        // }

        let form_data = {
            "PaymentMethodId": 0,
            "SessionId": JSON.parse(getSessionId)?.sessionId,
            "CustomerName": customer_detail?.name,
            "DisplayCurrencyIso": "SAR",
            "MobileCountryCode": code?.toString(),
            "CustomerMobile": mob_number?.toString(),
            "CustomerEmail": customer_detail?.email,
            "InvoiceValue": shop_detail?.OrderTotal,
            "Language": language,
            "CustomerReference": customer_ref?.toString(),
            "CustomerCivilId": order_Payloads?.orders?.CustomerId ? (order_Payloads?.orders?.CustomerId)?.toString() : customer_ref?.toString(),
            "UserDefinedField": order_Payloads?.state?.order_id ? order_Payloads?.state?.order_id : customer_ref?.toString(),
            "CallBackUrl": `${API_URL}/PaymentPath`,
            "ErrorUrl": `${API_URL}/PaymentPath`,
            "CustomerAddress": {
                "Block": "string",
                "Street": "string",
                "HouseBuildingNo": "string",
                "AddressInstructions": "string"
            },
            "ExpiryDate": new Date(new Date().setDate(new Date().getDate() + 1)),
            // "InvoiceItems": shops1,
            "ProcessingDetails": {
                "AutoCapture": true,
                "Bypass3DS": false
            },
            "Suppliers": [
                {
                    "SupplierCode": cart_details?.val?.PaymentGatewayID ? parseInt(cart_details?.val?.PaymentGatewayID) : "",
                    "ProposedShare": null,
                    "InvoiceShare": shop_detail?.OrderTotal
                }
            ],
        }

        try {
            const response = await ExecutePaymentGateway(form_data);
            if (response?.data?.IsSuccess) {
                localStorage.setItem("invoiceId", response?.data?.Data?.InvoiceId);
                localStorage.setItem("ExecutePaymentPayload", JSON.stringify(form_data));
                localStorage?.setItem("start_session", "false");
                localStorage.removeItem("orderHandler");
                window.open(response?.data?.Data?.PaymentURL, "_self");
                // setDisableLoader(false);
                // setPaymentPortal(false);
                return;
            } else {
                let Bypass3DS_err = response?.data?.ValidationErrors;
                let Bypass3DS_array = Bypass3DS_err?.map((v, i) => v?.Error);
                alert(Bypass3DS_array);
                setDisableLoader(false);

                return;
            }

        } catch (error) {
            console.log("error :::", error);
            toast.error(error?.response?.statusText, { position: toast.POSITION.TOP_LEFT });
            setPaymentPortal(false);
            setDisableLoader(false);
            return


        }
    }


    const paymentMethods = async () => {
        setLoaderState(true);
        try {

            let payload = {
                category: "paymentMethods"
            }

            let response = await axios.post(`${API_URL}/collection-fetch`, payload);
            if (response?.data?.success) {

                let paymentMethodsContainer = response?.data?.data?.documents;
                // let paymentMethodsContainer = [
                //     {
                //         "Name" : "Credit/debit card.",
                //         "Ar Name":"بطاقة الائتمان/مدى",
                //         "id":"1",
                //         "isActive":"1"
                //     },
                //     {
                //         "Name" : "Payment link via SMS ",
                //         "Ar Name":"الدفع برابط الدفع برسالة نصية",
                //         "id":"2",
                //         "isActive":"1"
                //     }
                // ]

                if(paymentMethodsContainer[0]?.isActive == 1) {
                    setSelectedValue(paymentMethodsContainer[0]?.id);
                } else if (paymentMethodsContainer[1]?.isActive == 1) {
                    setSelectedValue(paymentMethodsContainer[1]?.id);
                } else if(paymentMethodsContainer[0]?.isActive == 1 && paymentMethodsContainer[1]?.isActive == 1) {
                    setSelectedValue(paymentMethodsContainer[0]?.id);
                } else if(paymentMethodsContainer[0]?.isActive == 1 && paymentMethodsContainer[1]?.isActive == 0) {
                    setSelectedValue(paymentMethodsContainer[0]?.id);
                } else if (paymentMethodsContainer[0]?.isActive == 0 && paymentMethodsContainer[1]?.isActive == 1) {
                    setSelectedValue(paymentMethodsContainer[1]?.id);
                } else {
                    setSelectedValue("");
                }
                setPaymentMethodState(paymentMethodsContainer);
                setLoaderState(false);
                return

            } else {
                return alert(response?.data?.message?.category[0]);
            }

        } catch (error) {
            console.log("error :::", error);
            return toast.error(error?.response?.statusText, { position: toast.POSITION.TOP_LEFT });
        }
    }

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    }

    const handlerClose = (value) => {
        localStorage?.removeItem("orderHandler");
        return setPaymentPortal(value);
        // return window.location.replace("/");
    }

    const OrderHandler = async () => {


        if (setIds()?.length == 0) {
            alert(langId == "en" ? "There are some items out of stock" : "هناك بعض العناصر من المخزون");
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            return window?.location?.replace("/");
        }

        if (ShoppingDetails?.OrderTotal > 0) {

            if (!selectedValue) {
                return handleTextButton(4);
            } else {
                setLoader(true);
                if (selectedValue == 2) {
                    await createOrderWithSMS();
                } else {
                    await sendPaymentHandler();
                }
            }
        } else {
            return handleTextButton(1);
        }



    }

    console.log(getPaymentMethods, "getPaymentMethods")

    const list = (anchor) => (
        <Box
            className="checkout_drawer"
            role="presentation"
            component="div"
            style={langId == "en" ? { direction: 'ltr' } : { direction: 'rtl' }}
        >
            <ToastContainer />
            <Box component='div' className='checkout_drawer_content pay' >
                <Box onMouseDown={mouseDown} onTouchStart={handleSliderTouchStart}></Box>
                <Box component='div' className='content_main'>
                    <Typography component='h1' variant='h1' className='head_title' onMouseDown={mouseDown} onTouchStart={handleSliderTouchStart}>{flavorText?.checkout_heading}</Typography>
                    <Box component='div' className='inner_box' id='close_drawer'>
                        <Box component='div' className='pickup_details'>
                            <Typography component='span' variant='span'>{flavorText?.picked_at}</Typography>
                            <Typography component='h2' variant='h2'>{getCartDetail?.val?.branch_name ? getCartDetail?.val?.branch_name : ""}</Typography>
                            {/* <Typography component='h2' variant='h2'>Deem Plaza El Hamra, Riyadh</Typography> */}

                        </Box>
                        <Box component='div' className='date_time'>
                            <Typography component='span' variant='span'>{flavorText?.due_date}</Typography>
                            <Typography component='h2' variant='h2'>{getCartDetail?.OrderDate ? changeOrderDateFormat() : ""} {langId == 'en' ? '@' : 'في'} {getCartDetail?.OrderTime ? timeOrderHandler() : ""}</Typography>
                            {/* <Typography component='h2' variant='h2'>22/03/2023 @ 6 PM</Typography> */}

                        </Box>
                        {/* </Box> */}
                        {/* <Box component='div' className='inner_box'>
                        <Box component='div' className='order_for'>
                            <Typography component='h2' variant='h2'>{flavorText?.someone_order}</Typography>

                            <img src='images/left_back_arrow.svg' className={htmlDir + "img"} alt='Left Arrow' />

                        </Box>
                    </Box> */}
                        {/* <Box component='div' className='inner_box promocode_box'> */}
                        <Box component='div' className='promocode'>
                            <TextField className='code_field' disabled={getCouponParam} value={getText} onChange={(e) => setText(e?.target?.value?.trimStart())} placeholder={flavorText?.promocode} />
                            {!getCouponParam ?
                                <Button onClick={() => applyCouponCode("1")}>{flavorText?.apply}</Button>
                                :
                                <Button onClick={() => applyCouponCode("0")}>{flavorText?.remove}</Button>
                            }
                        </Box>
                    </Box>
                    <Box component='div' className='inner_box'>
                        <Box component='div' className='totals_main'>
                            <Typography component='h2' variant='h2'>{flavorText?.total_amount}</Typography>
                            <Typography component='h2' variant='h2'>{flavorText?.currency} {ShoppingDetails?.OrderTotal ? (ShoppingDetails?.OrderTotal - ValCal())?.toFixed(2) : 0}</Typography>

                        </Box>
                        <Box component='div' className='totals_vat'>
                            <Typography component='h2' variant='h2'>{flavorText?.vat} 15%</Typography>
                            <Typography component='h2' variant='h2'>{flavorText?.currency} {ValCal()}</Typography>

                        </Box>
                        <Box component='div' className='vat_details'>
                            <Typography component='span' variant='span'>{flavorText?.registration_no}</Typography>
                            <Typography component='span' variant='span'>{getCartDetail?.val?.VAT ? getCartDetail?.val?.VAT : "300091893700003"}</Typography>
                        </Box>
                    </Box>
                </Box>

                {!getLoaderState && getPaymentMethods?.length > 0 ?

                    getPaymentMethods?.filter(v => v?.isActive == "1")?.map((val, ind) => {
                        return (
                            <Box component='div' className='card_options'>
                                <Box key={ind} component='div' className='cards'>
                                    <Typography component='h2' variant='h2'>{langId == "en" ? val?.Name : val["Ar Name"]}</Typography>

                                    <Radio
                                        checked={selectedValue === val?.id}
                                        onChange={handleChange}
                                        value={val?.id}
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    {/* <Button >
                                        <img src='images/left_back_arrow.svg' className={htmlDir + "img"} alt='Left Arrow' />
                                    </Button> */}
                                </Box>
                            </Box>
                        )
                    })
                    :
                    <>
                        <Box component='div' className='card_options'>
                            <Skeleton animation="wave" width="100%" />
                        </Box>
                        <Box component='div' className='card_options'>
                            <Skeleton animation="wave" width="100%" />
                        </Box>
                    </>
                }
                {/* <Box component='div' className='cards'>
                        <Typography component='h2' variant='h2'>{flavorText?.credit_debit}</Typography>
                        <Button >
                            <img src='images/left_back_arrow.svg' className={htmlDir + "img"} alt='Left Arrow' />
                        </Button>
                    </Box> */}

                <Box component='div' className='step_button_box Confirm_pay'>
                    <Box component='div' className='text'>
                        <Typography component='h4' variant='h4'>{flavorText?.currency} {ShoppingDetails?.OrderTotal ? ShoppingDetails?.OrderTotal : 0}</Typography>
                        <Typography component='p' variant='p'>{flavorText?.vat_included}</Typography>
                    </Box>
                    <Box component='div' className='btn'>
                        {!loader ?
                            <Button className="upload_btn" onClick={() => OrderHandler()}>
                                {flavorText?.create_order}
                            </Button>
                            :
                            <Button className="upload_btn" disabled >
                                <CircularProgress style={{ width: "27px", height: "27px", color: "black" }} />
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>
            <Box
                onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
            </Box>
        </Box>
    );

    return (
        <React.Fragment>
            {['bottom'].map((anchor) => (
                <React.Fragment key={anchor}>
                    {ShoppingDetails?.LstECommerceShoppingCartItem?.length > 0 ?
                        ShoppingDetails?.OrderTotal > 0
                            ?
                            <Button onClick={toggleDrawer(anchor, true)} id='create-ID' className="upload_btn" aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                                {flavorText?.confirm}
                            </Button> :
                            <Button onClick={() => handleTextButton(1)} className="upload_btn" aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                                {flavorText?.confirm}
                            </Button>

                        :
                        <Button disabled className="upload_btn" aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                            {flavorText?.confirm}
                        </Button>
                    }
                    <Drawer
                        className="drawer_main_checkout"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>

                    <Dialog
                        open={opened}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                        onClose={() => setOpened(false)}
                        aria-labelledby="responsive-dialog-title"
                        aria-describedby="responsive-dialog-description"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {getCustomText}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="responsive-dialog-description">
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpened(false)} autoFocus>
                                {flavorText?.ok}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={OpenPaymentPortal}
                        fullWidth={fullWidth}
                        maxWidth='xs'
                        // sx={{
                        //     backdropFilter: "blur(5px)",
                        //     //other styles here
                        //   }}

                        // onClose={() => setPaymentPortal(false)}
                        aria-labelledby="responsive-dialog-title"
                        aria-describedby="responsive-dialog-description"
                    >
                        <DialogTitle id="responsive-dialog-title">
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="responsive-dialog-description">
                                <iframe src={`${API_URL}/embedded_payment.html`} width="100%" height="500px" title="Payment Gateway" style={{ border: 'none' }}></iframe>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handlerClose(false)} autoFocus disabled={getDisableLoader}>
                                {flavorText?.cancel}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
