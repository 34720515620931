import { LANG_GET, LANG_FAILED, LANG_SUCCESS } from '../constants/ActionAuthTypes';

const initialState = {
  loading: false,
  data: null,
  error: false,
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case LANG_GET:
      return { ...state, loading: true };
    case LANG_FAILED:
      return { ...state, loading: false, error: true };
    case LANG_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    default:
      return state;
  }
};

export default langReducer;
