import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { sendOtp} from '../../actions/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';

const ResendOTP = (props) => {
    const dispatch = useDispatch();
  const {lang, loader, ResendOtp, setTriggerEffect, triggerEffect} = props;
  const lang1 = useSelector((state) => state);
  const langId = lang1?.Lang?.data?.lang ? lang1?.Lang?.data?.lang : '';
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    // Start the countdown timer
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clean up the timer on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [triggerEffect]);

  const getResendText = () => {
    if (lang && lang.sec_resend_code) {
      return `(${countdown}) ${lang.sec_resend_code}`;
    }
    return '';
  };
//   const resend = (event) => {
//     setLoader(true);
//     dispatch(sendOtp(phoneNumber)).then((res) => {
//         if (res?.data?.success) {
//             setLoader(false);
//             setCountdown(30);
//             setTriggerEffect(true);
//             setTimeout(() => {
//                 setTriggerEffect(false);
//               }, 100);
//         } else {
//             setLoader(false);
//             alert("could not send code")
//         }
//     })
// };

  return (
    <div>
      {countdown > 0 ? (
        getResendText()
      ) : (
        loader ?
           <Button className='resend_otp' ><CircularProgress style={{width: "17px", height: "17px"}} /></Button>
        :
          <Button className='resend_otp' onClick={ResendOtp}>{langId == "en" ? "Resend OTP" : "إعادة إرسال كلمة المرور لمرة واحدة"}</Button>

      )}
    </div>
  );
};

export default ResendOTP;
