import axios from "axios";
import {API_URL} from "./../global"

const search_product = (search_input, lang) => {
  const langId = lang == 'ar' ? 0 : 1;

  return axios.get(`${API_URL}/search-product?search_key=${search_input}&langId=${langId}`,
   {
    headers: {
      'Cookie': 'ASP.NET_SessionId=ocwv0fu0kxr2oyhk2hc5nn5r'
    }
  });
};


export default {
    search_product
};
