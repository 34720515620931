import * as React from 'react';
import { useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import axios from 'axios';
import { API_URL, MunchBakeryApiUrl } from '../../global';
import CryptoJS from "crypto-js";
import CheckoutDrawer from '../../components/CheckoutDrawer'
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from "react-toastify";

import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import { TrackGoogleTagManagerPageView } from '../../utils/google-tag-manager/events';



const PersonsSlider = styled(Slider)({
    color: '#A3A3A3',
    height: 6,
    padding: '16px 0',
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        color: '#E9E9E9',
        opacity: 1,
        borderRadius: '0',
    },
    '& .MuiSlider-thumb': {
        height: 30,
        width: 30,
        backgroundColor: '#F8B5C4',
        backgroundImage: "url('images/bars_icn.svg')",
        backgroundSize: '44%',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        boxShadow: '5px 6px 14px 2px rgba(0, 0, 0, 0.25) !important',
        border: 'none',
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: '19px',
        fontSize: '14px',
        fontWeight: '800',
        color: '#000000',
        fontFamily: 'Open Sans',
        background: 'none',
        padding: 0,
        width: 0,
        height: 0,
        paddingBottom: '6px',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(0deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(0deg) scale(1)',
        },
    },
});


export default function CustomizeNext() {
    const lang = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const langId = lang?.Lang?.data?.lang ? lang?.Lang?.data?.lang : '';
    const flavorText = lang.Lang.data?.flavor ? lang.Lang.data.flavor : '';


    const [opened, setOpened] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('xs');
    const [getCustomText, setCustomText] = React.useState('');


    const [GetCategoryId, setCategoryId] = React.useState(localStorage.getItem("categoryId") ? JSON.parse(localStorage.getItem("categoryId"))?.Id : "");
    const [getSlidersData, setSlidersData] = React.useState(localStorage.getItem("sliders") ? JSON.parse(localStorage.getItem("sliders")) : "");
    const [getOthersData, setOthersData] = React.useState(localStorage.getItem("Others") ? JSON.parse(localStorage.getItem("Others")) : "");
    const [getCartDetail, setCartDetail] = React.useState(localStorage.getItem("cart_details") ? JSON.parse(localStorage.getItem("cart_details")) : "");
    const [productDetail, setProductDetail] = React.useState(localStorage.getItem("ProductDetails") ? JSON.parse(localStorage.getItem("ProductDetails")) : "");

    const [persons, setPerson] = React.useState(0);
    const [cakeImage, setCakeImage] = React.useState(GetCategoryId == 57 ? 'images/cupcake.png' : ((GetCategoryId == 20 || GetCategoryId == 21) ? 'images/no_objects.png' : 'images/cake_next_step.png'));
    const [objects, setObjects] = React.useState(1);
    const [layer, setLayer] = React.useState(1);
    const [cakeAmount, setCakeAmount] = React.useState(0);
    const [maxLayers, setMaxLayers] = React.useState(0);
    const [maxPerson, setmaxPerson] = React.useState(0);
    const [maxObjects, setMaxObjects] = React.useState(0);
    const encryptedUser = localStorage.getItem("user")
        ? CryptoJS.AES.decrypt(localStorage.getItem("user"), "secretKey123").toString(
            CryptoJS.enc.Utf8
        )
        : null;
    const user = JSON.parse(encryptedUser);
    const store1 = React.useState(user);

    const CakeCustomizeText = lang.Lang.data?.persons ? lang.Lang.data.persons : '';
    const CupCakeText = lang.Lang.data?.cupcakes ? lang.Lang.data.cupcakes : '';

    const custGuid = lang?.auth?.user?.cust_guid || store1[0]?.cust_guid;
    const custId = store1[0]?.cus_id ? store1[0]?.cus_id : ""
    const custtoken = lang?.auth?.user?.cus_to || store1[0]?.cus_to;


    const [CupCake, setCupCake] = React.useState(0);
    const [styroBaseSmall, setStyroBaseSmall] = React.useState(0);
    const [styroBaseBig, setStyroBaseBig] = React.useState(0);
    const [maxCupCake, setMaxCupCake] = React.useState(500);
    const [maxStyroBaseSmall, setMaxStyroBaseSmall] = React.useState(4);
    const [maxStyroBaseBig, setMaxStyroBaseBig] = React.useState(4);
    const [cupCakeAmount, setCupCakeAmount] = React.useState(0);
    const [ChargeableChar, setChargeableChar] = React.useState(0);
    const [paidCharacter, setPaidCharacter] = React.useState(0);
    // const [getPrice, setPrice] = React.useState(productDetail?.Price ? productDetail?.Price : 0);
    const [ShopDetail, setShopDetail] = React.useState([]);
    const [getText, setText] = React.useState("");
    const [ShoppingDetails, setShoppingDetails] = React.useState(localStorage.getItem("ShoppingDetails") ? JSON.parse(localStorage.getItem("ShoppingDetails")) : []);

    const [photoOcassionPersons, setPhotoOccassionPersion] = React.useState(0);
    const [maxPhotoOccassionPersion, setmaxPhotoOccassionPersion] = React.useState(0);
    const [photoCakeAmount, setPhotoCakeAmount] = React.useState(0);

    const [frostingPersons, setFrostingPersion] = React.useState(0);
    const [maxFrostingPersion, setmaxFrostingPersion] = React.useState(0);
    const [frostingCakeAmount, setFrostingCakeAmount] = React.useState(0);

    const [getThisValue, setThisValue] = React.useState(true);
    const [getButtonLoader, setButtonLoader] = React.useState(false);
    // const [getDecoratedPrice, setDecoratedPrice] = React.useState({});
    const [getStyroSmallPrice, setStyroSmallPrice] = React.useState({});
    const [getStyroBigPrice, setStyroBigPrice] = React.useState({});
    const [isLoader, setIsLoader] = React.useState(true);


    // const getPrice = getDecoratedPrice?.Price_in_SAR;
    const getPrice = productDetail?.Price;

    const StyroSmallPrice_in_SAR = getStyroSmallPrice?.Price_in_SAR;
    const StyroLargePrice_in_SAR = getStyroBigPrice?.Price_in_SAR;



    const [getFrostingArray, setFrostingArray] = React.useState([]);
    const [getPhotoOccassionArray, setPhotoOccassionArray] = React.useState([]);
    const [get3DcakeArray, set3DcakeArray] = React.useState([]);



    const [priceLoader, setPriceLoader] = React.useState(true);


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [cup_cake, setCupDetails] = React.useState({ CupCake: CupCake, styroBaseSmall: styroBaseSmall, styroBaseBig: styroBaseBig, cupCakeAmount: cupCakeAmount ? cupCakeAmount?.toFixed(2) : 0, StryoBaseLargeId: 1689, StryoBaseSmallId: 1688 })





    const handleChange = (value, item) => {

        if (get3DcakeArray?.length > 0) {

            let _3DcakeObject = get3DcakeArray?.filter((v) => item == "persons" ? v?.No_of_Persons == value : v?.No_of_Persons == persons)?.[0];

            let cakePricePerPerson = 0;
            let chargeable_characters = 0;
            if (value >= 5 && value < 10 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(20);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 10 && value < 15 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(25);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 15 && value < 20 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(30);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 20 && value < 25 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(35);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 25 && value < 30 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(40);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 30 && value < 35 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(45);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 35 && value < 40 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(50);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 40 && value < 45 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(60);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 45 && value < 50 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(65);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 50 && value < 55 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(70);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 55 && value < 60 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(75);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 60 && value < 70 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(80);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 70 && value < 80 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(90);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 80 && value < 90 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(100);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 90 && value < 100 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(110);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 100 && value < 110 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setmaxPerson(120);
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 110 && value < 120 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(130);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 120 && value < 130 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(140);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 130 && value < 140 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(150);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 140 && value < 150 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(160);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 150 && value < 160 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(180);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 160 && value < 170 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(190);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 170 && value < 180 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(200);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 180 && value < 190 && item == "persons") {

                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(200);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 190 && value < 200 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(200);
                setCakeAmount(cakePricePerPerson)
            } else if (value >= 200 && item == "persons") {
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons;
                setMaxLayers(_3DcakeObject?.Max_No_of_Layers);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                setmaxPerson(200);
                setCakeAmount(cakePricePerPerson)
            }


            if (persons >= 5 && persons < 10 && item == "objects") {
                let paidObjects = value > 0 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 0 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 10 && persons < 15 && item == "objects") {
                let paidObjects = value > 1 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 1 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 15 && persons < 20 && item == "objects") {
                let paidObjects = value > 2 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 2 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 20 && persons < 25 && item == "objects") {
                let paidObjects = value > 2 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 2 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 25 && persons < 30 && item == "objects") {
                let paidObjects = value > 3 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 3 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 30 && persons < 35 && item == "objects") {
                let paidObjects = value > 3 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 3 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 35 && persons < 40 && item == "objects") {
                let paidObjects = value > 4 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 4 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 40 && persons < 45 && item == "objects") {
                let paidObjects = value > 4 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 4 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 45 && persons < 50 && item == "objects") {
                let paidObjects = value > 5 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 5 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 50 && persons < 55 && item == "objects") {
                let paidObjects = value > 5 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 5 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 55 && persons < 60 && item == "objects") {
                let paidObjects = value > 5 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 5 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 60 && persons < 70 && item == "objects") {
                let paidObjects = value > 6 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 6 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 70 && persons < 80 && item == "objects") {
                let paidObjects = value > 6 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 6 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 80 && persons < 90 && item == "objects") {
                let paidObjects = value > 7 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 7 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 90 && persons < 100 && item == "objects") {
                let paidObjects = value > 7 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 7 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 100 && persons < 110 && item == "objects") {
                let paidObjects = value > 9 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 9 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 110 && persons < 120 && item == "objects") {
                let paidObjects = value > 9 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 9 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 120 && persons < 130 && item == "objects") {
                let paidObjects = value > 10 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 10 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 130 && persons < 140 && item == "objects") {
                let paidObjects = value > 10 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 10 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 140 && persons < 150 && item == "objects") {
                let paidObjects = value > 10 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 10 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 150 && persons < 160 && item == "objects") {
                let paidObjects = value > 12 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 12 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 160 && persons < 170 && item == "objects") {
                let paidObjects = value > 12 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 12 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 170 && persons < 180 && item == "objects") {
                let paidObjects = value > 12 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 12 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 180 && persons < 190 && item == "objects") {
                let paidObjects = value > 12 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 12 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 190 && persons < 200 && item == "objects") {
                let paidObjects = value > 14 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                chargeable_characters = value > 14 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            } else if (persons >= 200 && item == "objects") {
                let paidObjects = value > 17 ? value - _3DcakeObject?.No_of_free_Characters : 0
                cakePricePerPerson = _3DcakeObject?.Price_of_the_character_in_SAR * _3DcakeObject?.No_of_Persons + paidObjects * 50;
                chargeable_characters = value > 17 ? (value - _3DcakeObject?.No_of_free_Characters) * 50 : 0;
                setChargeableChar(chargeable_characters)
                setPaidCharacter(paidObjects);
                setMaxObjects(_3DcakeObject?.Max_No_of_Characters);
                if (GetCategoryId == 81) {
                    setCakeAmount(cakePricePerPerson);
                }
            }

            if (typeof value === 'number') {
                if (GetCategoryId == 20 || GetCategoryId == 21) {
                    item == 'persons' && value >= 10 ? setCakeImage('images/no_objects.png') : setCakeImage('images/no_objects.png');
                } else {
                    item == 'persons' && value >= 10 ? setCakeImage('images/cake_for_persons.png') : setCakeImage('images/cake_next_step.png');
                }
                item == 'persons' && setPerson(_3DcakeObject?.No_of_Persons);
                item == 'persons' && setObjects(1);
                item == 'persons' && setLayer(1);

                // if (value >= 10) {
                item == 'objects' && setObjects(value != 0 ? value : 1);
                item == 'layer' && setLayer(value != 0 ? value : 1);
                if (item == 'objects') {
                    item == 'objects' && value >= 5 && setCakeImage('images/five_objects.png')
                    item == 'objects' && value < 5 && layer < 2 && setCakeImage('images/four_objects.png')
                    item == 'objects' && value < 4 && layer < 2 && setCakeImage('images/three_objects.png')
                    item == 'objects' && value < 3 && layer < 2 && setCakeImage('images/cake_with_object.png')


                    item == 'objects' && value >= 5 && layer >= 2 && setCakeImage('images/level_five.png');
                    item == 'objects' && value < 5 && layer >= 2 && setCakeImage('images/level_four.png');
                    item == 'objects' && value < 4 && layer >= 2 && setCakeImage('images/level_three.png')
                    item == 'objects' && value < 3 && layer >= 2 && setCakeImage('images/cake-with_2_layer.png');
                    item == 'objects' && value < 2 && layer > 2 && setCakeImage('images/level_one.png')
                    item == 'objects' && value < 2 && layer < 3 && setCakeImage('images/level_one.png');
                    item == 'objects' && value < 2 && layer < 2 && setCakeImage('images/cake_for_persons.png');
                }
                if (item == 'layer') {


                    item == 'layer' && value > 2 && objects >= 5 && setCakeImage('images/level_five.png');
                    item == 'layer' && value > 2 && objects == 4 && setCakeImage('images/level_four.png');
                    item == 'layer' && value > 2 && objects == 3 && setCakeImage('images/level_three.png');
                    item == 'layer' && value > 2 && objects == 2 && setCakeImage('images/cake-with_2_layer.png');
                    item == 'layer' && value > 2 && objects == 1 && setCakeImage('images/level_one.png');
                    item == 'layer' && value > 2 && objects < 1 && setCakeImage('images/level_one.png');


                    item == 'layer' && value < 2 && objects >= 5 && setCakeImage('images/level_five.png');
                    item == 'layer' && value > 1 && objects >= 5 && setCakeImage('images/level_five.png');
                    item == 'layer' && (value == 1 || value == 0) && objects >= 5 && setCakeImage('images/five_objects.png');





                    item == 'layer' && value < 3 && objects < 6 && setCakeImage('images/level_five.png');
                    item == 'layer' && value < 2 && objects < 6 && setCakeImage('images/five_objects.png');
                    item == 'layer' && value < 3 && objects < 5 && setCakeImage('images/level_four.png');
                    item == 'layer' && value < 2 && objects < 5 && setCakeImage('images/four_objects.png');
                    item == 'layer' && value < 3 && objects < 4 && setCakeImage('images/level_three.png');
                    item == 'layer' && value < 2 && objects < 4 && setCakeImage('images/three_objects.png');
                    item == 'layer' && value < 3 && objects < 3 && setCakeImage('images/cake-with_2_layer.png');
                    item == 'layer' && value < 3 && objects < 2 && setCakeImage('images/level_one.png');

                    item == 'layer' && value < 2 && objects < 3 && setCakeImage('images/cake_with_object.png');
                    item == 'layer' && value < 2 && objects < 2 && setCakeImage('images/cake_for_persons.png');


                }
                // }


            }
        } else {
            console.log(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }

    };

    const handleCupCake = (value, item) => {
        let cakePricePerPerson = 0;
        if (value >= 0 && value < 15 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = styroBaseSmall == 0 && styroBaseBig == 0 && value == 0 ? value + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig) : (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(110);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 15 && value < 20 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(115);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 20 && value < 25 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(120);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 25 && value < 30 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(125);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 30 && value < 35 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(130);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 35 && value < 40 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(135);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 40 && value < 50 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(140);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 50 && value < 60 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(150);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 60 && value < 70 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(160);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 70 && value < 80 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setMaxCupCake(170);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 80 && value < 90 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(180);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 90 && value < 100 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(190);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 100 && value < 110 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(200);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 110 && value < 120 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(210);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 120 && value < 130 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(220);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 130 && value < 140 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(230);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 140 && value < 150 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxCupCake(240);
            setMaxStyroBaseBig(4);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 150 && value < 160 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(250);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 160 && value < 170 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(260);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 170 && value < 180 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(270);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 180 && value < 190 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(280);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 190 && value < 200 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(290);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 200 && value < 210 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(300);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 210 && value < 220 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(310);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 220 && value < 230 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(320);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 230 && value < 240 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(330);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 240 && value < 250 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(340);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 250 && value < 260 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(350);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 260 && value < 270 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(360);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 270 && value < 280 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(370);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 280 && value < 290 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(380);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 290 && value < 300 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(390);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 300 && value < 310 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(400);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 310 && value < 320 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(410);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 320 && value < 330 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(415);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 330 && value < 340 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(417);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 340 && value < 350 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(420);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 350 && value < 360 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(425);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 360 && value < 370 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(430);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 370 && value < 380 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(435);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 380 && value < 390 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(440);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 390 && value < 400 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(445);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 400 && value < 410 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(450);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 410 && value < 420 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(455);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 420 && value < 430 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(460);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 430 && value < 440 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(465);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 440 && value < 450 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(470);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 450 && value < 460 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(475);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 460 && value < 470 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(480);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 470 && value < 480 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(485);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 480 && value < 490 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(490);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 490 && value < 500 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(500);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 500 && item == "number_of_cupcakes") {
            if (styroBaseSmall >= 0 && styroBaseBig >= 0) {
                cakePricePerPerson = (getPrice * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * styroBaseBig);
            } else {
                cakePricePerPerson = getPrice * value;
            }
            setMaxStyroBaseBig(4);
            setMaxCupCake(500);
            setMaxStyroBaseSmall(4);
            setCupCakeAmount(cakePricePerPerson)
        }

        if (value == 0 && CupCake < 15 && item == "styro_base_small") {
            cakePricePerPerson = value == 0 && styroBaseBig == 0 ? (StyroSmallPrice_in_SAR * value) + (StyroLargePrice_in_SAR * styroBaseBig) : (StyroSmallPrice_in_SAR * value) + (StyroLargePrice_in_SAR * styroBaseBig)
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 0 && CupCake >= 0 && item == "styro_base_small") {
            if (CupCake >= 15 && styroBaseBig == 0) {
                cakePricePerPerson = (CupCake * getPrice) + (StyroSmallPrice_in_SAR * value)
            } else if (CupCake >= 15 && styroBaseBig > 0) {
                cakePricePerPerson = (CupCake * getPrice) + (StyroSmallPrice_in_SAR * value) + (StyroLargePrice_in_SAR * styroBaseBig)
            } else {
                cakePricePerPerson = (StyroSmallPrice_in_SAR * value) + (StyroLargePrice_in_SAR * styroBaseBig);
            }
            // cakePricePerPerson = CupCake >= 15 && styroBaseBig > 0 ? (CupCake * Price) + (30 * value) + (50 * styroBaseBig) : Price + 30 * value;
            setCupCakeAmount(cakePricePerPerson);
        }

        if (value == 0 && CupCake < 15 && item == "styro_base_big") {
            cakePricePerPerson = value == 0 && styroBaseSmall == 0 ? (StyroLargePrice_in_SAR * value) + (StyroSmallPrice_in_SAR * styroBaseSmall) : (StyroLargePrice_in_SAR * value) + (StyroSmallPrice_in_SAR * styroBaseSmall)
            setCupCakeAmount(cakePricePerPerson)
        } else if (value >= 0 && CupCake >= 0 && item == "styro_base_big") {
            if (CupCake >= 15 && styroBaseSmall == 0) {
                cakePricePerPerson = (CupCake * getPrice) + (StyroLargePrice_in_SAR * value)
            } else if (CupCake >= 15 && styroBaseSmall > 0) {
                cakePricePerPerson = (CupCake * getPrice) + (StyroSmallPrice_in_SAR * styroBaseSmall) + (StyroLargePrice_in_SAR * value)
            } else {
                cakePricePerPerson = (StyroLargePrice_in_SAR * value) + (StyroSmallPrice_in_SAR * styroBaseSmall)
            }
            // cakePricePerPerson = CupCake >= 15 && styroBaseSmall > 0 ? (CupCake * Price) + (30 * styroBaseSmall) + (50 * value) : Price + 50 * value;
            setCupCakeAmount(cakePricePerPerson);
        }

        if (typeof value === 'number') {
            item == 'number_of_cupcakes' && setCakeImage('images/cupcake.png');
            item == 'number_of_cupcakes' && setCupCake(value);


            item == 'styro_base_small' && setStyroBaseSmall(value);
            item == 'styro_base_big' && setStyroBaseBig(value);
            if (item == 'styro_base_small') {
                item == 'styro_base_small' && value >= 2 && setCakeImage('images/cupcake.png')
                item == 'styro_base_small' && value < 2 && styroBaseBig >= 2 && setCakeImage('images/cupcake.png');
                item == 'styro_base_small' && value < 2 && styroBaseBig < 2 && setCakeImage('images/cupcake.png');
            }
            if (item == 'styro_base_big') {
                item == 'styro_base_big' && value >= 2 && setCakeImage('images/cupcake.png');
                item == 'styro_base_big' && value < 2 && styroBaseSmall >= 2 && setCakeImage('images/cupcake.png');
                item == 'styro_base_big' && value < 2 && styroBaseSmall < 2 && setCakeImage('images/cupcake.png');
            }

        }

    };

    const ImageWrapper = ({ imageUrl, layer }) => {
        if (layer >= 2) {
            return (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 3 }}
                >
                    <img src={imageUrl} alt="Image" />
                </motion.div>
            );
        } else {
            return <img src={imageUrl} alt="Image" />;
        }
    };

    const cupCakeStep = (step) => {
        if (step > 40) {
            return 10;
        } else if (step > 15) {
            return 5
        } else {
            return 15;
        }
    };

    useEffect(() => {
        if (lang?.Lang?.data?.lang) {
            if(GetCategoryId == 81) {
                TrackGoogleTagManagerPageView('size of cake', document.location.origin + document.location.pathname + document.location.search);
            }
        }

        let transaction_err = localStorage.getItem("transaction_failed") ? JSON.parse(localStorage.getItem("transaction_failed")) : "";
        if (transaction_err?.err && transaction_err?.err == "declined") {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }
        let get_success_state = localStorage.getItem("transaction_success") ? localStorage.getItem("transaction_success") : "";
        if (get_success_state) {
            window.history.pushState({}, 'Munch Bakery | منش بيكري', window.location.origin);
            window.location.replace("/");
        }
    }, [])

    useEffect(() => {

        if (getSlidersData) {

            handleCupCake(getSlidersData?.CupCake, "number_of_cupcakes");
            handleCupCake(getSlidersData?.styroBaseSmall, "styro_base_small");
            handleCupCake(getSlidersData?.styroBaseBig, "styro_base_big");
            setCupCakeAmount(getSlidersData?.cupCakeAmount ? JSON.parse(getSlidersData?.cupCakeAmount) : "");

        }

    }, [getSlidersData])

    useEffect(() => {

        if (getOthersData) {
            handleChange(getOthersData?.persons, "persons");
            setObjects(getOthersData?.objects);
            setLayer(getOthersData?.layer);
            setCakeAmount(getOthersData?.cakeAmount ? JSON.parse(getOthersData?.cakeAmount) : "");
            setChargeableChar(getOthersData?.characterAmount)

            if (GetCategoryId == 20 || GetCategoryId == 21) {
                setCakeImage('images/no_objects.png');
            } else {
                getOthersData?.persons >= 10 ? setCakeImage('images/cake_for_persons.png') : setCakeImage('images/cake_next_step.png');
            }
            if (GetCategoryId == 81) {
                CakePhoto1();
            }

        }
    }, [getOthersData])

    const CakePhoto1 = () => {
        getOthersData?.objects >= 5 && setCakeImage('images/five_objects.png')
        getOthersData?.objects < 5 && getOthersData?.layer < 2 && setCakeImage('images/four_objects.png')
        getOthersData?.objects < 4 && getOthersData?.layer < 2 && setCakeImage('images/three_objects.png')
        getOthersData?.objects < 3 && getOthersData?.layer < 2 && setCakeImage('images/cake_with_object.png')
        getOthersData?.objects >= 5 && getOthersData?.layer >= 2 && setCakeImage('images/level_five.png');
        getOthersData?.objects < 5 && getOthersData?.layer >= 2 && setCakeImage('images/level_four.png');
        getOthersData?.objects < 4 && getOthersData?.layer >= 2 && setCakeImage('images/level_three.png')
        getOthersData?.objects < 3 && getOthersData?.layer >= 2 && setCakeImage('images/cake-with_2_layer.png');
        getOthersData?.objects < 2 && getOthersData?.layer > 2 && setCakeImage('images/level_one.png')
        getOthersData?.objects < 2 && getOthersData?.layer < 3 && setCakeImage('images/level_one.png');
        getOthersData?.objects < 2 && getOthersData?.layer < 2 && setCakeImage('images/cake_for_persons.png');
        CakePhoto2();
    }

    const CakePhoto2 = () => {
        getOthersData?.layer > 2 && getOthersData?.objects >= 5 && setCakeImage('images/level_five.png');
        getOthersData?.layer > 2 && getOthersData?.objects == 4 && setCakeImage('images/level_four.png');
        getOthersData?.layer > 2 && getOthersData?.objects == 3 && setCakeImage('images/level_three.png');
        getOthersData?.layer > 2 && getOthersData?.objects == 2 && setCakeImage('images/cake-with_2_layer.png');
        getOthersData?.layer > 2 && getOthersData?.objects == 1 && setCakeImage('images/level_one.png');
        getOthersData?.layer > 2 && getOthersData?.objects < 1 && setCakeImage('images/level_one.png');
        getOthersData?.layer < 2 && getOthersData?.objects >= 5 && setCakeImage('images/level_five.png');
        getOthersData?.layer > 1 && getOthersData?.objects >= 5 && setCakeImage('images/level_five.png');
        getOthersData?.layer < 3 && getOthersData?.objects < 6 && setCakeImage('images/level_five.png');
        CakePhoto3();

    }

    const CakePhoto3 = () => {
        getOthersData?.layer < 2 && getOthersData?.objects < 6 && setCakeImage('images/five_objects.png');
        getOthersData?.layer < 3 && getOthersData?.objects < 5 && setCakeImage('images/level_four.png');
        getOthersData?.layer < 2 && getOthersData?.objects < 5 && setCakeImage('images/four_objects.png');
        getOthersData?.layer < 3 && getOthersData?.objects < 4 && setCakeImage('images/level_three.png');
        getOthersData?.layer < 2 && getOthersData?.objects < 4 && setCakeImage('images/three_objects.png');
        getOthersData?.layer < 3 && getOthersData?.objects < 3 && setCakeImage('images/cake-with_2_layer.png');
        getOthersData?.layer < 3 && getOthersData?.objects < 2 && setCakeImage('images/level_one.png');
        getOthersData?.layer < 2 && getOthersData?.objects < 3 && setCakeImage('images/cake_with_object.png');
        getOthersData?.layer < 2 && getOthersData?.objects < 2 && setCakeImage('images/cake_for_persons.png');
    }

    const transObjects = () => {
        if (objects == 0) {
            return CakeCustomizeText?.zero_object
        } else if (objects == 1) {
            return CakeCustomizeText?.one_object
        } else if (objects == 2) {
            return CakeCustomizeText?.two_objects
        } else {
            return CakeCustomizeText?.objects
        }
    }

    const transLevels = () => {
        if (layer == 0) {
            return CakeCustomizeText?.zero_level
        } else if (layer == 1) {
            return CakeCustomizeText?.one_level
        } else if (layer == 2) {
            return CakeCustomizeText?.two_levels
        } else {
            return CakeCustomizeText?.levels
        }
    }

    const ShopGetShoppingCart = async () => {


        let langName = lang?.Lang?.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: custGuid,
            CustomerId: custId ? custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "1",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {
            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            if (response?.data?.GetShoppingCartSummaryResult) {
                let shopCartItems = response?.data?.GetShoppingCartSummaryResult;
                if (GetCategoryId == 57) {
                    if ((shopCartItems?.LstECommerceShoppingCartItem)?.length > 0) {
                        let shopItemsArray = shopCartItems?.LstECommerceShoppingCartItem;

                        // console.log(shopItemsArray, "outOfStockItem");
                        let outOfStockItem = shopItemsArray?.filter((v) => !v?.InStock)

                        if (outOfStockItem?.length > 0) {
                            setState({ ...state, ["bottom"]: false });
                            setIsLoader(true);
                            clearCart(false);
                            return toast.error(langName == "en" ? "Some Items out of stock" : "بعض العناصر من المخزون", { position: toast.POSITION.TOP_LEFT });
                        }
                        setIsLoader(false);
                        setState({ ...state, ["bottom"]: true });
                        stateHandler();
                    }
                }
                setShoppingDetails(shopCartItems);

            }
        } catch (error) {
            console.log("err", error);
        }

    }

    const stateHandler = () => {
        return (
            <Box component='div' id='next-ID' className='text-decoration'>
                <CheckoutDrawer cupCake={cup_cake} getCartDetail={getCartDetail} custtoken={custtoken} setState={setState} state={state} getText={getText} setText={setText} productDetail={productDetail} ShoppingDetails={ShoppingDetails} setShoppingDetails={setShoppingDetails} revokeCouponCode={revokeCouponCode} CategoryId={GetCategoryId} IsClick={IsClick} getThisValue={getThisValue} />
            </Box>
        )
    }


    const getShopGetShoppingCart = async (new_value) => {
        let langName = lang.Lang.data?.lang;
        const langId = langName == 'ar' ? "0" : "1";

        const data = {
            CustomerGuid: custGuid,
            CustomerId: custId ? custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: langId,
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "15"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/GetShoppingcartsummary/`, data, header);
            if (response?.data?.GetShoppingCartSummaryResult) {
                setShopDetail(response?.data?.GetShoppingCartSummaryResult)
                setButtonLoader(false);
                if (response?.data?.GetShoppingCartSummaryResult?.LstECommerceShoppingCartItem) {
                    let result = response?.data?.GetShoppingCartSummaryResult;

                    result?.LstECommerceShoppingCartItem?.map(async (v, i) => {
                        await AddUpdateShoppingCart(null, null, null, v, new_value);
                    })
                }
            }

        } catch (error) {
            console.log("err", error);
            return;
        }
    }

    const clearCart = async (new_state) => {
        // localStorage.removeItem("start_session");
        localStorage.setItem("push_state", "true");
        setIsLoader(true);
        setButtonLoader(true);
        let data = {
            langId: 1,
            CustomerGuid: custGuid
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }

            await axios.post(`${MunchBakeryApiUrl}/DeleteProductShoppingCartMunchCake/`, data, header);

            if (GetCategoryId == 57) {
                // await ShopGetShoppingCart();
                if (new_state) {
                    await cupCakeloopAddItemtoCart(new_state);
                } else {
                    await getShopGetShoppingCart(new_state);
                }
            } else if (GetCategoryId == 81) {

                await OCcassionloopAddItemtoCart();

            } else if (GetCategoryId == 20) {

                localStorage.setItem("Others", JSON.stringify({ layer: layer, photoOcassionPersons: photoOcassionPersons, photoCakeAmount: photoCakeAmount?.toFixed(2), maxPhotoOccassionPersion: maxPhotoOccassionPersion }))
                let state = {
                    others: { layer: layer, photoOcassionPersons: photoOcassionPersons, photoCakeAmount: photoCakeAmount?.toFixed(2), maxPhotoOccassionPersion: maxPhotoOccassionPersion }
                };
                navigate('/customizeThird', { state: state });

            } else {

                localStorage.setItem("Others", JSON.stringify({ layer: layer, frostingPersons: frostingPersons, frostingCakeAmount: frostingCakeAmount?.toFixed(2), maxFrostingPersion: maxFrostingPersion }))
                let state = {
                    others: { layer: layer, frostingPersons: frostingPersons, frostingCakeAmount: frostingCakeAmount?.toFixed(2), maxFrostingPersion: maxFrostingPersion }
                };
                navigate('/customizeThird', { state: state });

            }

        } catch (error) {
            console.log("err", error);
            setButtonLoader(false);
            return alert(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }
    }

    const cupCakeloopAddItemtoCart = async (new_value) => {
        if (CupCake > 0) {
            let cupCakeId = productDetail?.prodId
            let Amount = productDetail?.Price;
            await AddUpdateShoppingCart(cupCakeId, CupCake, Amount, null, new_value);
        }

        if (styroBaseBig > 0) {
            let StryoBaseLargeId = 1689
            let Amount = styroBaseBig == 0 ? "0" : 50;
            await AddUpdateShoppingCart(StryoBaseLargeId, styroBaseBig, Amount, null, new_value);
        }
        if (styroBaseSmall > 0) {
            let StryoBaseSmallId = 1688
            let Amount = styroBaseSmall == 0 ? "0" : 30;
            await AddUpdateShoppingCart(StryoBaseSmallId, styroBaseSmall, Amount, null, new_value);
        }
        await ShopGetShoppingCart();
    }

    const OCcassionloopAddItemtoCart = async () => {
        let Price1 = 0;
        let Quantity1 = Number(objects) - Number(paidCharacter);
        let ProductId1 = 2406;
        await AddUpdateShoppingCart(ProductId1, Quantity1, Price1, null, true);

        let Price2 = 50;
        let Quantity2 = paidCharacter;
        let ProductId2 = 2394;
        await AddUpdateShoppingCart(ProductId2, Quantity2, Price2, null, true);

    }

    const AddUpdateShoppingCart = async (Id, Quantity, Price, result, new_value) => {
        let order_date = moment(result?.UpdatedOn, 'DD/MM/YYYY');
        let newOrderDate = moment(order_date).format("MM/DD/YYYY");

        const data = {
            CustomerId: custId,
            CustomerGuid: custGuid,
            CityId: -1,
            DistrictId: -1,
            MarkAsDeleted: !new_value ? 1 : 0,
            OrderDate: !new_value ? newOrderDate : getCartDetail?.OrderDate,
            Price: !new_value ? result?.Price : Price,
            ProductId: !new_value ? result?.ProductId : Id,
            Quantity: !new_value ? result?.Quantity : Quantity,
            ShoppingCartId: !new_value ? result?.ShoppingCartId : "-1",
            StoreId: !new_value ? result?.StoreId : getCartDetail?.StoreId,
            ZoneId: "15"
        };

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/AddUpdateShoppingCartItemMunchBakery/`, data, header);
            if (response?.data) {
                localStorage.setItem("form_data", JSON.stringify(data));
                if (GetCategoryId == 57) {
                    localStorage.setItem("sliders", JSON.stringify({ CupCake: CupCake, styroBaseBig: styroBaseBig, styroBaseSmall: styroBaseSmall, StryoBaseLargeId: 1689, StryoBaseSmallId: 1688, cupCakeAmount: cupCakeAmount }))

                    let state = { category_id: GetCategoryId }
                    navigate('/customizeNext', { state: state, replace: false })
                    setButtonLoader(false);

                } else if (GetCategoryId == 81) {


                    localStorage.setItem("Others", JSON.stringify({ persons: persons, objects: objects, layer: layer, cakeAmount: cakeAmount?.toFixed(2), ProductId: 2394, freeCharacterId: 2406, characterAmount: ChargeableChar, max_number_character: maxObjects }))
                    let state = {
                        others: { persons: persons, objects: objects, layer: layer, cakeAmount: cakeAmount?.toFixed(2), ProductId: 2394, freeCharacterId: 2406, characterAmount: ChargeableChar }
                    };

                    navigate('/customizeThird', { state: state })
                    setButtonLoader(false);
                    return

                } else if (GetCategoryId == 20) {
                    localStorage.setItem("Others", JSON.stringify({ photoOcassionPersons: photoOcassionPersons, photoCakeAmount: photoCakeAmount?.toFixed(2), maxPhotoOccassionPersion: maxPhotoOccassionPersion }))
                    let state = {
                        others: { photoOcassionPersons: photoOcassionPersons, photoCakeAmount: photoCakeAmount?.toFixed(2), maxPhotoOccassionPersion: maxPhotoOccassionPersion }
                    };
                    navigate('/customizeThird', { state: state })
                    setButtonLoader(false);
                }
                localStorage?.setItem("inStock", JSON.stringify({ in_stock: response?.data?.IsInStock }));
                setButtonLoader(false);


            }
        } catch (error) {
            console.log("err", error);
            setButtonLoader(false);
            return alert(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }

    }

    const revokeCouponCode = async () => {

        const data = {
            CustomerGuid: custGuid,
            CouponCode: getText ? getText : ShopDetail?.CouponCode ? ShopDetail?.CouponCode : "",
            IsApplyCopoun: "0",
            CustomerId: custId ? custId : "",
            OrderDate: getCartDetail?.OrderDate ? getCartDetail?.OrderDate : "",
            StoreId: getCartDetail?.StoreId ? getCartDetail?.StoreId : "",
            langId: "1",
            CityId: "-1",
            DistrictId: "-1",
            ZoneId: "-1",
            OrderType: "-1"
        }

        try {

            const header = {
                headers: {
                    "Authorization": `basic ${custtoken}`,
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post(`${MunchBakeryApiUrl}/ApplyOrRevokeMunchCouponCode/`, data, header);
            // console.log('https://liveapi.munchbakery.com/MunchBakeryAPIService.svc/ApplyOrRevokeMunchCouponCode/, --api');

            if (response?.data?.ApplyOrRevokeMunchCouponCodeResult?.Status == "SUCCESS") {
                setText("")
                localStorage.removeItem("CouponCode");
            }

        } catch (error) {
            console.log("err", error);
            return alert(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }
    }

    useEffect(() => {

        if (GetCategoryId == 57) {
            // if (custGuid) {
            //     if (lang.Lang.data?.lang) {
            //         getShopGetShoppingCart();
            //     }
            // }

            if (ShopDetail?.CouponCode) {
                if (lang.Lang.data?.lang) {
                    revokeCouponCode();
                }
            }
        }

    }, [ShopDetail?.CouponCode])


    useEffect(() => {
        if (GetCategoryId == 57) {
            if (!state?.bottom) {
                if (lang.Lang.data?.lang) {
                    clearCart(false);
                }
                if (ShoppingDetails?.CouponCode) {
                    revokeCouponCode();
                }
                IsClick();
            }
        }
    }, [!state?.bottom])


    const handlePhotoOccassion = (event, value, item) => {
        if (getPhotoOccassionArray?.length > 0) {
            let PhotoOccassionObject = getPhotoOccassionArray?.filter((v) => v?.No_of_Persons == value)?.[0];
            if (PhotoOccassionObject) {

                let cakePricePerPerson = 0;
                if (value >= 0 && value < 10 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(150);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 10 && value < 15 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(155);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 15 && value < 20 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(105);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 20 && value < 25 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(110);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 25 && value < 30 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(115);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 30 && value < 35 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(120);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 35 && value < 40 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(125);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 40 && value < 45 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(130);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 45 && value < 50 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(135);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 50 && value < 60 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(140);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 60 && value < 70 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(145);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 70 && value < 80 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(150)
                    setPhotoCakeAmount(cakePricePerPerson)

                } else if (value >= 80 && value < 90 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(155);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 90 && value < 100 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(160);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 100 && value < 110 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(165);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 110 && value < 120 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(170);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 120 && value < 130 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(175);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 130 && value < 140 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(180);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 140 && value < 150 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(185);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 150 && value < 160 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(190);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 160 && value < 170 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(195);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 170 && value < 180 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(200);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 180 && value < 190 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(205);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 190 && value < 200 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(210);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 200 && value <= 230 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(230);
                    setPhotoCakeAmount(cakePricePerPerson);

                } else if (value >= 230 && item == "photo_ocassion_persons") {
                    cakePricePerPerson = PhotoOccassionObject?.Person_price_In_SAR * PhotoOccassionObject?.No_of_Persons;
                    setmaxPhotoOccassionPersion(230);
                    setPhotoCakeAmount(cakePricePerPerson);
                }

                if (typeof value === 'number') {
                    item == 'photo_ocassion_persons' && setPhotoOccassionPersion(PhotoOccassionObject?.No_of_Persons);
                }
            }
        } else {
            console.log(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }

    }

    const handleFrosting = (value, item) => {
        if (getFrostingArray?.length > 0) {
            let frostingObject = getFrostingArray?.filter((v) => v?.No_of_Persons == value)?.[0];
            if (frostingObject) {
                let cakePricePerPerson = 0;
                if (value >= 0 && value < 5 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(75)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 5 && value < 10 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(80)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 10 && value < 15 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(85)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 15 && value < 20 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(90)
                    setFrostingCakeAmount(cakePricePerPerson)


                } else if (value >= 20 && value < 25 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(95)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 25 && value < 30 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(100)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 30 && value < 35 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(105)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 35 && value < 40 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(110)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 40 && value < 45 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(115)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 45 && value < 50 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(120)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 50 && value < 60 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(125)
                    setFrostingCakeAmount(cakePricePerPerson)

                } else if (value >= 60 && value < 70 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(130)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 70 && value < 80 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(135)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 80 && value < 90 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(140)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 90 && value < 100 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(145)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 100 && value < 110 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(150)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 110 && value < 120 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(155)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 120 && value < 130 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(160)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 130 && value < 140 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(165)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 140 && value < 150 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(170)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 150 && value < 160 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(175)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 160 && value < 170 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(180)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 170 && value < 180 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(185)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 180 && value < 190 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(190)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 190 && value < 200 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(200)
                    setFrostingCakeAmount(cakePricePerPerson)
                } else if (value >= 200 && item == "frosting_cake_persons") {
                    cakePricePerPerson = frostingObject?.Person_price_SAR * frostingObject?.No_of_Persons;
                    setmaxFrostingPersion(200)
                    setFrostingCakeAmount(cakePricePerPerson)
                }



                if (typeof value === 'number') {
                    item == 'frosting_cake_persons' && setFrostingPersion(frostingObject?.No_of_Persons);
                }
            }
        } else {
            alert(langId == "en" ? "Something went wrong!. Please again later." : "هناك خطأ ما!. يرجى مرة أخرى في وقت لاحق.");
        }


    }

    const TotalAmount = () => {
        if (GetCategoryId == 57) {
            return cupCakeAmount ? cupCakeAmount?.toFixed(2) : 0;
        } else if (GetCategoryId == 81) {
            return cakeAmount ? cakeAmount?.toFixed(2) : 0;
        } else if (GetCategoryId == 20) {
            return photoCakeAmount ? photoCakeAmount?.toFixed(2) : 0;
        } else if (GetCategoryId == 21) {
            return frostingCakeAmount ? frostingCakeAmount?.toFixed(2) : 0;
        } else {
            return 0;
        }
    }

    const photoStep = (step) => {


        if (step >= 0 && step <= 10) {
            return 10;
        } else if (step > 10 && step < 50) {
            return 5;
        } else if (step >= 50 && step < 200) {
            return 10;
        } else if (step >= 200) {
            return 30;
        } else {
            return 0;
        }

        // if (step >= 0 && step <= 10) {
        // 	return 10;
        // } else if (step > 10 && step < 50) {
        // 	return 5;
        // } else if (step >= 50 && step < 200) {
        // 	return 10;
        // } else if (step >= 200) {
        // 	return 30;
        // } else {
        // 	return 0;
        // }
    }


    const minValue = (step) => {

        if (step >= 200) {
            return 20;
        } else if (step > 10 && step < 50) {
            return 5
        } else {
            return 0;
        }


        // if (step >= 200) {
        // 	return 20;
        // } else if (step > 10 && step < 50) {
        // 	return 5
        // } else {
        // 	return 0;
        // }

    }


    // const photoStep = (step) => {

    // 	if (step >= 0 && step <= 10) {
    // 		return 10;
    // 	} else if (step > 10 && step < 50) {
    // 		return 5;
    // 	} else if (step >= 50 && step < 200) {
    // 		return 10;
    // 	} else if (step >= 200) {
    // 		return 30;
    // 	} else {
    // 		return 0;
    // 	}
    // }


    // const minValue = (step) => {

    // 	if (step >= 200) {
    // 		return 20;
    // 	} else if (step > 10 && step < 50) {
    // 		return 5
    // 	} else {
    // 		return 0;
    // 	}

    // }

    const IsClick = async () => {
        await new Promise((resolve, reject) => {
            setTimeout(() => {
                let state = { category_id: GetCategoryId }
                navigate('/customizeNext', { state: state, replace: false })
            }, 100);
        });
    }

    const handleTextButton = (val, message = "") => {
        if (val === 1) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Please add atleast one item.') : setCustomText('الرجاء إضافة عنصر واحد على الأقل')
        } else if (val === 2) {
            setOpened(true);
            return langId == 'en' ? setCustomText('Price should be greater than 0.') : setCustomText('يجب أن يكون السعر أكبر من 0')
        } else {
            return setOpened(false);
        }
    }


    const headingHandler = () => {
        if (GetCategoryId == 81) {
            return CakeCustomizeText?.heading3Dcake;
        } else if (GetCategoryId == 57) {
            return "";
        } else {
            return CakeCustomizeText?.heading;
        }

    }

    const PriceFetch = async (name) => {

        setPriceLoader(true);
        try {
            const form_data = {
                category: name,
            }
            const result = await axios.post(`${API_URL}/collection-fetch`, form_data);
            if (result?.data?.success) {
                if (result?.data?.data?.documents?.length > 0) {
                    let inArray = result?.data?.data?.documents;

                    // let newArray = inArray?.filter((v, i) => v?.BE_id == productDetail?.prodId);
                    // setDecoratedPrice(newArray[0]);

                    let styroSmallPrice = inArray?.filter((v, i) => v?.BE_id == 1688);
                    setStyroSmallPrice(styroSmallPrice[0]);

                    let styroBigPrice = inArray?.filter((v, i) => v?.BE_id == 1689);
                    setStyroBigPrice(styroBigPrice[0]);

                    let OthercakeObject = inArray?.filter((v) => v?.No_of_Persons >= 0)?.[0];

                    let lastcakeObject = inArray[inArray?.length - 1];


                    setFrostingArray(inArray);
                    setmaxFrostingPersion(lastcakeObject?.No_of_Persons);
                    setFrostingCakeAmount(OthercakeObject?.Person_price_SAR * OthercakeObject?.No_of_Persons);


                    setPhotoOccassionArray(inArray);
                    setmaxPhotoOccassionPersion(lastcakeObject?.No_of_Persons);
                    setPhotoCakeAmount(OthercakeObject?.Person_price_In_SAR * OthercakeObject?.No_of_Persons);



                    set3DcakeArray(inArray);
                    setmaxPerson(lastcakeObject?.No_of_Persons);

                    let cakeObject = inArray?.filter((v) => v?.No_of_Persons > 0)?.[0];
                    setPerson(cakeObject?.No_of_Persons);
                    setMaxObjects(cakeObject?.Max_No_of_Characters);
                    setMaxLayers(cakeObject?.Max_No_of_Layers);
                    setCakeAmount(cakeObject?.Price_of_the_character_in_SAR * cakeObject?.No_of_Persons);

                    setPriceLoader(false);
                }

            }
        } catch (error) {
            console.log({ "err": error });
            toast.error(error?.response?.statusText, { position: toast.POSITION.TOP_LEFT });

        }
    }

    useEffect(() => {

        if (lang.Lang.data?.lang) {
            if (GetCategoryId == 57) {
                PriceFetch("decorated_cupcake");
            } else if (GetCategoryId == 81) {
                PriceFetch("3DPRICE");
            } else if (GetCategoryId == 21) {
                PriceFetch("frosting");
            } else if (GetCategoryId == 20) {
                PriceFetch("photo_occasion");
            }
        }

    }, [GetCategoryId])


    return (
        <React.Fragment>
            <ToastContainer />
            <Box component='div' className='page_default customize_page cust_second_step'>
                <Box component='div' className='custom_cake'>
                    {cakeImage && <ImageWrapper layer={layer} imageUrl={cakeImage} />}
                </Box>
                <Typography component='h1' variant='h1'>
                    {headingHandler()}
                </Typography>

                {!priceLoader ?
                    <>
                        {GetCategoryId == 81 ?
                            <Box component='div' className='range_bars_custom'>
                                <Box component='div' className='individual_bars'>
                                    <Box className="text_state">
                                        {(persons <= 10) ? CakeCustomizeText?.people : CakeCustomizeText?.persons}
                                    </Box>
                                    <Box>
                                        <PersonsSlider
                                            value={persons}
                                            min={persons >= 60 ? 0 : 5}
                                            max={maxPerson}
                                            step={persons >= 60 ? 10 : 5}
                                            valueLabelDisplay="on"
                                            onChange={(event, value) => handleChange(value, "persons")}
                                            aria-labelledby="persons-slider"
                                        />
                                    </Box>
                                </Box>
                                <Box component='div' className='individual_bars'>
                                    <Box component='div' className='individual_bars'>
                                        <Box className="text_state">
                                            {transObjects()}
                                        </Box>
                                        <Box>
                                            <PersonsSlider
                                                value={objects}
                                                min={0}
                                                max={maxObjects}
                                                valueLabelDisplay="on"
                                                onChange={(event, value) => handleChange(value, 'objects')}
                                                aria-labelledby="persons-slider"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box component='div' className='individual_bars'>
                                    <Box component='div' className='individual_bars'>
                                        <Box className="text_state">
                                            {transLevels()}
                                        </Box>
                                        <Box>
                                            <PersonsSlider
                                                value={layer}
                                                min={0}
                                                max={maxLayers}
                                                valueLabelDisplay="on"
                                                onChange={(event, value) => handleChange(value, 'layer')}
                                                aria-labelledby="persons-slider"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            : ""
                        }
                        {GetCategoryId == 57 ?
                            <Box component='div' className='range_bars_custom'>
                                <Box component='div' className='individual_bars'>
                                    <Box className="text_state">
                                        {CupCakeText?.number_of_cupcakes}
                                    </Box>
                                    <Box>
                                        <PersonsSlider
                                            value={CupCake}
                                            min={0}
                                            max={maxCupCake}
                                            step={cupCakeStep(CupCake)}
                                            valueLabelDisplay="on"
                                            onChange={(event, value) => handleCupCake(value, "number_of_cupcakes")}
                                            aria-labelledby="persons-slider"
                                        />
                                    </Box>
                                </Box>
                                <Box component='div' className='individual_bars'>
                                    <Box component='div' className='individual_bars'>
                                        <Box className="text_state">
                                            {CupCakeText?.styro_base_small}
                                        </Box>
                                        <Box>
                                            <PersonsSlider
                                                value={styroBaseSmall}
                                                min={0}
                                                max={maxStyroBaseSmall}
                                                valueLabelDisplay="on"
                                                onChange={(event, value) => handleCupCake(value, 'styro_base_small')}
                                                aria-labelledby="persons-slider"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box component='div' className='individual_bars'>
                                    <Box component='div' className='individual_bars'>
                                        <Box className="text_state">
                                            {CupCakeText?.styro_base_big}
                                        </Box>
                                        <Box>
                                            <PersonsSlider
                                                value={styroBaseBig}
                                                min={0}
                                                max={maxStyroBaseBig}
                                                valueLabelDisplay="on"
                                                onChange={(event, value) => handleCupCake(value, 'styro_base_big')}
                                                aria-labelledby="persons-slider"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            : ""
                        }
                        {GetCategoryId == 20 ?
                            <Box component='div' className='range_bars_custom'>
                                <Box component='div' className='individual_bars'>
                                    <Box className="text_state">
                                        {(photoOcassionPersons <= 10) ? CakeCustomizeText?.people : CakeCustomizeText?.persons}
                                    </Box>
                                    <Box>
                                        <PersonsSlider
                                            value={photoOcassionPersons}
                                            min={minValue(photoOcassionPersons)}
                                            // max={maxValue(photoOcassionPersons)}
                                            // key={`slider-${photoOcassionPersons}`}
                                            max={maxPhotoOccassionPersion}
                                            step={photoStep(photoOcassionPersons)}
                                            valueLabelDisplay="on"
                                            onChange={(event, value) => handlePhotoOccassion(event, value, "photo_ocassion_persons")}

                                            aria-labelledby="persons-slider"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            : ""
                        }
                        {GetCategoryId == 21 ?
                            <Box component='div' className='range_bars_custom'>
                                <Box component='div' className='individual_bars'>
                                    <Box className="text_state">
                                        {(frostingPersons <= 10) ? CakeCustomizeText?.people : CakeCustomizeText?.persons}
                                    </Box>
                                    <Box>
                                        <PersonsSlider
                                            value={frostingPersons}
                                            min={frostingPersons >= 60 ? 10 : 0}
                                            max={maxFrostingPersion}
                                            step={frostingPersons >= 60 ? 10 : 5}
                                            valueLabelDisplay="on"
                                            onChange={(event, value) => handleFrosting(value, "frosting_cake_persons")}
                                            aria-labelledby="persons-slider"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            : ""
                        }

                    </>
                    :
                    <>
                        <Box component='div' className='range_bars_custom'>
                            <Box component='div' className='individual_bars'>
                                <Box className="text_state">
                                    <Skeleton animation="wave" width={80} />
                                </Box>
                                <Box>
                                    <Skeleton animation="wave" width={330} />
                                </Box>
                            </Box>

                            {GetCategoryId == 57 || GetCategoryId == 81 ?
                                <>
                                    <Box component='div' className='individual_bars'>
                                        <Box component='div' className='individual_bars'>
                                            <Box className="text_state">
                                                <Skeleton animation="wave" width={80} />
                                            </Box>
                                            <Box>
                                                <Skeleton animation="wave" width={330} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box component='div' className='individual_bars'>
                                        <Box component='div' className='individual_bars'>
                                            <Box className="text_state">
                                                <Skeleton animation="wave" width={80} />
                                            </Box>
                                            <Box>
                                                <Skeleton animation="wave" width={330} />
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                                : <>
                                </>
                            }
                        </Box>

                    </>
                }
                <Box component='div' className='step_button_box'>
                    <Box component='div' className='text'>
                        <Typography component='h4' variant='h4'>{CakeCustomizeText?.currency} {TotalAmount()}</Typography>
                        <Typography component='p' variant='p'>{CakeCustomizeText?.vat_included}</Typography>
                    </Box>
                    <Box component='div' className='btn' >
                        {
                            GetCategoryId == 57 ?
                                <>
                                    {
                                        CupCake == 0 && styroBaseSmall == 0 && styroBaseBig == 0 ?
                                            <Button disabled={priceLoader} className="upload_btn" onClick={() => handleTextButton(1)}>
                                                {CakeCustomizeText?.next_btn}
                                            </Button>
                                            :
                                            <>
                                                {!getButtonLoader ?
                                                    <>
                                                        {isLoader ?
                                                            <Button className="upload_btn" onClick={() => !state?.bottom ? clearCart(true) : ""} aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                                                                {flavorText?.next_btn}
                                                            </Button>
                                                            : stateHandler()
                                                        }
                                                    </>
                                                    :
                                                    <Button className="upload_btn" disabled aria-label="verofiction setup" aria-controls="verification-modal" aria-haspopup="true">
                                                        <CircularProgress style={{ width: "23px", height: "23px", color: "black" }} />
                                                    </Button>
                                                }
                                            </>


                                    }
                                </>
                                :
                                <>
                                    {TotalAmount() == 0 ?
                                        <Button disabled={priceLoader} className="upload_btn" onClick={() => handleTextButton(2)}>
                                            {CakeCustomizeText?.next_btn}
                                        </Button>
                                        :
                                        <>
                                            {!getButtonLoader ?
                                                <Button disabled={priceLoader} className="upload_btn" onClick={() => clearCart(false)}>
                                                    {CakeCustomizeText?.next_btn}
                                                </Button>
                                                :
                                                <Button className="upload_btn" disabled>
                                                    <CircularProgress style={{ width: "23px", height: "23px", color: "black" }} />
                                                </Button>
                                            }
                                        </>

                                    }
                                </>
                        }

                    </Box>
                </Box>
            </Box>
            <Dialog
                open={opened}
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                onClose={() => setOpened(false)}
                aria-labelledby="responsive-dialog-title"
                aria-describedby="responsive-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title">
                    {getCustomText}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="responsive-dialog-description">
                        {/* You will loose your progress, are you sure to move back ? */}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={() => handleButton("0")}>{flavorText?.cancel}</Button> */}
                    <Button onClick={() => setOpened(false)} autoFocus>
                        {flavorText?.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    )
}
