
import TagManager from 'react-gtm-module';

const InitializeGoogleTagManager = () => {

    const tagManagerArgs = {
        gtmId: 'GTM-588KMFRB',
        auth: '6SDrAw2x-KCRuSKoszfG4w',
        preview: 'env-2'
    }

    // Initialize GTM - Add your GTM ID
    TagManager.initialize(tagManagerArgs)

    // console.log("GTM INITIALIZED");
  };


  const TrackGoogleTagManagerEvent = (event = "", parameter = "", parameterValue ="") => {

    // console.log("GTM event :::", event, ":", parameter, ":", parameterValue);

    // Send GTM event
    // window.dataLayer.push({
    //     event: 'event',
    //     eventProps: {
    //         category: category,
    //         action: action,
    //         label: label,
    //         value: value
    //     }
    // });

    // window.dataLayer.push({
    //     event: event,
    //     parameter: parameter,
    //     parameterValue: parameterValue
    // });

    // Send GTM event
    window.dataLayer.push({
        event: event,
        eventProps: {
            action: parameter,
            value: parameterValue
        }
    });

  }

  const TrackGoogleTagManagerPageView = (hitType = "", page = "") => {
    // console.log("GTM Page view :::", hitType, ":", page, ":");

    // Send GTM Page View
    TagManager.dataLayer({
        dataLayer: {
            event: hitType,
            page: {
                url: page
            }
        },
    });

  };



  export { InitializeGoogleTagManager, TrackGoogleTagManagerEvent, TrackGoogleTagManagerPageView };
